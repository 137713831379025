import React, { useEffect, useState } from "react";
import { useMainStateValue } from "../../provider/AppProvider";
import apiRequest from "../../lib/api/services";
import * as appConstant from "../../provider/appConstant.type";
import {
  formatCurrency,
  notifyError,
  notifySuccess,
} from "../../lib/helpers/utils";

export default function Orders() {
  const [{ customer, settings }, dispatch] = useMainStateValue();
  const [orderData, serOrderData] = useState([]);
  const [refetch, setFetch] = useState(false);
  const [orderItemId, setOrderItemId] = useState([]);
  const [refundurl, setRefundurl] = useState({});
  //setFetch(!refetch);

  useEffect(() => {
    const fetchInfo = () => {
      dispatch({ type: appConstant.SHOW_LOADER });
      apiRequest
        .getOrderBycustomerId({ customer_mobile: customer.mobile_number })
        .then((res) => {
          if (res.data.errorMessage) {
            console.error(
              "Error msg getOrderBycustomerId",
              res.data.errorMessage
            );
            serOrderData([]);
          } else {
            serOrderData(res.data);
          }
        })
        .catch((e) => {
          console.error("fetch getOrderBycustomerId Info error ", e);
          serOrderData([]);
        })
        .finally((e) => {
          dispatch({ type: appConstant.HIDE_LOADER });
        });
    };
    fetchInfo();
  }, [refetch]);

  function OrderCanceldesign(cancelled) {
    if (cancelled == "Y") {
      return (
        <div className="orderCancel">
          <div className="orderCancelImg">
            <img src="/images/jpg/cancelled-stamp.jpg" />
          </div>
        </div>
      );
    } else {
      return "";
    }
  }

  function orderdata() {
    return orderData.map((o) => (
      <div key={o.id} className="card px-2 mb-3 position-relative">
        {OrderCanceldesign(o.cancelled)}
        <div className="card-header bg-white">
          <div className="d-flex justify-content-space-between">
            <p className="text-muted">
              Order ID:-
              <span className="font-weight-bold text-dark">{o.id}</span>
            </p>
            <p className="text-muted">
              Place On:-
              <span className="font-weight-bold text-dark">
                {o.created_date}
              </span>
            </p>
          </div>
        </div>
        <div className="card-body">
          <div className="media flex-column flex-sm-row">
            <div className="media-body w-100 ">
              <h5 className="bold">Shipping Address</h5>
              <p className="shippingAddress">
                {o.deliveryAddress ? o.deliveryAddress : "N/A"}
                {/* <span>Prometric</span>
                <span> 2 Floor, DLF Infinity Tower-A</span>
                <span> Sector-25, Phase-II</span>
                <span>DLF City</span>
                <span> Gurgaon - 122002</span>
                <span> Haryana - IND</span> */}
              </p>
              {/* <p className="text-muted"> Qt: 1 Pair</p>
                  <h4 className="mt-3 mb-4 bold"> <span className="mt-5">₹</span> 1,500 <span className="small text-muted"> via (COD) </span></h4>
                  <p className="text-muted">Tracking Status on: <span className="Today">11:30pm, Today</span></p> <button type="button" className="btn btn-outline-primary d-flex">Reached Hub, Delhi</button> */}
            </div>
            <div className="media-body w-100">
              <h5 className="bold">Payment Method</h5>
              {PaymentMethod(o)}
            </div>
            <div className="media-body w-100">
              <h5 className="bold">Order Summary</h5>
              {/* <p className="text-muted"> Qt: 1 Pair</p>
                  <h4 className="mt-3 mb-4 bold"> <span className="mt-5">₹</span> 1,500 <span className="small text-muted"> via (COD) </span></h4>
                  <p className="text-muted">Tracking Status on: <span className="Today">11:30pm, Today</span></p> <button type="button" className="btn btn-outline-primary d-flex">Reached Hub, Delhi</button> */}
              <ul className="OrderSummary">
                <li>
                  <p className="label">Subtotal: </p>
                  <p className="price">
                    {formatCurrency(
                      o.subtotal ? o.subtotal : 0,
                      settings.country_code
                    )}
                  </p>
                </li>
                {/* <li>
                  <p className="label">Tax: </p>
                  <p className="price">
                    {formatCurrency(o.tax ? o.tax : 0, settings.country_code)}
                  </p>
                </li> */}
                <li>
                  <p className="label">Discount Fees: </p>
                  <p className="price">
                    {formatCurrency(
                      o.discount_amount ? o.discount_amount : 0,
                      settings.country_code
                    )}
                  </p>
                </li>
                <li>
                  <p className="label">Delivery Fees: </p>
                  <p className="price">
                    {formatCurrency(
                      o.delivery_charges ? o.delivery_charges : 0,
                      settings.country_code
                    )}
                  </p>
                </li>
                <li className="CarttotalContainer">
                  <p className="label">Total: </p>
                  <p className="price">
                    {formatCurrency(
                      o.totalamount ? o.totalamount : 0,
                      settings.country_code
                    )}
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="ProductList">
            <h5 className="bold">Product List</h5>
            {o.cancelled === "Y" ? <button type="button" 
          //   onClick={(e) =>{
          // // location_id
          // window.open(url) }} 
          className="btn btn-primary mar-10r">Refund Status</button> : ""}
            {showItem(o.id)}
          </div>
        </div>

        {o.cancelled === "Y" ? "" : showDeliverdLogo(o)}
      </div>
    ));
  }

  function PaymentMethod(orderDetails) {
    if (orderDetails.paidByTransfer > 0) {
      return <p>Pay on Online</p>;
    } else if (orderDetails.paidByTransfer == 0.0) {
      return <p>Cash On Delivery</p>;
    }
  }

  function showCancelLogo() {
    return "Cancel";
  }

  function showDeliverdLogo(o) {
    if (o.delivered === "N") {
      return (
        <>
          {orderStatus(o.placed, o.shipped, o.delivered)}

          <div className="card-footer bg-white px-sm-3 pt-sm-4 px-0">
            <div className="row text-center ">
              {/* <div className="col my-auto border-line ">
              <h5>Re-Order</h5>
            </div> */}
              {o.shipped === "N" ? (
                <div className="col my-auto border-line ">
                  <h5
                    onClick={(e) =>
                      cancelOrder(e.currentTarget.attributes["tag"].value)
                    }
                    tag={o.id}
                  >
                    Cancel Order
                  </h5>
                </div>
              ) : (
                " "
              )}
            </div>
          </div>
        </>
      );
    } else if (o.delivered === "Y") {
      return <>{OnorderDelivered()}</>;
    }
  }

  function OnorderDelivered() {
    return (
      <div className="orderDelivered">
        <div className="orderDeliveredContainer">
          <div className="image">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="100%"
              fill="none"
              viewBox="0 0 512 512"
            >
              <path
                fill="#21A881"
                d="M437.019 74.981C388.667 26.629 324.38 0 256 0 187.619 0 123.332 26.629 74.98 74.981 26.629 123.333 0 187.62 0 256c0 68.381 26.629 132.668 74.98 181.021C123.332 485.372 187.619 512 256 512c68.38 0 132.667-26.628 181.019-74.979C485.371 388.668 512 324.381 512 256c0-68.38-26.629-132.667-74.981-181.019zM387.106 188.71L231.311 344.504a14.954 14.954 0 01-10.606 4.394 14.954 14.954 0 01-10.607-4.394l-85.205-85.203c-5.858-5.857-5.858-15.356-.001-21.213 5.858-5.858 15.355-5.858 21.214 0l74.599 74.597 145.188-145.188c5.858-5.858 15.355-5.858 21.213 0 5.859 5.858 5.859 15.355 0 21.213z"
              ></path>
            </svg>
          </div>
          <p className="m-0 pl-3">DELIVERED</p>
        </div>
      </div>
    );
  }

  function orderStatus(placed, shipped, delivered) {
    return (
      <div className="row px-3">
        <div className="col-12 col-md-8 m-auto">
          <ul id="progressbar">
            <li
              className={placed === "Y" ? "step0 active " : "step0"}
              id="step1"
            >
              PLACED
            </li>
            <li
              className={
                shipped === "Y"
                  ? "step0 text-center  active "
                  : "step0 text-center"
              }
              id="step2"
            >
              OUT FOR DELIVERY
            </li>
            <li className={
              shipped === "Y"
              ? "step0 text-right active"
              : "step0 text-right"}
              id="step3">
              DELIVERED
            </li>
          </ul>
        </div>
      </div>
    );
  }

  function cancelOrder(id) {
    dispatch({ type: appConstant.SHOW_LOADER });
    let data = {
      cancelled: "Y",
    };
    apiRequest
      .deleteOrderById(id, data)
      .then((res) => {
        if (res.data.errorMessage) {
          console.error(
            "Error msg getOrderBycustomerId",
            res.data.errorMessage
          );
        } else {
          notifySuccess("Succesfully  delete order id : " + id);
          setFetch(!refetch);
        }
      })
      .catch((e) => {
        console.error("fetch getOrderBycustomerId Info error ", e);
        setOrderItemId({});
      })
      .finally((e) => {
        dispatch({ type: appConstant.HIDE_LOADER });
      });
  }

  function showItem(id) {
    let item = orderItemId[id];
    let url = refundurl.id;
    return item ? (
      <>
      <table className="table table-striped d-none d-sm-none d-md-block" style={{marginTop: "10px"}}>
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col" className="text-uppercase">Product Name</th>
            <th scope="col" className="text-uppercase">quantity</th>
            <th scope="col" className="text-uppercase">product_price</th>
          </tr>
        </thead>
        <tbody>{itemGen(item, 'TABLE')}</tbody>
      </table>
      <ul className="list-group d-block d-sm-block d-md-none">
        {itemGen(item, 'LIST')}
      </ul>
      </>
    ) : (
      <button
        onClick={(e) =>
          getItemByCustomerId(e.currentTarget.attributes["tag"].value)
        }
        tag={id}
        className="btn btn-primary"
      >
        Click me to get product
      </button>
    );
  }

  function itemGen(item, type) {
    if(type == 'TABLE') {
      return item.map((i, index) => (
        <tr>
          <th scope="row">{index + 1}</th>
          <td>{i.product_name}</td>
          <td>{i.quantity}</td>
          <td>{i.product_price}</td>
        </tr>
      ));
    } else if(type == 'LIST') {
      return item.map((i, index) => ( 
        <li className="list-group-item">
          <p className="product_name text-uppercase"> <span className="font-weight-bold">Product Name : </span> <span>{i.product_name}</span></p>
          <p className="product_quantity text-uppercase"><span className="font-weight-bold">quantity : </span><span>{i.quantity}</span></p>
          <p className="product_price text-uppercase"><span className="font-weight-bold">product price : </span> <span>{i.product_price}</span></p>
        </li>
      ));
      
    } 
  }

  function getItemByCustomerId(id) {
    dispatch({ type: appConstant.SHOW_LOADER });
    apiRequest
      .getOrderById(id)
      .then((res) => {
        // setOrderItemId(res.data.items[0]);
        if (res.data.errorMessage) {
          console.error(
            "Error msg getOrderBycustomerId",
            res.data.errorMessage
          );
          serOrderData({});
        } else {
          let loc = res.data.detail.location_id;
          let order = id;
          let customer = JSON.parse(localStorage.getItem("GrocerboxAPP.USER")).id;
          apiRequest.getPaymentRefund(order,loc,customer).then((res) => {
            if(res.data.length>0){
            let rurl = res.data[0].refundUrl;
              setRefundurl({
                id:rurl,
              });
            }
          }); 
        }
            setOrderItemId({
              ...orderItemId,
              [id]: res.data.items ? res.data.items : [],
            });
      })
      .catch((e) => {
        console.error("fetch getOrderBycustomerId Info error ", e);
        setOrderItemId({});
      })
      .finally((e) => {
        dispatch({ type: appConstant.HIDE_LOADER });
      });
  }

  return (
    <div className="OrderProductCard container-fluid p-0">{orderdata()}</div>
  );
}
