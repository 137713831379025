import React, { useState, useEffect } from "react";
import apiRequest from "../lib/api/services";
import * as appConstant from "../provider/appConstant.type";
import parse from "html-react-parser";
import { useMainStateValue } from "../provider/AppProvider";
import { useParams } from "react-router-dom"; 
export default function ContentPage() {
    let { pageName, pageid } = useParams();
    const [{ customer }, dispatch] = useMainStateValue();
    const [title, setTitle] = useState("");
    const [data, setData] = useState("");
    window.scrollTo(0, 0);
    console.log(pageName, pageid);
    useEffect(() => {
        const fetchInfo = () => {
          dispatch({ type: appConstant.SHOW_LOADER });
          apiRequest
            .getPageDT(pageid)
            .then((res) => {
              if (res.data.errorMessage) {
                console.error("Error msg getPageDT", res.data.errorMessage);
                //serOrderData([]);
              } else {
                setTitle(res.data.name);
                setData(res.data.html_content);
              }
            })
            .catch((e) => {
              console.error("fetch getPageDT Info error ", e);
            })
            .finally((e) => {
              dispatch({ type: appConstant.HIDE_LOADER });
            });
        };
        fetchInfo();
      }, []);

    function showdata() {
    return parse(data);
    }
    return (
        <div className="commonPadding " id={pageName}>
            {showdata()}
        </div> 
    )
}