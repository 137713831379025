import React, { useState, useEffect, useReducer, useRef } from "react";
import ProductCard from "./ProductCard";
import { useProductFetch } from "../../hooks/apiHooks";
import { useInfiniteScroll } from "../../hooks/infiniteScroll";

export default function ProductGrid(d) {
  //console.log("--prop-- d", d);
  
const priceKey = window._env_.PRICE_KEY;
  const addToObject = function (oldobj, newObj) {
    for (let prop in newObj) {
      oldobj[prop] = newObj[prop];
    }
    return oldobj;
  };

  const dataReducer = (state, action) => {
    switch (action.type) {
      case "STACK_DATA":
        return {
          ...state,
          products: addToObject(state.products, action.products),
          totalRecords: action.totalRecords,
        };
      case "FETCHING_DATA":
        return { ...state, fetching: action.fetching };
      default:
        return state;
    }
  };

  const pageReducer = (state, action) => {
    switch (action.type) {
      case "ADVANCE_PAGE":
        return { ...state, pageNumber: state.pageNumber + 1 };
      default:
        return state;
    }
  };

  let requestDt = { };
  if (d?.catagories && !d?.onSearch) {
    requestDt["catagory_code"] = d?.catagories;
  }
  let queryParam = {
    locationId : d?.productSettings?.online_store_id ,
    pageSize : 50,
  }
  if (d?.subcatagories && !d?.onSearch) {
    requestDt["subcatagory_code"] = d?.subcatagories;
  }
  if (d?.selectedBrand.length > 0 && !d?.onSearch) {
    requestDt["brand_code"] = d?.selectedBrand.toString();
  }

  if(d?.onSearch){
    queryParam['value'] = d?.title;
  }

  if(d?.selectedPrice.length > 0) {
    queryParam['priceTag'] = d?.selectedPrice.toString();
  }
  if(d?.selectedOrderType.length > 0) {
    queryParam['orderBy'] = priceKey;
    queryParam['orderType'] = d?.selectedOrderType.toString();
  }
  //console.log("requestDt", requestDt);
  

  const [pager, pagerDispatch] = useReducer(pageReducer, {
    pageNumber: 1,
    data: requestDt,
    queryParam : queryParam,
  });
  const [productsData, productsDispatch] = useReducer(dataReducer, {
    products: {},
    totalRecords: 0,
    fetching: true,
  });
  //console.log("pager", pager);
  let bottomBoundaryRef = useRef(null);
  useProductFetch(pager, productsDispatch);
  useInfiniteScroll(bottomBoundaryRef, pagerDispatch);

  function setProductCard() {
    if (productsData?.products) {
      return Object.keys(productsData?.products).map((key) => (
        <ProductCard key={key} product={productsData?.products[key]} />
      ));
    }
  }

  return (
    <>
      <div className="col-12 col-md-10 col-lg-10 secoundary-product-container"> 
        <span className="product-count">
          {d?.title} {/*({productsData?.totalRecords}) */}
        </span>
        <div className="products-card-section product-grid-system">
          {setProductCard()}
          {productsData.fetching && <p>Loading ... </p>}
          <div
            id="page-bottom-boundary"
            style={{ border: "1px solid white" }}
            ref={bottomBoundaryRef}
          ></div>
        </div>
      </div>
    </>
  );
}
