import React, { useState, useEffect, useReducer, useRef } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useMainStateValue } from "../../provider/AppProvider";
import apiRequest from "../../lib/api/services";
import * as appConstant from "../../provider/appConstant.type";
import { removeValueInArray, urlParamCreater } from "../../lib/helpers/utils";
import ProductGrid from "../Product/ProductGrid";
import FilterPrice from "../Product/FilterPrice";
import FilterOrderBy from "../Product/FilterOrderBy";

export default function ProductSearch() {
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const queryPrice = query.get("price_code");
  const queryOrderType = query.get("ordertype");
  const [
    { imageUrl, productSettings, menuData },
    dispatch,
  ] = useMainStateValue();
  const querySearch = query.get("q");
  const [searchText, setSearchText] = useState(querySearch ? querySearch : "");

  const [brand, setBrand] = useState([]);
  const [price, setPrice] = useState([]);
  const [orderType, setOrderType] = useState([]);

  const [selectedBrand, setSelectBrand] = useState( querySearch ? querySearch.split(",") : []);
  const [selectedPrice, setSelectPrice] = useState(
    queryPrice ? queryPrice.split(",") : []
  );
  const [selectedOrderType, setSelectOrderType] = useState(
    queryOrderType ? queryOrderType.split(",") : []
  );
  const uri = '/search/' ;
  const url = uri ;

  useEffect(() => {
    const fetchpricedropdown = () => {
      dispatch({ type: appConstant.SHOW_LOADER });
      apiRequest
        .getPricebycatagory()
        .then((responses) => {
          const pricedt = responses.data;
          setPrice(pricedt);
        })
        .catch((e) => {
          console.error("getPricebycatagory API error ", e);
        })
        .finally((e) => {
          dispatch({ type: appConstant.HIDE_LOADER });
        });
    };
    fetchpricedropdown();
  }, [searchText]);

  function clearPriceFilter(e) {
    let c = selectedPrice.length > 0 ? selectedPrice : [];
    let r = removeValueInArray(c,e.target.dataset.remove);
    setSelectPrice(r);
    history.push(
        uri +
        urlParamCreater(selectedBrand, selectedPrice, selectedOrderType , true)
    );
  }

  function getPriceNameById(id) {
    let a = price.filter((v) => v.id.toString() === id.toString());
    return a.length > 0 ? a[0].name : "";
  }

  function filterDisplayPrice() {
    return selectedPrice.map((v) => (
      <span className="pr-2">
        {getPriceNameById(v)}&nbsp;
        <img
          src={"/images/svg/close-white.svg"}
          alt="close" data-remove={v}
          onClick={clearPriceFilter}
        />
      </span>
    ));
  }

  function filterClearAll() {
    return selectedPrice.length > 0 ? (
      <span
        className="product-clear-all cursor-pointer"
        onClick={clearAllFilter}
      >
        Clear all
      </span>
    ) : (
      ""
    );
  }

  function clearAllFilter() {
    //setSelectBrand([]);
    setSelectPrice([]);
    setSelectOrderType([]);
  }

  // http://13.232.223.153:8080/service/locationstocks/searchproduct/groupbyname?value=rice&locationId=1&pageSize=50&pageNumber=1

  //show only order by  price and sort filter

  //Total product -- content (lazy loading -- for data)

  {
    /* <div>
      We are sorry, no results found. Please try to search with a different
      spelling or check out our categories.
    </div> */
  }

  return (
    <div className="product-filter-section commonPadding">
      <div className="product-filter-main-page">
        <div className="products-items-content">
          <div className="product-search-clear">
            {filterDisplayPrice()}
            {filterClearAll()}
          </div>
          <FilterOrderBy
            uri={url}
            onSearch={true}
            brand={brand}
            selectedBrand={selectedBrand}
            setSelectBrand={setSelectBrand}
            price={price}
            selectedPrice={selectedPrice}
            setSelectPrice={setSelectPrice}
            orderType={orderType}
            selectedOrderType={selectedOrderType}
            setSelectOrderType={setSelectOrderType}
          />
        </div>
      </div>
      <div className="products-with-cart">
        <div className="col-12 col-md-2 col-lg-2 primary-product-container">
          <div className="products-filter-left-nav">
            <div className="products-filter-left-nav-Second-section pt-3">
              <h6 className="pb-1">Price</h6>
              <FilterPrice
                uri={url}
                onSearch={true}
                brand={brand}
                selectedBrand={selectedBrand}
                setSelectBrand={setSelectBrand}
                price={price}
                selectedPrice={selectedPrice}
                setSelectPrice={setSelectPrice}
                orderType={orderType}
                selectedOrderType={selectedOrderType}
                setSelectOrderType={setSelectOrderType}
              />
            </div>
          </div>
        </div>
        <ProductGrid
          uri={url}
          onSearch={true}
          imageUrl={imageUrl}
          title={querySearch}
          productSettings={productSettings}
          selectedBrand={selectedBrand}
          setSelectBrand={setSelectBrand}
          selectedPrice={selectedPrice}
          setSelectPrice={setSelectPrice}
          selectedOrderType={selectedOrderType}
          setSelectOrderType={setSelectOrderType}
        />
      </div>
    </div>
  );
}
