const requestURL = {
  customerSignup: "/service/customer" /*POST*/,
  customerLogin: "/service/customer/login" /*POST*/,
  customerByMacId : 'service/customer/info/', /*GET*/
  sendOtp: "/service/customer/sendOTP" /*POST*/,
  verifyOtp: "/service/customer/verifyOTP" /*POST*/,
  guestLogin: "/service/login" /*POST*/,
  logout:"/service/logout" /*POST*/,
  catagories: "/service/subcatagories?withCount=Y" /*sub-catagory-controller */,
  locationInfo: "/service/location/", /*GET*/
  tax:"/service/tax",
  catagoriesCount:
    "/service/locationstocks/catagories/count" /*locationstocks-controller */,
  banner: "/service/banner" /*banner-controller*/,
  brand: "/service/brand" /* service/brand */,
  imageUrl: "service/downloadFile/",
  searchProduct:
    "/service/locationstocks/searchproduct/groupbyname?pageSize=10&pageNumber=1",
  //searchProduct:"/service/locationstocks/searchproduct/groupbyname?pageSize=10&pageNumber=1?locationId="+${arg} /*POST*/
  productFullDetail : '/service/locationstocks/searchproduct/groupbyname', 
  productSearchbox : '/service/locationstocks/searchbox/groupbyname',
  brandByCatagory : '/service/brandbycatagory',
  priceByCatagory : '/service/pricesearchrange',
  productViewDetail : '/service/locationstocks/searchproduct',
  addressByCustomer :  '/service/deliveryaddressbycustomer/',
  saveAddressByCustomer: '/service/deliveryaddress',
  locationinvoicedetailsPay: '/service/locationinvoicedetails/pay',
  locationinvoicedetails: '/service/locationinvoicedetails',
  customerByID :'/service/customer/',
  orderByid : '/service/locationinvoicedetailssearch/',
  orderByorderid : '/service/locationinvoicedetails/',
  aboutpage:'/service/page/',
  cancelOrder:'/service/locationinvoicedetails/cancel',
  deliverycharges: '/service/courierprice',
  cmsPage: '/service/page/',
  newPayament: '/service/locationinvoicedetails/newpayment',
  newPayamentGatwayId: '/service/gatewaysearch',
  paymentGatewayKey : '/service/gatewaycredentialsearch',
  paymentGatewayKey : '/service/gatewaycredentialsearch',
  xenditStatus: "/service/locationinvoicedetails/xenditstatus/"
};

export default requestURL;
