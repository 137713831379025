import React, {useState} from "react";
import Orders from "../order/Orders";
import { useMainStateValue } from "../../provider/AppProvider";
import Profile from "../Profile/profile";
import AddressSection from "../checkout-components/AddressSection";

export default function MyAccount() {

  const [{ imageUrl }, dispatch] = useMainStateValue();
  const [activetab, setActiveTab] = useState('YourOrder');

  const [currentAddress, setCurrentAddress] = useState([]);
  const [choosenAddress, setChoosenAddress] =  useState(null);

  return (
    <div className="Myaccount"> 
         <div className="MyaccountContainer commonPadding">
            <div className={activetab == 'YourOrder' ? 'YourOrderContainer active' : 'YourOrderContainer'} onClick={() => {setActiveTab('YourOrder')}}>
                <div className="containerImg"> 
                  <img src={'/images/svg/order-green.svg'} />
                </div>
                <div className="containerContent">
                    <h5> Your Orders  </h5>
                    <span>Track, return, or buy things again</span>
                </div>
            </div>
            
            <div  className={activetab == 'YourProfile' ? 'YourProfileContainer active' : 'YourProfileContainer'} onClick={() => {setActiveTab('YourProfile')}}>
                 <div className="containerImg"> 
                  <img src={'/images/svg/order-green.svg'} />
                </div>
                <div className="containerContent">
                    <h5> Your Profile  </h5>
                    <span>Update Your Profile</span>
                </div>
            </div>
            <div className={activetab == 'YourAddress' ? 'YourAddressContainer active' : 'YourAddressContainer'} onClick={() => {setActiveTab('YourAddress')}}>
                 <div className="containerImg"> 
                  <img src={'/images/svg/order-green.svg'} />
                </div>
                <div className="containerContent">
                    <h5> Your Addresses  </h5>
                    <span>Add New, Update Addresses</span>
                </div>
            </div>
         </div>
         <div className="MyaccountOutputContainer commonPadding"> 
            <div className={activetab == 'YourOrder' ? 'YourOrderOutputContainer' : 'YourOrderOutputContainer d-none'}>   
               {activetab === 'YourOrder' ? <Orders /> : ""}  
            </div>
            <div className={activetab == 'YourProfile' ? 'YourProfileOutputContainer' : 'YourOrderOutputContainer d-none' }>  
                {activetab === 'YourProfile' ? <Profile /> : ""}  
             </div>
             <div className={activetab == 'YourAddress' ? 'YourAddressOutputContainer' : 'YourAddressOutputContainer d-none' }>  
                {activetab === 'YourAddress' ? <AddressSection choosenAddress={choosenAddress}
               setChoosenAddress={setChoosenAddress}  currentAddress={currentAddress} setCurrentAddress={setCurrentAddress}/>  : ""}  
             </div>
         </div>
    </div>
  );
}
