import * as appConstant from "./appConstant.type";
import { TokenService } from "../lib/api/storage.service";

export const mainInitialState = {
  productSettings: null,
  settings: null,
  apiTokenGuestOrCustomer: null,
  apiOnPageLoaded: false,
  apiFailed: false,
  user: null,
  enablePopUpType: "",
  isLoggedIn: false,
  pageLoaderEnable: true,
  showCart: false,
  imageUrl: window._env_.HOST_URL || process.env.REACT_APP_HOST_URL,
  showMenu: false,
  menuData: null,
  showLoader: false,
  customer: null,
  macId: null,
  otpEmail: null,
  enableCheckout: false,
  locationInfo: null,
  macId: null,
  loginMobile: null,
  tax: null,
  citys:null,
  payData:null,
  branddt:null
};

const mainReducer = (state, action) => {
  switch (action.type) {
    case appConstant.SET_TOKEN:
      TokenService.saveToken(action.data.xtoken);
      return {
        ...state,
        apiTokenGuestOrCustomer: action.data.xtoken,
      };
    case appConstant.REMOVE_TOKEN:
      TokenService.removeToken();
      return {
        ...state,
        apiTokenGuestOrCustomer: null,
      };
    case appConstant.ADD_HOST_API_DATA:
      return {
        ...state,
        productSettings: action.data.productSettings,
        settings: action.data.settings,
        apiTokenGuestOrCustomer: action.data.xtoken,
        apiFailed: false,
      };
    case appConstant.FAILED_HOST_API_DATA:
      return {
        ...state,
        productSettings: null,
        settings: null,
        apiTokenGuestOrCustomer: null,
        apiFailed: true,
      };
    case appConstant.HOST_API_LOADED:
      return {
        ...state,
        apiOnPageLoaded: true,
      };
    case appConstant.HOST_API_FAILED:
      return {
        ...state,
        apiOnPageLoaded: false,
      };
    case appConstant.CLOSE_POPUP:
      return {
        ...state,
        enablePopUpType: "",
      };
    case appConstant.SHOW_POPUP_LOGIN:
      return {
        ...state,
        enablePopUpType: "LOGIN",
        showMenu: false,
      };
    case appConstant.SHOW_POPUP_SIGNIN:
      return {
        ...state,
        enablePopUpType: "SIGNIN",
        showMenu: false,
      };
    case appConstant.SHOW_POPUP_FORGETPASSWORD:
      return {
        ...state,
        enablePopUpType: "FORGETPASSWORD",
        showMenu: false,
      };
    case appConstant.SHOW_POPUP_OTP:
      return {
        ...state,
        enablePopUpType: "OTP",
        showMenu: false,
      };
    case appConstant.SHOW_POPUP_NEWPASSWORD:
      return {
        ...state,
        enablePopUpType: "NEWPASSWORD",
        showMenu: false,
      };
    case appConstant.SHOW_POPUP_LOGOUT:
      return {
        ...state,
        enablePopUpType: "LOGOUT",
        showMenu: false,
      };
    case appConstant.OPEN_CART:
      return {
        ...state,
        showCart: true,
      };
    case appConstant.CLOSE_CART:
      return {
        ...state,
        showCart: false,
      };
    case appConstant.OPEN_MENU:
      return {
        ...state,
        showMenu: true,
      };
    case appConstant.CLOSE_MENU:
      return {
        ...state,
        showMenu: false,
      };
    case appConstant.ENABLE_CHECKOUT:
      return {
        ...state,
        enableCheckout: true,
      };
    case appConstant.DISABLE_CHECKOUT:
      return {
        ...state,
        enableCheckout: false,
      };
    case appConstant.ADD_MENU_DROPDOWN:
      return {
        ...state,
        menuData: action.data,
      };

    case appConstant.DELETE_MENU_DROPDOWN:
      return {
        ...state,
        menuData: null,
      };

    case appConstant.SHOW_LOADER:
      return {
        ...state,
        showLoader: true,
      };
    case appConstant.HIDE_LOADER:
      return {
        ...state,
        showLoader: false,
      };
      case appConstant.ADD_CITY:
        return {
          ...state,
          citys: action.data,
        };
      case appConstant.REMOVE_CITY:
        return {
          ...state,
          citys: null,
        };
    case appConstant.CUSTOMER_LOGIN:
      TokenService.saveToken(action.data.xtoken);
      TokenService.saveMACIDToken(action.data.macId);
      TokenService.saveUserData(action.data.customer);
      return {
        ...state,
        customer: action.data.customer,
        apiTokenGuestOrCustomer: action.data.xtoken,
        macId: action.data.macId,
      };
    case appConstant.CUSTOMER_LOGOUT:
      TokenService.removeToken();
      TokenService.removeMACIDToken();
      TokenService.removeUserData();
      return {
        ...state,
        customer: null,
        apiTokenGuestOrCustomer: null,
        macId: null,
      };
    case appConstant.SET_OTP_EMAIL:
      return {
        ...state,
        otpEmail: action.data.otpEmail,
      };
    case appConstant.REMOVE_OTP_EMAIL:
      return {
        ...state,
        otpEmail: "",
      };
    case appConstant.CUSTOMER_API_LOADED:
      TokenService.saveToken(action.data.xtoken);
      return {
        ...state,
        productSettings: action.data.productSettings,
        settings: action.data.settings,
        apiFailed: false,
        customer: action.data.customer,
        apiTokenGuestOrCustomer: action.data.xtoken,
        macId: action.data.macId,
      };
    case appConstant.ADD_LOCATION_INFO:
      return {
        ...state,
        locationInfo: action.data,
      };
    case appConstant.REMOVE_LOCATION_INFO:
      return {
        ...state,
        locationInfo: null,
      };

    case appConstant.ADD_LOGIN_MOBILE_MACID:
      return {
        ...state,
        macId: action.data.macId,
        loginMobile: action.data.loginMobile,
      };
    case appConstant.REMOVE_LOGIN_MOBILE_MACID:
      return {
        ...state,
        macId: null,
        loginMobile: null,
      };
    case appConstant.ADD_TAX_INFO:
      return {
        ...state,
        tax: action.data,
      };
    case appConstant.REMOVE_TAX_INFO:
      return {
        ...state,
        tax: null,
      };
      case appConstant.ADD_PAY_INFO:
        return {
          ...state,
          payData: action.data,
        };
      case appConstant.REMOVE_PAY_INFO:
        return {
          ...state,
          payData: null,
        };
        case appConstant.ADD_BRAND:
          return {
            ...state,
            branddt: action.data,
          };
        case appConstant.REMOVE_BRAND:
          return {
            ...state,
            branddt: null,
          };

    default:
      return state;
  }
};

export default mainReducer;
