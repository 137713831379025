const TOKEN_KEY = 'GrocerboxAPP.TOKEN' 
const USER = 'GrocerboxAPP.USER'
const MACID_TOKEN_KEY = 'GrocerboxAPP.MACID_TOKEN'

const TokenService = {
    getToken() {
        return localStorage.getItem(TOKEN_KEY)
    },

    saveToken(accessToken) {
        localStorage.setItem(TOKEN_KEY, accessToken)
    },

    removeToken() {
        localStorage.removeItem(TOKEN_KEY)
    },
    getUserData() {
        return JSON.parse(localStorage.getItem(USER));
    },

    saveUserData(d) {
        localStorage.setItem(USER, JSON.stringify(d))
    },

    removeUserData() {
        localStorage.removeItem(USER)
    },

    getMACIDToken() {
        return localStorage.getItem(MACID_TOKEN_KEY)
    },

    saveMACIDToken(refreshToken) {
        localStorage.setItem(MACID_TOKEN_KEY, refreshToken)
    },

    removeMACIDToken() {
        localStorage.removeItem(MACID_TOKEN_KEY)
    }

}

export { TokenService }