import React from "react";
import { useMainStateValue } from "../../provider/AppProvider";
import * as appConstant from "../../provider/appConstant.type";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import apiRequest from "../../lib/api/services";
import { notifyError } from "../../lib/helpers/utils";
import { useHistory } from "react-router-dom";

export default function Otp() {
  const history = useHistory();
  const [
    { enablePopUpType, otpEmail, macId, loginMobile ,enableCheckout},
    dispatch,
  ] = useMainStateValue();
  const handleClose = () => dispatch({ type: appConstant.CLOSE_POPUP });
  const showLogin = () => dispatch({ type: appConstant.SHOW_POPUP_LOGIN });

  function validate(values) {
    const errors = {};
    if (!values.otp) {
      errors.otp = "Required";
    }

    return errors;
  }

  const { handleSubmit, handleChange, handleBlur, touched, errors } = useFormik(
    {
      initialValues: {
        otp: "",
      },
      validate,
      onSubmit: (values) => {
        const data = {
          otp: values.otp,
          macId: macId,
          phoneNumber: loginMobile,
          email: otpEmail,
          device: "online"
        };
        dispatch({ type: appConstant.SHOW_LOADER });
        apiRequest
          .verifyOTP(data)
          .then((res) => {
            console.log(res);
            if (res.data.errorMessage) {
              notifyError("Verify OTP API error");
              console.log(res.data.errorMessage, "res.data.errorMessage");
              dispatch({ type: appConstant.CUSTOMER_LOGOUT });
            } else {
              localStorage.setItem("GrocerboxAPP.uuid", macId);
              const dt = {
                customer: res.data.customer,
                xtoken: res.data.x_Token,
                macId: res.data.macId,
              };
              dispatch({ type: appConstant.CUSTOMER_LOGIN, data: dt });
              if(enableCheckout){
                history.push(`/member/checkout`)
              }
              handleClose();
              // TODO Open verfiy otp popup
              //   const dt = {
              //     customer: res.data.customer,
              //     xtoken: res.data.x_Token,
              //     macId: res.data.macId,
              //   };
              //   dispatch({ type: appConstant.CUSTOMER_LOGIN, data: dt });
            }
          })
          .catch((e) => {
            console.error("OTP API error ", e);
            notifyError("Verify OTP API error");
          })
          .finally((e) => {
            console.log("----------> Closed");
            dispatch({ type: appConstant.HIDE_LOADER });
            //TODO Rmeove it once api works

            //TODO Rmeove it once api works
            //dispatch({ type: appConstant.SHOW_POPUP_NEWPASSWORD });
          });
      },
    }
  );

  return (
    <Modal
      show={enablePopUpType === "OTP"}
      onHide={handleClose}
      animation={false}
      backdrop="static"
      keyboard={false}
      dialogClassName="modalContent"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="OTPContainer ">
          <h4 className="text-center">Enter Verification Code</h4>
          <form onSubmit={handleSubmit}>
            <div className="text-center">
              <small className="form-text mb-3 ">
                We have sent OTP to
                {loginMobile ? <span className="textPrimary"> {loginMobile}</span> : ''}
                {otpEmail ? <span className="textPrimary"> {otpEmail}</span> : ''}
              </small>
            </div>
            <div className="form-group">
              <label> Enter OTP</label>
              <input
                type="text"
                name="otp"
                onChange={handleChange}
                onBlur={handleBlur}
                className="form-control"
              />
              {touched.otp && errors.otp ? (
                <small className="form-text  text-danger">{errors.otp}</small>
              ) : null}
            </div>
            <button type="submit" className="btn btn-primary w-100">
              Verify
            </button>

            <div className="text-center  mt-3">
              <span onClick={showLogin} className="textPrimary cursor-pointer">
                Cancel
              </span>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
}
