import React, { useEffect, useState } from "react";
import { useMainStateValue } from "../../provider/AppProvider";
import apiRequest from "../../lib/api/services";
import * as appConstant from "../../provider/appConstant.type";
import { useHistory } from "react-router-dom";
import CustomerAddress from "./CustomerAddress";

export default function AddressSection(i) {
  const history = useHistory();
  const [{ showCart, settings, customer }, dispatch] = useMainStateValue();

  const [enableReinitialize, setEnableReinitialize] = useState(false);
  const [refreshAddress, setRefreshAddress] = useState(false);
  const [showNewAddress, setShowNewAddress] = useState(false);
  const [initialValues, setInitialValues] = useState({
    firstname: "",
    mobileno: "",
    address: "",
    city: "",
    pincode: "",
    state: "",
  }); 

  if(i && i.currentAddress && i.currentAddress.length > 0){
    i.setChoosenAddress(i.currentAddress[0].id);
  }
  useEffect(() => {
    const fetchHostAddressInfo = () => {
      if (customer) {
        dispatch({ type: appConstant.SHOW_LOADER });
        apiRequest
          .getAddressByCustomerID(customer.id)
          .then((res) => {
            console.log("res", res);
            if (res.data.errorMessage) {
              console.error(
                "Error msg getAddressByCustomerID",
                res.data.errorMessage
              );
            } else {
              i.setCurrentAddress(res.data);
              res.data.length > 0
                ? setShowNewAddress(false)
                : setShowNewAddress(true);

              console.log('KPR AddressSection', res.data);
              i.setChoosenAddress(res.data[0].id); 
                
            }
          })
          .catch((e) => {
            console.error("getAddressByCustomerID API error ", e);
          })
          .finally((e) => {
            dispatch({ type: appConstant.HIDE_LOADER });
          });
      } else {
        history.push(`/`);
      }
    };
    fetchHostAddressInfo();
  }, [refreshAddress]);

  function showNewAddressDiv() {
    setInitialValues({
      firstname: "",
      mobileno: "",
      address: "",
      city: "",
      pincode: "",
      state: "",
    });
    setEnableReinitialize(true);
    setShowNewAddress(true);
  }

  function showEditAddressDiv(e) {
    //let id = e.target.getAttribute('data-addressid');
    let a = i?.currentAddress.filter(
      (v) => v.id.toString() === e.toString()
    )[0];
    //console.log("e", e, a);
    setInitialValues({
      id: a.id,
      firstname: a.customer_name,
      mobileno: a.phone_number,
      address: a.address1,
      city: a.city,
      pincode: a.zipcode,
      state: a.state,
    });
    setEnableReinitialize(true);
    setShowNewAddress(true);
  }

  function EditIcon(id) {
    return (
      <div className="icon">
        <svg
          onClick={(e) =>
            showEditAddressDiv(e.currentTarget.attributes["tag"].value)
          }
          tag={id}
          xmlns="http://www.w3.org/2000/svg"
          x="0"
          y="0"
          enableBackground="new 0 0 477.873 477.873"
          version="1.1"
          viewBox="0 0 477.873 477.873"
          xmlSpace="preserve"
        >
          <path
            fill="#21a881"
            d="M392.533 238.937c-9.426 0-17.067 7.641-17.067 17.067V426.67c0 9.426-7.641 17.067-17.067 17.067H51.2c-9.426 0-17.067-7.641-17.067-17.067V85.337c0-9.426 7.641-17.067 17.067-17.067H256c9.426 0 17.067-7.641 17.067-17.067S265.426 34.137 256 34.137H51.2C22.923 34.137 0 57.06 0 85.337V426.67c0 28.277 22.923 51.2 51.2 51.2h307.2c28.277 0 51.2-22.923 51.2-51.2V256.003c0-9.425-7.641-17.066-17.067-17.066z"
          ></path>
          <path
            fill="#21a881"
            d="M458.742 19.142A65.328 65.328 0 00412.536.004a64.85 64.85 0 00-46.199 19.149L141.534 243.937a17.254 17.254 0 00-4.113 6.673l-34.133 102.4c-2.979 8.943 1.856 18.607 10.799 21.585 1.735.578 3.552.873 5.38.875a17.336 17.336 0 005.393-.87l102.4-34.133c2.515-.84 4.8-2.254 6.673-4.13l224.802-224.802c25.515-25.512 25.518-66.878.007-92.393z"
          ></path>
        </svg>
      </div>
    );
  }

  function deleteAdrress(x) {
    dispatch({ type: appConstant.SHOW_LOADER });
    apiRequest
      .deleteAddressByCustomerID(x)
      .then((res) => {
        console.log("res", res);
        if (res.data.errorMessage) {
          console.error(
            "Error msg deleteAddressByCustomerID",
            res.data.errorMessage
          );
        } else {
          setRefreshAddress(!refreshAddress);
        }
      })
      .catch((e) => {
        console.error("deleteAddressByCustomerID API error ", e);
      })
      .finally((e) => {
        dispatch({ type: appConstant.HIDE_LOADER });
      });
  }

  function DeleteIcon(id) {
    return (
      <div className="icon" style={{ right: "35px" }}>
       
       <svg
        onClick={(e) =>
          deleteAdrress(e.currentTarget.attributes["tag"].value)
        }
        tag={id}
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 427 427"
    >
      <g fill="#21A881" clipPath="url(#clip0)">
        <path d="M272.398 154.703c-5.523 0-10 4.476-10 10v189c0 5.519 4.477 10 10 10 5.524 0 10-4.481 10-10v-189c0-5.524-4.476-10-10-10zM154.399 154.703c-5.524 0-10 4.476-10 10v189c0 5.519 4.476 10 10 10 5.523 0 10-4.481 10-10v-189c0-5.524-4.477-10-10-10z"></path>
        <path d="M68.399 127.121v246.378c0 14.562 5.34 28.238 14.668 38.05a49.248 49.248 0 0035.73 15.45H308a49.234 49.234 0 0035.73-15.45c9.328-9.812 14.668-23.488 14.668-38.05V127.121c18.543-4.922 30.558-22.836 28.078-41.864-2.484-19.023-18.691-33.253-37.879-33.257h-51.199V39.5a39.288 39.288 0 00-11.539-28.031 39.288 39.288 0 00-28.062-11.47H169a39.284 39.284 0 00-39.601 39.5V52h-51.2c-19.187.005-35.394 14.235-37.878 33.258-2.48 19.028 9.535 36.942 28.078 41.864zM308 406.999H118.797c-17.097 0-30.398-14.688-30.398-33.5v-245.5h249.999v245.5c0 18.812-13.301 33.5-30.398 33.5zm-158.601-367.5a19.248 19.248 0 015.675-13.894A19.261 19.261 0 01169 20h88.797a19.257 19.257 0 0113.925 5.605 19.239 19.239 0 015.676 13.895V52H149.399V39.5zM78.199 72h270.398c9.942 0 18 8.06 18 18 0 9.942-8.058 18.001-18 18.001H78.2c-9.942 0-18-8.06-18-18 0-9.942 8.058-18 18-18z"></path>
        <path d="M213.398 154.703c-5.523 0-10 4.476-10 10v189c0 5.519 4.477 10 10 10 5.524 0 10-4.481 10-10v-189c0-5.524-4.476-10-10-10z"></path>
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0H427V427H0z"></path>
        </clipPath>
      </defs>
    </svg> 
      </div>
    );
  }

  function UserSavedAddress() {
    return (
      <div className="use-this-address pt-0">
        <div className="text-right mb-3">
          <button
            type="submit"
            className="btn btn-primary px-3 py-1"
            onClick={showNewAddressDiv}
          >
            Add New
          </button>
        </div>
        <div className="row">{showAddressBydata()}</div>
      </div>
    );
  }

  function showAddressBydata() {
    return i?.currentAddress.map((v) => (
      <div className="col-12 col-lg-6 userAddresscard my-2" key={v.id}>
        <input
          type="radio"
          name="useraddress"
          value={v.id}
          id={"useraddress_" + v.id}
          onChange={(e) => i?.setChoosenAddress(e.currentTarget.value)}
        />
        <label className={i?.choosenAddress == v.id ? "existing-saved-user active" : "existing-saved-user"} htmlFor={"useraddress_" + v.id}>
          <span className="radioBTN"></span>
          {EditIcon(v.id)}
          {DeleteIcon(v.id)}
          <p className="existing-saved-user-name mb-0">{v.customer_name}</p>
          <p className="existing-saved-user-address mb-0">{v.address1}</p>
          <p className="existing-saved-user-address mb-0">{v.city}</p>
          <p className="existing-saved-user-address mb-0">{v.landmark}</p>
          <p className="existing-saved-user-address mb-0">
            {v?.state} <span>{v.zipcode}</span>
          </p>
        </label>
      </div>
    ));
  }

  return (
    <>
      {UserSavedAddress()}
      {showNewAddress ? (
        <CustomerAddress
          enableReinitialize={enableReinitialize}
          showNewAddress={showNewAddress}
          initialValues={initialValues}
          refreshAddress={refreshAddress}
          setRefreshAddress={setRefreshAddress}
        />
      ) : (
        ""
      )}
    </>
  );
}
