import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useMainStateValue } from "../../provider/AppProvider";
import { useCartValue } from "../../provider/CartContext";
import ImageTag from "../ImageLoader/imageTag";
import requestURL from "../../lib/api/requestURI";
import {
  formatCurrency,
  notifySuccess,
  notifyWarn,
  isItaNumber,
  getProductPriceWithoutDiscount,
  getProductPriceWithDiscount,
  checkStartEndDate,
  imageSrcType
} from "../../lib/helpers/utils";
import { useHistory } from "react-router-dom";
import SliderImage from "react-zoom-slider";

export default function ProductDetails(k) {
  const history = useHistory();
  const productDetail = k?.ProductDetailedInfo;
  //console.log('KPR',productDetail);
  const [{ imageUrl, settings, branddt, tax }, dispatch] = useMainStateValue();
  const [initquantity, setInitquantity] = useState(1);
  const productKey = Object.keys(productDetail);
  const [currentProductKey, setCurrentProductKey] = useState(productKey[0]);
  const [selectedProduct, setSelectedProduct] = useState(
    productDetail[currentProductKey]
  );

  const [stateImage, setStateImage] = useState({});

  // console.log("productKey --> currentProductKey --> selectedProduct", productKey, currentProductKey, selectedProduct);

  const {
    cartItems,
    addProduct,
    increase,
    decrease,
    removeProduct,
  } = useCartValue();

  // cart product
  let cartProduct =
    cartItems[cartItems.findIndex((item) => item.id === selectedProduct?.id)];

  useEffect(() => {
    setSelectedProduct(productDetail[currentProductKey]);
  }, [currentProductKey]);

  function handleMouseMove(e) {
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = ((e.pageX - left) / width) * 100;
    const y = ((e.pageY - top) / height) * 100;
    this.setState({ backgroundPosition: `${x}% ${y}%` });
  }

  function imageDisplay() { 
    let dtimg =
      selectedProduct?.image1 != null ? selectedProduct?.image1 : "NA";
      
    //if(selectedProduct?.id === 3729) dtimg = "adaefcc2-76d9-43a4-aae5-60cf4d3c3123";
    if (selectedProduct?.image1 != null) {
      let arry = [];
      let data = [];
      for (let index = 1; index < 5; index++) {
        arry.push(selectedProduct["image" + index]);
      }
      let filter = arry.filter((n) => n);
      if (filter.length > 0) {
        filter.map((x, i) =>
          data.push({
            image: !(x && x.indexOf("s3.amazonaws.com") > -1)? window._env_.API_URL + requestURL.imageUrl + x:x,
            text: "img" + x,
          })
        );

        return (
          <SliderImage
            data={data}
            width="500px"
            showDescription={false}
            direction="right"
          />
        );
      }
    } else {
      return (
        <React.Fragment>
          {/* <img src={imageUrl+'/images/png/onion-big.PNG'} alt="fruits"/>  */}
          <ImageTag
            imageid={dtimg}
            name={selectedProduct?.image1 != null ? selectedProduct?.name : ""}
            imagetype={imageSrcType.PRODCARDIMAGE}
            enableZoom={true}
          />
        </React.Fragment>
      );
    }
  }

  let productPriceWithoutDiscount = getProductPriceWithoutDiscount(
    selectedProduct,
    tax
  );
  let priceDetails = formatCurrency(
    productPriceWithoutDiscount ? productPriceWithoutDiscount : 0,
    settings.country_code
  );

  let isDiscount = selectedProduct?.discount > 0;

  isDiscount = isDiscount
    ? checkStartEndDate(
        selectedProduct?.discount_start_date,
        selectedProduct?.discount_end_date,
        settings.time_zone,
        settings.server_time_zone
      )
    : isDiscount;

  let discontPriceDetails = 0;
  if (isDiscount) {
    let productPriceWithDiscount = getProductPriceWithDiscount(
      selectedProduct,
      tax
    );
    discontPriceDetails = formatCurrency(
      productPriceWithDiscount ? productPriceWithDiscount : 0,
      settings.country_code
    );
  }

  // Price details

  function price() {
    return (
      <p className="product-price mt-1">
        MRP:
        {isDiscount ? <span className="text-strike"> {priceDetails}</span> : ""}
        {isDiscount ? (
          <span> {discontPriceDetails}</span>
        ) : (
          <span> {priceDetails}</span>
        )}
      </p>
    );
  }

  // let priceDetails = formatCurrency(
  //     selectedProduct ? selectedProduct.price1 : 0,
  //     settings.country_code
  // );

  // function price() {
  //     return (
  //         <p className="product-price mt-1">
  //             MRP: <span> {priceDetails}</span>
  //         </p>
  //     );

  //     // <p className="product-price">MRP <span> Rs 19.00</span></p>
  // } 
  let inStockStatus = selectedProduct.quantity > 0 || (selectedProduct.allow_backorder == 'Y' && selectedProduct.max_qty > 0) ? true : false;
  function stockView() {
    if (inStockStatus) {
      return (
        <div className="product-in-stock pt-2">
          <span> In Stock</span>
        </div>
      );
    } else {
      return (
        <div className="product-out-stock pt-2">
          <span> Out of Stock</span>
        </div>
      );
    }
  }

  function getBrandName(brandCode) {
    let brandName = brandCode ? branddt[brandCode]?.name : "";
    return brandName;
  }

  function onAddToCart() {
    if (isItaNumber(initquantity)) {
      addProduct({
        product: selectedProduct,
        quantity: Number(initquantity),
        stockQuantity: selectedProduct.quantity,
      });
      notifySuccess(
        "Successfully added " +
          initquantity +
          " QTY of " +
          selectedProduct.name +
          " is added to basket"
      );
    } else {
      notifyWarn("!!! Invalid Quantity !!!");
    }
  }

  function minus() {
    let q = cartProduct.quantity - 1;
    if (q > 0) {
      decrease({ id: cartProduct.id, quantity: q });
    } else {
      removeProduct(cartProduct);
    }
  }
  function add() { 
    let q = cartProduct.quantity + 1;
    let stockQuantity = cartProduct.stockQuantity;
    console.log('cartProduct', q < stockQuantity);  
    if(cartProduct.allow_backorder === "N" && q < stockQuantity) { 
      increase({ id: cartProduct.id, quantity: q });
    } else if (cartProduct.allow_backorder === "Y" && q < cartProduct.max_qty) {
      increase({ id: cartProduct.id, quantity: q });
    } else {
      notifyWarn("You've reached the maximum units allowed for the purchase of this item");
    }  
  }

  function inCartQuantity() {
    return cartProduct.quantity;
  }
  function btnAddCart() {
    let inCart = cartItems.find((item) => item.id === selectedProduct?.id);
    if (inCart) {
      if (inStockStatus) {
        return (
          <div className="add-sub-button-common fullWidth mt-3">
            <div className="value-button decrement" onClick={minus}></div>
            <div className="cart-count-input">
              <span>{inCartQuantity()} in box</span>
            </div>
            <div className="value-button Increment" onClick={add}></div>
          </div>
        );
      }
    } else {
      if (inStockStatus) {
        return (
          <React.Fragment>
            <div className="add-sub-button-common">
              <div
                className="value-button decrement"
                onClick={() => {
                  initquantity > 1
                    ? setInitquantity(initquantity - 1)
                    : setInitquantity(initquantity);
                }}
              ></div>
              <div className="cart-count-input">
                <span>{initquantity}</span>
              </div>
              <div
                className="value-button Increment"
                onClick={() => {
                  setInitquantity(initquantity + 1);
                }}
              ></div>
            </div>
            <div className="d-sm-block d-md-flex">
              <div className="buy-now">
                <button
                  type="button"
                  className="bg-white"
                  onClick={onAddToCart}
                >
                  Add to Cart
                </button>
              </div>
            </div>
          </React.Fragment>
        );
      }
      return "";
    }
  }

  function changeProductDetails(e, index) {
    setCurrentProductKey(index);
    setSelectedProduct(productDetail[currentProductKey]);
  }

  function showGramCategory() {
    if (productKey.length > 1) {
      return (
        <React.Fragment>
          {productDetail.map((x, i) => (
            <div
              id={i}
              className={`product-choose-box ${
                x.id == selectedProduct.id ? "active" : ""
              }`}
              onClick={(e) => changeProductDetails(e, i)}
            >
              <span>{x.capacity}</span>
            </div>
          ))}
        </React.Fragment>
      );
    } else {  
      return productDetail[0].capacity;
    }
  }

  function productDescription() {
    //console.log("Product Description", selectedProduct.description);
    if (selectedProduct.description == null) {
      return "";
    } else {
      return (
        <div className="cart-product-description">
          <ul className="p-0">
            <div className="pt-3 pb-3 cart-Product-details">
              Product Details
            </div>
            <li>{selectedProduct.description}</li>
          </ul>
        </div>
      );
    }
  }
  function goback() {
    history.goBack();
  }

  return (
    <div className="products-cart-section">
      <p className="products-cart-back cursor-pointer" onClick={goback}>
        <span className="pr-2">
          <i className="fa fa-angle-left" aria-hidden="true"></i>
        </span>
        Back
      </p>
      <div className="row m-0">
        <div className="col-12 col-lg-6 cart-product-center">
          <div className="cart-product-choosing">
            <div className="cart-product-img-sec text-center pb-3">
              {imageDisplay()}
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6 Cart-desc">
          <div className="cart-product-choosing-with-details">
            <ul className="p-0 mb-1">
              <li>
                <p className="txt_small m-0 ">
                  {getBrandName(selectedProduct.brand_code)}
                </p>
                <p className="m-0 product_name">{selectedProduct.name}</p>
              </li>
              {/* <li><p className="m-0">1 KG </p></li> */}
              <li>{price()}</li>

              {/* <li className="cart-button-sec">
                                <div className="row no-gutters">
                                        {btnAddCart()}
                                </div>
                            </li> */}
            </ul>
            <div className="Other-details">
              {/* <div className="Delivery-input p-0">
                                <span>
                                    <i className="fa fa-map-marker pb-2" aria-hidden="true"></i> <input type="text" placeholder="Enter Delivery Pincode" className="pb-1" />
                                    <span className="Delivery-input-check">Check</span>
                                </span>
                                <p className="pt-2">Enter pincode Check availablity </p>
                            </div> */}
              <div className="other-details-quantity d-flex">
                {showGramCategory()}
              </div>
            </div>
            {stockView()}
            <div className="cart-button-sec mt-3">
              <div className="row no-gutters WhenCartIsZero">
                {btnAddCart()}
              </div>
            </div>
            {productDescription()}
          </div>
        </div>
      </div>
    </div>
  );
}
