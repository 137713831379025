import React from "react";
import Slider from "react-slick";
import { settingsFeatureProduct } from "../../lib/helpers/sliderSetting";
import ProductCard from "../Product/ProductCard";

export default function FeatureProducts(p) {
  function SliderProduct() {
    if (p?.products.products) {
      return Object.keys(p?.products.products).map((key) => (
        <ProductCard
          data-index={key}
          key={key}
          product={p?.products.products[key]}
        />
      ));
    }
  }

  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  if (p?.products.products && isEmpty(p?.products.products)) {
    return "";
  } else {
    return (
      <div className="featured-products commonPadding bg-light-gray">
        <h3>Feature Products</h3>
        <div className="products-card-section container-fluid">
          {p?.products.products ? (
            <Slider {...settingsFeatureProduct}>{SliderProduct()}</Slider>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}
