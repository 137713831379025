import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { removeValueInArray, urlParamCreater } from "../../lib/helpers/utils";

export default function FilterPrice(i) {
  const history = useHistory();
  const uri = i?.uri;

  useEffect(() => {
    const fetchFilterInfo = () => {
      // console.log(
      //   "--effect-in-price ",
      //   i?.selectedBrand,
      //   i?.selectedPrice,
      //   i?.selectedOrderType,
      //   uri
      // );
      let queryParam = urlParamCreater(
        i?.selectedBrand,
        i?.selectedPrice,
        i?.selectedOrderType,
        i?.onSearch
      );
      history.push({
        pathname: uri,
        search: queryParam,
      });
    };
    fetchFilterInfo();
  }, [i?.selectedPrice]);

  function onpriceFilter(e) {
    //console.log("-----------", e.target.value, e.target.checked);
    if (e.target.checked) {
      let c = i?.selectedPrice.length > 0 ? i?.selectedPrice : [];
      i.setSelectPrice(c.concat(e.target.value));
    } else {
      let c = i?.selectedPrice.length > 0 ? i?.selectedPrice : [];
      let r = removeValueInArray(c, e.target.value);
      i.setSelectPrice(r);
      history.push(
          uri +
          urlParamCreater(
            i?.selectedBrand,
            i?.selectedPrice,
            i?.selectedOrderType,
            i?.onSearch
          )
      );
    }
  }
  function isCheckedPrice(c) {
    let p = i?.selectedPrice.filter((b) => {
      if (b.toString() === c.toString()) {
        return b;
      }
    });
    return p.length > 0 ? true : false;
  }

  function priceData() {
    if (i?.price.length > 0) {
      return i?.price.map((key) => (
        <label
          key={key.id}
          className="products-filter-left-nav-container"
        >
          {key.name}
          <input
            type="checkbox"
            value={key.id}
            defaultChecked={isCheckedPrice(key.id)}
            onChange={onpriceFilter}
          />
          <span className="checkmark" />
        </label>
      ));
    } else {
      return <p>N/A</p>;
    }
  }

  return priceData();
}
