import React from "react";
import { useMainStateValue } from "../../provider/AppProvider";
import * as appConstant from "../../provider/appConstant.type";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import apiRequest from "../../lib/api/services";

export default function NewPassword() {
  const [{ enablePopUpType, otpEmail }, dispatch] = useMainStateValue();
  const handleClose = () => dispatch({ type: appConstant.CLOSE_POPUP });
  const showLogin = () => dispatch({ type: appConstant.SHOW_POPUP_LOGIN });

  function validate(values) {
    const errors = {};
    if (!values.password) {
        errors.password = "Required";
      }else if(values.password.length < 7){
        errors.password = "Atleast password must 8 Char";
      }
      if (!values.repassword) {
        errors.repassword = "Required";
      }else if(values.repassword !== values.password){
        errors.repassword = "Password must be same";
      }
      return errors;
  }

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    errors,
  } = useFormik({
    initialValues: {
        password:"",
        repassword: ""
    },
    validate,
    onSubmit: (values) => {
      console.log(JSON.stringify(values));
      const data = {
        password: values.password,
        repassword: values.repassword
      };
      dispatch({ type: appConstant.SHOW_LOADER });
      apiRequest
        .verifyOTP(data)
        .then((res) => {
          console.log(res);
          if (res.data.errorMessage) {
            // TODO Show error
          } else {
            // TODO Open verfiy otp popup
            //   const dt = {
            //     customer: res.data.customer,
            //     xtoken: res.data.x_Token,
            //     macId: res.data.macId,
            //   };
            //   dispatch({ type: appConstant.CUSTOMER_LOGIN, data: dt });
          }
        })
        .catch((e) => {
          console.error("Repassword API error ", e);
        })
        .finally((e) => {
          console.log("----------> Closed");
          dispatch({ type: appConstant.HIDE_LOADER });
          //TODO Rmeove it once api works
        });
    },
  });

  return (
    <Modal
      show={enablePopUpType === "NEWPASSWORD"}
      onHide={handleClose}
      animation={false}
      backdrop="static"
      keyboard={false}
      dialogClassName="modalContent"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="NewPasswordContainer">
          <h4 className="text-center mb-4">Reset Password</h4>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label> New Password</label>
              <input type="password" 
              name="password"
              onChange={handleChange}
              onBlur={handleBlur}
              className="form-control" />
             {touched.password && errors.password ? (
                <small className="form-text  text-danger">{errors.password}</small>
              ) : null}
            </div>
            <div className="form-group">
              <label> Re-Enter New Password</label>
              <input type="password" 
              name="repassword"
              onChange={handleChange}
              onBlur={handleBlur} className="form-control" />
              {touched.repassword && errors.repassword ? (
                <small className="form-text  text-danger">{errors.repassword}</small>
              ) : null}
            </div>
            <button type="submit" className="btn btn-primary w-100">
              Reset
            </button>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
}
