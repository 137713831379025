import React, { useEffect } from "react";
import { useMainStateValue } from "../../provider/AppProvider";
import * as appConstant from "../../provider/appConstant.type";
import LoginOTP from "../Popup/LoginOTP";
import LoginModal from "../Popup/LoginModal";
import SignInModal from "../Popup/SignInModal";
import { Link } from "react-router-dom";
import ForgetPassword from "../Popup/ForgetPassword";
import NewPassword from "../Popup/NewPassword";
import Otp from "../Popup/Otp";
import Logout from "../Popup/Logout";
import { ToastContainer } from "react-toastify";
import { useCartValue } from "../../provider/CartContext";
import "react-toastify/dist/ReactToastify.css";
import SearchBar from "./SearchBar";

export default function HeaderSection() {
  const { cartItems , onOtherPageUpdate} = useCartValue();
  const [
    { user, showCart, imageUrl, showMenu, locationInfo, customer },
    dispatch,
  ] = useMainStateValue();
  function showlogin() {
    dispatch({ type: appConstant.SHOW_POPUP_LOGIN });
  }
  function showLogout() {
    dispatch({ type: appConstant.SHOW_POPUP_LOGIN });
  }
  function cartClick() {
    showCart
      ? dispatch({ type: appConstant.CLOSE_CART })
      : dispatch({ type: appConstant.OPEN_CART });
  }
  function openMenu() {
    showMenu
      ? dispatch({ type: appConstant.CLOSE_MENU })
      : dispatch({ type: appConstant.OPEN_MENU });
  }

  function loginButton() {
    return (
      <React.Fragment>
        <span className="user-icon" onClick={showlogin}></span> 
      </React.Fragment>
    );
  }
  function logoutButton() { 
    if(customer) {
      return (
        <React.Fragment>
          <span className="user-icon"></span>
          <span>Hi, {customer.first_name}</span>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <span className="user-icon"></span> 
        </React.Fragment>
      );
    }
   
  }  

  function headerMyaccountDropDowm() {
    return (
      <React.Fragment>
        <div className="myaccounDropDown">
          <ul>
            <li><Link to="/member/account">Your Account</Link></li>
            
            <li onClick={logout}>Logout</li> 
          </ul>
        </div>
      </React.Fragment>
    )
  }

  function callCart() {
    //console.log('cartItems',cartItems)
    return (
      <span className="cart-icon" onClick={cartClick}>
        {cartItems.length > 0 ? cartSize(cartItems.length) : ""}
      </span>
    );
  }
  function cartSize(s){
    return <b>{s}</b>
  }

  function logout() {
    dispatch({ type: appConstant.SHOW_POPUP_LOGOUT });
  }

  useEffect(() => {
    function checkUsetData() {
      //const item = localStorage.getItem('GrocerboxAPP.cart')
      const storage = localStorage.getItem('GrocerboxAPP.cart') ? JSON.parse(localStorage.getItem('GrocerboxAPP.cart')) : [];
      console.log('item --->', storage)
      if (storage) {
        //setUserData(item)
        onOtherPageUpdate(storage);
      }
    }
  
    window.addEventListener('storage', checkUsetData)
  
    return () => {
      window.removeEventListener('storage', checkUsetData)
    }
  }, [])


  // // \ from product setting  --m  api location controller to get location object

  // {
  //   "id": 1,
  //   "name": "SRI MURUGAN STORE",
  //   "address1": "APT TWR ASTER,TAMAN KEMAYORAN CONDOMINIUM, ",
  //   "address2": "KEMAYORAN, JAKARTA 10630",
  //   "contact_phone": "021-658.50.484", ----- show in mobile no in header info
  //   "city": "WA:0857-1919-5679", --------------
  //   "zipcode": "", --------------------
  //   "created_by": "admin",
  //   "updated_by": "admin",
  //   "created_date": "2020-05-08 03:26:57",
  //   "updated_date": "2020-06-13 03:44:40",
  //   "discount": 0,
  //   "discount_end_date": null,
  //   "discount_start_date": null
  // }
  //console.log(customer); 
  return (
    
    <header>
      {/* <div className="product-logo">
      <Link to="/" className="navbar-img">
          <img src={"/service/assets/clientLogo"} />
        </Link>
      </div> */}
      <nav className="navbar navbar-expand-lg navbar-light bg-white">
        <div className="header-side-menu-icon" onClick={openMenu}>
          <img src={"/images/png/menu.png"} />
        </div>
        <Link to="/" className="navbar-brand">
          <img src={"/images/png/logo-green.png"} />
        </Link>
        <SearchBar />
        <div className="right-header-container">
          <ul className="navbar-nav ml-auto">
            {/* <li className="nav-item header-location">
              <div>
                <span className="location-icon"></span>

                <a className="m-0">
                  <span className="font-weight-bold">Current address</span>
                  <span>
                    {locationInfo?.city} {locationInfo?.zipcode}
                  </span>
                </a>
              </div>
            </li>
            <li className="nav-item header-phone">
              <div>
                <span className="phone-icon"></span>
                <a className="m-0">{locationInfo?.contact_phone}</a>
              </div>
            </li> */}
            <li className="nav-item header-login_signin">
              <div className="user-name-icon">{customer ? logoutButton() : loginButton()}</div>
              {customer ? headerMyaccountDropDowm() : ''}
            </li>
            <li className="nav-item header-login_signin">
              <div className="user-name-icon">{callCart()}</div>
            </li>
          </ul>
        </div>
      </nav>
      {/* <LoginModal /> */}
      <LoginOTP />
      <SignInModal />
      <ForgetPassword />
      <Otp />
      <NewPassword />
      <Logout />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </header>
  );
}
