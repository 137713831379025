import React from "react";
import Slider from "react-slick";
import { settingsBanner } from "../../lib/helpers/sliderSetting";
import ImageTag from "../ImageLoader/imageTag";
import { imageSrcType } from "../../lib/helpers/utils";
import { useHistory } from "react-router-dom";

export default function Banner(b) {
  const history = useHistory();
  function loadBanner() {
    var t= b.banner.map((x) => {
      if (x.enabled !== "N") {
        return(<div key={x.id} data-index={x.id} className="banner-img" onClick={() => bannerClick(x.href_link)}> 
          <ImageTag  imageid={x.imageid} id={x.id} name={x.name} imagetype={imageSrcType.BANNERIMAGE}/>
        </div>); 
      }
    });
    console.log("banners" ,t);
    return t;
  } 
  function bannerClick(link) {
    if(link!=null) {
      history.push(link);
    }
  }

  function linkFound(x) {
    console.log('Banner Console', x);
    return(
        <div key={x.id} data-index={x.id} className="banner-img">
          <ImageTag  imageid={x.imageid} id={x.id} name={x.name} imagetype={imageSrcType.BANNERIMAGE}/>
        </div>); 
  }
  function linkNotFound() {

  }

  

  return (
    <div className="site-banner-Container">
      {b.banner ? <Slider {...settingsBanner}>{loadBanner()}</Slider> : ''}
    </div>
  );
}
