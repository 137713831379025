import { toast } from "react-toastify";
import isNumber from "lodash/isNumber";
const priceKey = window._env_.PRICE_KEY;

export const formatNumberUSD = (number) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(number);
};
export const formatNumberINR = (number) => {
  return new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  }).format(number);
};
export const formatNumberIDR = (number) => {
  return "Rp " + new Intl.NumberFormat("de-DE").format(number);
};
export const notNull = (e) => {
  return e !== undefined && e !== "undefined";
};

export const formatCurrency = (number, countryCode) => {
  if (countryCode === "IDR") {
    return formatNumberIDR(number);
  } else if (countryCode === "INR") {
    return formatNumberINR(number);
  } else if (countryCode === "USD") {
    return formatNumberUSD(number);
  }
};

export const dateCheck = (from, to, check) => {
  let fDate, lDate, cDate;
  fDate = Date.parse(from);
  lDate = Date.parse(to);
  cDate = Date.parse(check);

  if (cDate <= lDate && cDate >= fDate) {
    return true;
  }
  return false;
};

export const checkStartEndDate = (start, end, time_zone, server_time_zone) => {
  //console.log(start, end, time_zone, server_time_zone);
  if (start && end) {
    return dateCheck(start, end, new Date());
  }
  return false;
};

export const setDashInSpace = (s) => {
  s = encodeURIComponent(s)
  return s.replace(/ /g, "~");
};

export const setSpaceInDash = (s) => {
  s = decodeURIComponent(s)
  return s.replace(/~/g, " ");
};

export const notifySuccess = (str) => toast.success(str);
export const notifyWarn = (str) => toast.warn(str);
export const notifyInfo = (str) => toast.info(str);
export const notifyError = (str) => toast.error(str);

export const isItaNumber = (n) => {
  return !isNaN(Number(n)) && isNumber(parseInt(n));
};

export const genrateCartPriceData = (cartItems, tax) => {
  let subtotal = cartItems.reduce(
    (total, product) => total + (product[priceKey] * product.quantity),
    0
  );
  let taxAmt = cartItems.reduce(
    (taxrs, product) =>
      taxrs + (getTaxAmtbyProduct(product, tax) * product.quantity),
    0
  );
  let deliveryFees = 0;
  let discountFees = cartItems.reduce(
    (discountrs, product) =>
      discountrs + (getDiscountbyProduct(product) * product.quantity),
    0
  );
  let uam = 0;
  subtotal = subtotal;
  let total = subtotal + deliveryFees - discountFees;
  return {
    subtotal: subtotal,
    tax: taxAmt,
    deliveryFees: deliveryFees,
    discountFees: discountFees,
    uam: uam,
    total: total,
  };
};

export const genrateCartPrice = (cartItems, countryCode, tax) => {
  let i = genrateCartPriceData(cartItems, tax);
  return {
    subtotal: formatCurrency(i.subtotal, countryCode),
    tax: formatCurrency(i.tax, countryCode),
    deliveryFees: formatCurrency(i.deliveryFees, countryCode),
    discountFees: formatCurrency(i.discountFees, countryCode),
    total: formatCurrency(i.total, countryCode),
    uam: i.uam,
  };
};

export const imageSrcType = {
  BANNERIMAGE: "BANNERIMAGE",
  PRODCARDIMAGE: "PRODCARDIMAGE",
  BRANDIMAGE: "BRANDIMAGE",
};

export const removeValueInArray = (arr, r) => {
  for (var i = 0; i < arr.length; i++) {
    if (arr[i] === r) {
      arr.splice(i, 1);
    }
  }
  return arr;
};

export const isPreAND = (...r) => {
  let isAND = false;
  for (let c in r) {
    if (r[c].length > 0) {
      isAND = true;
    }
  }
  return isAND ? "&" : "";
};

export const urlParamCreater = (
  selectedBrand,
  selectedPrice,
  selectedOrderType,
  onSearch
) => {
  let isdataThr =
    selectedBrand.length > 0 ||
    selectedPrice.length > 0 ||
    selectedOrderType.length > 0
      ? "?"
      : "";
  let isONserch = onSearch ? "q=" : "brand_code=";
  return (
    isdataThr +
    (selectedBrand.length > 0 ? isONserch + selectedBrand.toString() : "") +
    (selectedPrice.length > 0
      ? isPreAND(selectedBrand) + "price_code=" + selectedPrice.toString()
      : "") +
    (selectedOrderType.length > 0
      ? isPreAND(selectedBrand, selectedPrice) +
        "orderby=" +
        priceKey +
        "&ordertype=" +
        selectedOrderType.toString()
      : "")
  );
};

export const getProductPriceWithoutDiscount = (product, tax) => {
  let price = 0;
  if (product) {
    price = product[priceKey];// + getTaxAmtbyProduct(product, tax);
    price = price;
  }
  return price;
};

export const getProductPriceWithoutTax = (product) => {
  let price = 0;
  if (product) {
    price = product[priceKey]; 
  }
  return price;
};


export const getProductPriceWithDiscount = (product, tax) => {
  let price = 0;
  if (product) {
    price = product[priceKey];
    price = price  - getDiscountbyProduct(product);// + getTaxAmtbyProduct(product, tax);
    price = price;
  }
  return price;
};

export const getTaxAmtbyProduct = (product, tax) => {
  let price = product[priceKey];
  let tax_amount = 0;
  if (product.tax1) {
    tax_amount = tax_amount + (price * (tax ? tax[product.tax1] : 0)) / 100;
  }
  if (product.tax2) {
    tax_amount = tax_amount + (price * (tax ? tax[product.tax2] : 0)) / 100;
  }
  if (product.tax3) {
    tax_amount = tax_amount + (price * (tax ? tax[product.tax3] : 0)) / 100;
  }
  product.tax_amount = tax_amount;
  return tax_amount;
};

export const getDiscountbyProduct = (product) => {
  let price = product[priceKey];
  let discount_amount = 0;
  if (
    product.discount &&
    checkStartEndDate(
      product.discount_start_date,
      product.discount_end_date,
      "",
      ""
    )
  ) {
    discount_amount = (product.discount * price) / 100;
  }
  return discount_amount;
};
