import React from "react";
import { useMainStateValue } from "../../provider/AppProvider";
import * as appConstant from "../../provider/appConstant.type";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import apiRequest from "../../lib/api/services";

export default function ForgetPassword() {
  const [{ enablePopUpType }, dispatch] = useMainStateValue();
  const handleClose = () => dispatch({ type: appConstant.CLOSE_POPUP });
  const showLogin = () => dispatch({ type: appConstant.SHOW_POPUP_LOGIN });

  function validate(values) {
    const errors = {};
    if (!values.email) {
      errors.email = "Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Invalid email address";
    }

    return errors;
  }

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    errors,
  } = useFormik({
    initialValues: {
      email: "",
    },
    validate,
    onSubmit: (values) => {
      console.log(JSON.stringify(values));
      const data = {
        email: values.email,
      };
      dispatch({ type: appConstant.SHOW_LOADER });
        apiRequest
          .sendOTP(data)
          .then((res) => {
            console.log(res);
            if (res.data.errorMessage) {
              // TODO SHow error
            } else {
              // TODO Open verfiy otp popup
            //   const dt = {
            //     customer: res.data.customer,
            //     xtoken: res.data.x_Token,
            //     macId: res.data.macId,
            //   };
            //   dispatch({ type: appConstant.CUSTOMER_LOGIN, data: dt });
            }
          })
          .catch((e) => {
            console.error("Login API error ", e);
          })
          .finally((e) => {
            console.log("----------> Closed");
            dispatch({ type: appConstant.HIDE_LOADER });
            
            //TODO Rmeove it once api works
            dispatch({ type: appConstant.SET_OTP_EMAIL, data:'test@gmail.com' });
            dispatch({ type: appConstant.SHOW_POPUP_OTP });
          });
    },
  });

  return (
    <Modal
      show={enablePopUpType === "FORGETPASSWORD"}
      onHide={handleClose}
      animation={false}
      backdrop="static"
      keyboard={false}
      dialogClassName="modalContent"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="ForgetPasswordContainer">
          <h4 className="text-center">Forget Password</h4>
          <form onSubmit={handleSubmit}>
            <small className="form-text mb-3 ">
              Enter your email and will send a OTP to reset your password
            </small>
            <div className="form-group">
              <label> Email</label>
              <input
                type="email"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                className="form-control"
              />
              {touched.email && errors.email ? (
                <small className="form-text  text-danger">{errors.email}</small>
              ) : null}
            </div>
            <button type="submit" className="btn btn-primary w-100">
              Submit
            </button>
          </form>
          <div className="text-center  mt-3">
            <span onClick={showLogin} className="textPrimary cursor-pointer ">
              Back to Login
            </span>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
