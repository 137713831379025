import React from "react";
import { useFormik } from "formik";
import apiRequest from "../../lib/api/services";
import * as appConstant from "../../provider/appConstant.type";
import { useMainStateValue } from "../../provider/AppProvider";

export default function CustomerAddress(i) {
  const [{ showCart, settings, customer, citys }, dispatch] = useMainStateValue();
  



  function validate(values) {
    const errors = {};

    if (!values.firstname) {
      errors.firstname = "Required";
    }

    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    // var phoneno = /^\+(?:[0-9] ?){6,14}[0-9]$/;
    if (!values.mobileno) {
      errors.mobileno = "Required";
    } else if (!values.mobileno.match(phoneno)) {
      errors.mobileno = "Enter a valid Mobile number";
    }

    if (!values.address) {
      errors.address = "Required";
    }

    if (!values.city) {
      errors.city = "Required";
    }
    var pindodereg = /^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/;
    if (!values.pincode) {
      errors.pincode = "Required";
    } else if (!values.pincode.match(pindodereg)) {
      errors.pincode = "Enter a valid Pincode";
    }

    if (!values.state) {
      errors.state = "Required";
    }

    return errors;
  }
  //{ handleSubmit, formik.handleChange, formik.handleBlur, touched, errors }
  const formik = useFormik({
    enableReinitialize: i?.enableReinitialize,
    initialValues: i?.initialValues,
    validate,
    onSubmit: (values) => {
      console.log(JSON.stringify(values));
      const data = {
        address1: values.address,
        address2: "",
        city: values.city,
        landmark: values.state,
        customer_id: customer.id,
        customer_name: values.firstname,
        phone_number: values.mobileno,
        country: "",
        zipcode: values.pincode,
      };
      dispatch({ type: appConstant.SHOW_LOADER });
      if (i?.initialValues?.id) {
        data["id"] = i?.initialValues?.id;
        apiRequest
          .editAddressByCustomerId(i?.initialValues?.id, data)
          .then((res) => {
            if (res.data.errorMessage) {
            } else {
              console.log("res.data", res.data);
              i.setRefreshAddress(!i.refreshAddress);
            }
          })
          .catch((e) => {
            console.error("Save address API error ", e);
          })
          .finally((e) => {
            dispatch({ type: appConstant.HIDE_LOADER });
          });
      } else {
        apiRequest
          .saveAddressByCustomerId(data)
          .then((res) => {
            if (res.data.errorMessage) {
            } else {
              console.log("res.data", res.data);
              i.setRefreshAddress(!i.refreshAddress);
            }
          })
          .catch((e) => {
            console.error("Save address API error ", e);
          })
          .finally((e) => {
            dispatch({ type: appConstant.HIDE_LOADER });
          });
      }
    },
  });

  //   {
  //     firstname: a.customer_name,
  //     mobileno: a.phone_number,
  //     address: a.address1,
  //     city: a.city,
  //     pincode: a.zipcode,
  //     state: "",
  //   }

  function errorTicker(s) {
    let t = formik.touched[s],
      e = formik.errors[s];
    return t && e ? <small className="form-text  text-danger">{e}</small> : "";
  }

  function loadOptionCity(){
    return citys.map((c) => (
      <option key={c.name} value={c.name}>{c.name}</option>
    ))
  }

  return (
    <div
      className={
        "product-shipping-page " + (i?.showNewAddress ? " " : "d-none")
      }
    >
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-12 col-sm-6 col-md-6 product-shipping-users">
            <label htmlFor="fname">First name</label>
            <input
              type="text"
              name="firstname"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Name"
              maxLength="50"
              value={formik.values.firstname}
            />
            {errorTicker("firstname")}
          </div>
          <div className="col-12 col-sm-6 col-md-6 product-shipping-users">
            <label htmlFor="Address">Address</label>
            <input
              type="text"
              name="address"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              maxLength="350"
              placeholder="Flat/street"
              value={formik.values.address}
            />
            {errorTicker("address")}
          </div>
          <div className="col-12 col-sm-6 col-md-6 product-shipping-users">
            <label htmlFor="mob-number">Mobile-Number</label>
            <input
              type="text"
              name="mobileno"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Mobile-Number"
              maxLength="10"
              value={formik.values.mobileno}
            />
            {errorTicker("mobileno")}
          </div>
          <div className="col-12 col-sm-6 col-md-6 product-shipping-users">
            <label htmlFor="Address">Landmark</label>
            <input
              type="text"
              name="state"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              maxLength="200"
              placeholder="Landmark"
              value={formik.values.landmark}
            />
            {errorTicker("state")}
          </div>

          <div className="col-12 col-sm-6 col-md-6 product-shipping-users">
            <label htmlFor="Pincode">Pincode</label>
            <input
              type="text"
              name="pincode"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Pincode"
              value={formik.values.pincode}
            />
            {errorTicker("pincode")}
          </div>
          <div className="col-12 col-sm-6 col-md-6 product-shipping-users">
            <label htmlFor="City">City</label>
            {/* <input
              type="text"
              name="city"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="City"
              maxLength="50"
              value={formik.values.city}
            /> */}
            <select
              name="city"
              value={formik.values.city}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="form-control"
            >
              <option value="">Select a City</option>
              {loadOptionCity()}
            </select>
            {errorTicker("city")}
          </div>

          <div className="ButtonContainer pt-3 w-100 d-block text-right">
            <button type="submit" className="btn btn-primary px-5 py-2">
              Save
            </button>
            {/* <button onClick={i?.setShowNewAddress(false)} className="btn btn-primary px-5 py-2">
              Cancel
            </button> */}
          </div>
        </div>
      </form>
    </div>
  );
}
