import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useMainStateValue } from "../../provider/AppProvider";
import { useCartValue } from "../../provider/CartContext";
import ImageTag from "../ImageLoader/imageTag";
import { imageSrcType } from "../../lib/helpers/utils";
import {
  formatCurrency,
  checkStartEndDate,
  setDashInSpace,
  setSpaceInDash,
  notifySuccess,
  notifyWarn,
  isItaNumber,
  getProductPriceWithoutDiscount,
  getProductPriceWithDiscount
} from "../../lib/helpers/utils";

export default function ProductCard(i) {
  //console.log('KPR ProductCard', i?.product);
  const productInfo = i?.product;
  const productKey = Object.keys(productInfo);

  const [{ settings, tax , branddt}, dispatch] = useMainStateValue();
  const {
    cartItems,
    addProduct,
    increase,
    decrease,
    removeProduct,
  } = useCartValue();
  productKey.sort(function(a,b){
    return a < b?-1:0;
  });
  const [currentProductKey, setCurrentProductKey] = useState(
    setDashInSpace(productKey[0])
  );
  const [choosenProductInfo, setChoosenProductInfo] = useState(
    productInfo[setSpaceInDash(currentProductKey)]
  );
  const [initquantity, setInitquantity] = useState(1);

  // let linkURI =
  // "/productview/" +
  // setDashInSpace(choosenProductInfo?.name) +
  // "/" +
  // setDashInSpace(productKey[0].toString());

  useEffect(() => {
    setChoosenProductInfo(productInfo[setSpaceInDash(currentProductKey)]);

    // linkURI =  "/productview/" +
    // setDashInSpace(choosenProductInfo?.name) +
    // "/" +
    // setDashInSpace(currentProductKey.toString());

    //console.log("currentProductKey ", currentProductKey);
    //console.log("cartItems ", cartItems);
    //console.log("choosenProductInfo ", choosenProductInfo);
  }, [currentProductKey]);

  // cart product
  let cartProduct =
    cartItems[
      cartItems.findIndex((item) => item.id === choosenProductInfo?.id)
    ];

  let productPriceWithoutDiscount = getProductPriceWithoutDiscount(
    choosenProductInfo,
    tax
  );


  
  let priceDetails = formatCurrency(
    productPriceWithoutDiscount ? productPriceWithoutDiscount : 0,
    settings.country_code
  );

  let isDiscount = choosenProductInfo?.discount > 0;

  isDiscount = isDiscount
    ? checkStartEndDate(
        choosenProductInfo?.discount_start_date,
        choosenProductInfo?.discount_end_date,
        settings.time_zone,
        settings.server_time_zone
      )
    : isDiscount;

  let discontPriceDetails = 0;
  if (isDiscount) {
    let productPriceWithDiscount = getProductPriceWithDiscount(
      choosenProductInfo,
      tax
    );
    discontPriceDetails = formatCurrency(
      productPriceWithDiscount ? productPriceWithDiscount : 0,
      settings.country_code
    );
  }

  // Price details

  function price() {
    return (
      <div className="product-price">
        MRP:
        {isDiscount ? <span className="text-strike"> {priceDetails}</span> : ""}
        {isDiscount ? (
          <span> {discontPriceDetails}</span>
        ) : (
          <span> {priceDetails}</span>
        )}
      </div>
    );

    //   return (<div className="product-price">
    //   MRP: <span className="text-strike"> Rs 100.00</span>  <span> Rs 19.00</span>
    // </div>);
  }

  function displayDiscount() {
    //TODO start date and end date -- by systemtime
    // system -- time_zone: "Asia/Jakarta" get googletime on pageload
    //console.log('choosenProductInfo?.discount', choosenProductInfo)
    if (isDiscount) {
      return (
        <div className="offer-band">
          <p className="mb-0">
            GET <span>{choosenProductInfo?.discount}%</span>OFF
          </p>
        </div>
      );
    } else {
      return "";
    }
  }

  // Is outofstock
  let isInOutofstock = choosenProductInfo?.quantity > 0 || (choosenProductInfo?.allow_backorder == 'Y' && choosenProductInfo?.max_qty > 0) ? true : false;
  // let isAllow_Backorder = choosenProductInfo?.allow_backorder == 'Y' ? true : false;
  function outOfStock() {
    if (isInOutofstock) { 
      return "";
    } else {
      return <p className="OutOfStock">Out Of Stock</p>;
    }
  }

  function deliveruCharge() {
    return (
      <div className="product-price">
        Delivery Fee: <span> Rs 10.00</span>
      </div>
    );
  }

  // add as function api as code brand_code: null as map
  let brandCode = choosenProductInfo?.brand_code;
  let isbrand = brandCode ? true : false;
  function brand() {
    if (isbrand) {
      let brandName = branddt[brandCode]?.name;
      return brandName;
    } else {
      return "";
    }
  }

  function qu() {
    return (
      <div className="product-initial-quality">
        <div>
          1 <span>{productInfo?.capacity}</span>
        </div>
      </div>
    );
  }

  function imageDisplay() {
    let dtimg =
      choosenProductInfo?.image1 != null ? choosenProductInfo?.image1 : "NA";
    //if(choosenProductInfo?.id === 3729) dtimg = "8685b17d-1247-4c8a-bc3b-37c8b2ac77b8";
    //console.log("---> ",dtimg)
    return (
      <Link
        to={
          "/productview/" +
          setDashInSpace(choosenProductInfo?.name) +
          "/" +
          setDashInSpace(currentProductKey.toString())
        }
      >
        {/* <img src='/images/png/onion-big.PNG'} alt="onion-big" /> */}
        <ImageTag
          imageid={dtimg}
          name={
            choosenProductInfo?.image1 != null ? choosenProductInfo?.name : ""
          }
          imagetype={imageSrcType.PRODCARDIMAGE}
        />
      </Link>
    );
  }

  function productDropdownChange(e) {
    setCurrentProductKey(setSpaceInDash(e.target.value));
    setChoosenProductInfo(productInfo[setSpaceInDash(currentProductKey)]);
  }

  function productDropdown() {
    if (productKey.length > 1) {
      return (
        <div className="product-quality-choosing">
          <select
            value={setDashInSpace(currentProductKey)}
            onChange={productDropdownChange}
            className="form-control form-control-lg"
          >
            {productKey.map((x) => (
              <option key={setDashInSpace(x)} value={setDashInSpace(x)}>
                {x}
              </option>
            ))}
          </select>
        </div>
      );
    } else { 
      return (<div className="product-quality-label">{productKey[0]}</div>);
    }
  }
  //choosenProductInfo?.quantity
  
  function onAddToCart() {
    let inStockStatus = choosenProductInfo.quantity >= initquantity || (choosenProductInfo.allow_backorder == 'Y' && choosenProductInfo.max_qty >= initquantity)? true : false;
   let qty = choosenProductInfo.allow_backorder == 'Y' ? choosenProductInfo.max_qty > choosenProductInfo.quantity ? choosenProductInfo.max_qty : choosenProductInfo.quantity :choosenProductInfo.quantity
    if(!inStockStatus){
      notifyWarn("!!! You can order maximum "+qty+" items  !!!");
      return;
    }
    if (isItaNumber(initquantity)) {
      addProduct({
        product: choosenProductInfo,
        quantity: Number(initquantity),
        stockQuantity: choosenProductInfo.quantity,
      });
      notifySuccess(
        "Successfully added " +
          initquantity +
          " QTY of " +
          choosenProductInfo.name +
          " is added to basket"
      );
    } else {
      notifyWarn("!!! Invalid Quantity !!!");
    }
  }

  function minus() {
    let q = cartProduct.quantity - 1;
    if (q > 0) {
      decrease({ id: cartProduct.id, quantity: q });
    } else {
      removeProduct(cartProduct);
    }
  }

  function add() { 
    let q = cartProduct.quantity + 1;
    let stockQuantity = cartProduct.stockQuantity;
    console.log('cartProduct.stockQuantity', cartProduct);
    if(cartProduct.allow_backorder === "N" && q < stockQuantity) { 
      increase({ id: cartProduct.id, quantity: q });
    } else if (cartProduct.allow_backorder === "Y" && q < cartProduct.max_qty) {
      increase({ id: cartProduct.id, quantity: q });
    } else {
      notifyWarn("You've reached the maximum units allowed for the purchase of this item");
    } 
    // if (cartProduct.allow_backorder === "Y" || q < stockQuantity) {  
    //   if(q < cartProduct.max_qty) {
    //     notifyWarn("You've reached the maximum units allowed for the purchase of this item");
    //     return;
    //   }
     
      
    // } else {
    //   //alert("no!!!");
    //   notifyWarn("You've reached the maximum units allowed for the purchase of this item");
    // }
  }

  function remove() {
    removeProduct(cartProduct);
  }

  function inCartQuantity() {
    return cartProduct.quantity;
  }

  function showAddCart() {
    let inCart = cartItems.find((item) => item.id === choosenProductInfo?.id);
    if (inCart) {
      if (isInOutofstock) {
        return (
          <div className="product-add-detail d-flex">
            <div className="row m-0 pt-2 w-100">
              <div className="col-12 d-flex pl-0 ">
                {/* <span className="Qty pr-3">Qty</span> */}
                <div className="add-sub-button-common fullWidth">
                  <div className="value-button decrement" onClick={minus}></div>
                  <div className="cart-count-input">
                    <span>{inCartQuantity()} in box</span>
                  </div>
                  <div className="value-button Increment" onClick={add}></div>
                </div>
              </div>
              {/* <div className="col-4 product-add-button p-0">
                <button type="button" onClick={onAddToCart}>
                  Add
                </button>
              </div> */}
            </div>
          </div>
        );
      }
    } else {
      if (isInOutofstock) {
        return (
          <div className="product-add-detail d-flex">
            <div className="row m-0 pt-2 w-100">
              <div className="col-8 d-flex pl-0 ">
                <span className="Qty pr-3">Qty</span>
                <input
                  type="text"
                  className="w-100"
                  value={initquantity}
                  onChange={(e) => setInitquantity(e.target.value)}
                />
              </div>
              <div className="col-4 product-add-button p-0">
                <button type="button" onClick={onAddToCart}>
                  Add
                </button>
              </div>
            </div>
          </div>
        );
      }
      return outOfStock();
    }
  }

  return (
    <div className="product-card">
      <div className="product-card-container">
        <div className="productFirstPart">
          <div className="product-card-img text-center">{imageDisplay()}</div>
          {displayDiscount()} 
          <div className="product-owner">
            <p className="m-0"> {brand()}</p>
          </div>
         
          <div className="product-name">
            <Link
              to={
                "/productview/" +
                setDashInSpace(choosenProductInfo?.name) +
                "/" +
                setDashInSpace(currentProductKey.toString())+`?productId=${choosenProductInfo?.id}`
              }
            >
              <p>
                {choosenProductInfo?.name}
                {/* <span> - Medium</span> */}
              </p>
            </Link>
          </div>
          {/* {qu()} */}
          {productDropdown()}
        </div>
        <div className="product-details">
          {price()}
          {/* {deliveruCharge()} */}
          {showAddCart()}
        </div>
      </div>
    </div>
  );
}
