import React from "react";
import Slider from "react-slick";
import { settingsBrandProduct } from "../../lib/helpers/sliderSetting";
import ImageTag from "../ImageLoader/imageTag";
import { imageSrcType } from "../../lib/helpers/utils";

export default function Brand(b) {
  console.log('b.brand', b.brand);
  let brand = []
  Object.keys(b.brand).map((x) => (
    b.brand[x].featured === "Y" ? brand.push(b.brand[x]) : ""
  )); 
  if(brand.length > 0){
    return (
      <div className="featured-products commonPadding">
        <h3>Brands</h3>
        <div className="brandSslider container-fluid">
          {brand ? <Slider {...settingsBrandProduct}>
            {brand.map((x) => (
              <div data-index={x.id} key={x.id} className="brand-image">
                 <ImageTag  imageid={x.imageid} id={x.id} name={x.name} imagetype={imageSrcType.BRANDIMAGE}/>
              </div>
            ))}
          </Slider> : ''}
        </div>
      </div>
    );
  }else{
    return "";
  }
}

