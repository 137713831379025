import React, { Component, useEffect, useState } from "react";
import Autosuggest from "react-autosuggest";
import apiRequest from "../../lib/api/services";
import { useMainStateValue } from "../../provider/AppProvider";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import { DebounceInput } from "react-debounce-input";
import {
  formatCurrency,
  checkStartEndDate,
  setDashInSpace,
  setSpaceInDash,
  notifySuccess,
  notifyWarn,
  isItaNumber,
  getProductPriceWithoutDiscount,
  getProductPriceWithDiscount,
} from "../../lib/helpers/utils";
// Imagine you have a list of languages that you'd like to autosuggest.

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = (suggestion) => suggestion.name;

const renderSuggestion = (suggestion) => {
  //console.log("suggestion ", suggestion.linkURI);
  return (
    <Link to={suggestion.linkURI}>
      {suggestion.name}
      {/* <div className="product-price">
        MRP:
        {suggestion.price.isDiscount ? <span className="text-strike"> {suggestion.price.priceDetails}</span> : ""}
        {suggestion.price.isDiscount ? (
          <span> {suggestion.price.discontPriceDetails}</span>
        ) : (
          <span> {suggestion.price.priceDetails}</span>
        )}
      </div> */}
    </Link>
  );
};



//);
export class AutoComplete extends Component {
  constructor() {
    super();

    this.state = {
      value: "",
      suggestions: [],
      productKeys: [],
      productList: [],
    };

    this.apiService = apiRequest;
    //this.productList = [];
    this.Payload = {
      name: "",
      enabled: "Y",
    };
    this.parem = {
      orderBy: "name",
      orderType: "asc",
      pageSize: "50",
      pageNumber: "1",
    };
  }

  onChange = (event, { newValue, method }) => {
    this.setState({
      value: newValue,
    });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    //console.log('value ---->', value.length);
    if (value.length > 2) {
      this.getSuggestionsfromApi(value);
    }
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };
  onKeyUp = (event) => {
    this.setState({
      value: event.target.value,
    });
    if (event.key === "Enter") {
      window.location.href = "/search/?q=" + event.target.value;
    }
  };
  onsearch = () => {
    window.location.href = "/search/?q=" + this.state.value;
  };
  renderInputComponent = (inputProps) => {
    return (
      <>
        {/* <input {...inputProps} className="form-control" onKeyPress={this.onKeyUp} value={this.state.value}   /> */}
        <DebounceInput
          minLength={2}
          debounceTimeout={300}
          onKeyPress={this.onKeyUp}
          value={this.state.value}
          {...inputProps}
        />
        <div className="input-group-append">
          <span className="input-group-text" onClick={this.onsearch}>
            <img src="/images/svg/search-white.svg" />
          </span>
        </div>
      </>
    );
  };

  getSuggestionsfromApi = (value) => {
    this.Payload.name = value;
    this.apiService
      .getProductSearchbox(this.Payload, this.parem)
      .then((res) => {
        //this.productList = res.data;
        this.setSuggestionsToUi(res.data);
      })
      .catch((e) => {});
  };

  // autocompleteLink = (productList, currentProduct, currentProductKey) => {
  //   let ProductKey = Object.keys(currentProduct)[0];
  //   return (
  //     "/productview/" +
  //     setDashInSpace(currentProductKey) +
  //     "/" +
  //     setDashInSpace(ProductKey.toString())
  //   );
  // };

  // getPrice = (product, productList) => {
  //   // let isDiscount = choosenProductInfo?.discount > 0;

  //   // isDiscount = isDiscount
  //   // ? checkStartEndDate(
  //   //     choosenProductInfo?.discount_start_date,
  //   //     choosenProductInfo?.discount_end_date,
  //   //     settings.time_zone,
  //   //     settings.server_time_zone
  //   //   )
  //   // : isDiscount;
  //   let productPriceWithoutDiscount = getProductPriceWithoutDiscount(
  //     productList[product].pks,
  //     tax
  //   );
  //   let priceDetails = formatCurrency(
  //     productPriceWithoutDiscount ? productPriceWithoutDiscount : 0,
  //     settings.country_code
  //   );
  //   return{
  //     isDiscount : false,
  //     priceDetails : priceDetails,
  //     discontPriceDetails: ""
  //   };
  // };

  onSuggestionSelected = () => {
    this.setState({
      value: "",
    });
  };

  setSuggestionsToUi = (productList) => {
    console.log("productList", Object.keys(productList));
    // let link = Object.keys(currentProduct)[0];
    // return (
    //   "/productview/" +
    //   setDashInSpace(currentProductKey) +
    //   "/" +
    //   setDashInSpace(ProductKey.toString())
    // );
    let suggestionsArray = [];
    Object.keys(productList).map((product) => {
      console.log("product", product);
      let obj = {};
      obj["name"] = 
      productList[product];
      obj["linkURI"] =
        "/productview/" +
        setDashInSpace(product) +
        "/" ;
      suggestionsArray.push(obj);
    });
    this.setState({
      productKeys: Object.keys(productList),
      productList: productList.products,
    });

    this.setState({
      suggestions: suggestionsArray,
    });
  };

  render() {
    const { value, suggestions } = this.state;

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: "Search Products",
      value,
      onChange: this.onChange,
    };

    // Finally, render it!
    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        //ref="autosuggest"
        onSuggestionSelected={this.onSuggestionSelected}
        renderInputComponent={this.renderInputComponent}
      />
    );
  }
}
