import React, { useEffect, useState } from "react";
import ProductCard from "../Product/ProductCard";
import { useLocation, useParams } from "react-router-dom";
import apiRequest from "../../lib/api/services";
import { useMainStateValue } from "../../provider/AppProvider";
import {
    formatCurrency, setDashInSpace, setSpaceInDash, isItaNumber, notifySuccess,
    notifyWarn
} from "../../lib/helpers/utils";
import ImageTag from "../ImageLoader/imageTag";
import ProductDetails from "../Product/ProductDetails";


export default function ProductList() {
    const [{ productSettings }, dispatch] = useMainStateValue();
    const [productDetail, setProductDetail] = useState([]);
    const [isApiLoaded,setApiLoaded] = useState(false);

    let { productName } = useParams();

    let params = useParams();

    const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const productId = searchParams.get('productId');

    const data = {
        "name": decodeURIComponent(productName)
        //"capacity": setSpaceInDash(productId)
        // "id":productId
    }


    useEffect(() => {
        const fetchProductDetailInfo = () => {
            setApiLoaded(false);
            apiRequest
                .getproductViewDetail(data, productSettings.online_store_id)
                .then((responses) => {
                    const getProductDetails = responses.data.products;
                    if(getProductDetails.length){
                        setProductDetail(getProductDetails);
                        setApiLoaded(true);
                    }
                     else {
                        window.location.href="/";
                    }
                })
                .catch((e) => {
                    console.error("fetch Dashboard Info error ", e);
                                setApiLoaded(false);
                });
        };
        fetchProductDetailInfo();
    }, []);
 
    //console.log("product Detail", productDetail);

    return (
        isApiLoaded ? (<div>
            <ProductDetails ProductDetailedInfo ={productDetail}/>
        </div>)  : " "
    );
}
