import React from "react";
import Slider from "react-slick";
import { settingsMenu, settingsBanner } from "../../lib/helpers/sliderSetting";
import { Link } from "react-router-dom";

export default function SlideMenu(c) {
  return (
    <>
      <div className="main-category-container">
      {c.catagories ? (
        <Slider {...settingsMenu}>
          {c.catagories.map((x) => (
            <div className="category" key={x.id} data-index={x.id}>
              <Link to={"/product/" + x.code}>{x.name}</Link>
            </div>
          ))}
        </Slider>
      ) : (
        ""
      )}
    </div>
    </>
  );
}
