import React from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import TemplateLayout from "./TemplateLayout";
import Dashboard from "../components/PageTemplate/Dashboard";
import ProductList from "../components/PageTemplate/ProductList";
import ProductView from "../components/PageTemplate/ProductView";
import CheckOut from "../components/PageTemplate/Checkout";
import OrderConfirmation from "../components/PageTemplate/OrderConfirmation";
import MyAccount from "../components/PageTemplate/MyAccount";
import ProductSearch from "../components/PageTemplate/ProductSearch"
import history from "./history";
import AboutUs from "../cms/about";
import ContactUS from "../cms/contact-us";
import PrivacyPolicy from "../cms/privacy-policy";
import ContentPage from "../cms/contentPage";

export default function Routes() {
  return (
      <Router history={history}>
        <Switch>
          <TemplateLayout exact path="/" component={Dashboard} />
          <TemplateLayout exact path="/product/:catagories" component={ProductList} />
          <TemplateLayout exact path="/product/:catagories/:subcatagories" component={ProductList} />
          <TemplateLayout path="/productview/:productName/" component={ProductView} />
          <TemplateLayout exact path="/search/" component={ProductSearch} />

          <TemplateLayout exact path="/member/checkout" component={CheckOut} />
          <TemplateLayout exact path="/member/orderconfirmation" component={OrderConfirmation} />
          <TemplateLayout exact path="/member/account" component={MyAccount} />
          <TemplateLayout exact path="/page/About-Us" component={AboutUs} />
          <TemplateLayout exact path="/page/ContactUS" component={ContactUS} />
          <TemplateLayout exact path="/page/PrivacyPolicy" component={PrivacyPolicy} />
          <TemplateLayout exact path="/page/Content-Page/:pageName/:pageid" component={ContentPage} />
          

          <Route path="" render={() => <Redirect to="/" />} />
        </Switch>
      </Router>
  );
}
