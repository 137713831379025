import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { useMainStateValue } from "../../provider/AppProvider";

import CartSummary from "../checkout-components/CartSummary";

import { useCartValue } from "../../provider/CartContext";
import CartProduct from "../Cart/CartProduct";
import Payment from "../payement/payment";

import AddressSection from "../checkout-components/AddressSection";

import apiRequest from "../../lib/api/services";
import * as appConstant from "../../provider/appConstant.type";

export default function CheckOut() {
  const [{ showCart, settings, customer, locationInfo }, dispatch] = useMainStateValue();
  const { cartItems, itemCount, clearCart } = useCartValue();

  const [currentAddress, setCurrentAddress] = useState([]);

  const [choosenAddress, setChoosenAddress] = useState(null);

  const [deliveryList , setDeliveryList] = useState([]);

  const [deliveryValueAmt, setDeliveryValueAmt] = useState("");
  

  function getUMOdata(){
    let totalUOM = 0
    totalUOM = cartItems.reduce(
      (total, product) => total + (product.uom_rate) * product.quantity,
      0
    );

    return totalUOM;
  } 

  useEffect(() => {
    const fetchInfo = () => {
      if (choosenAddress != null) {
        console.log(choosenAddress);
        console.log(currentAddress)
        if(currentAddress?.length){
          const customerAddress = currentAddress.filter(e => e.id == choosenAddress)[0];
          console.log("customerAddress", customerAddress)
          dispatch({ type: appConstant.SHOW_LOADER });
          let weight = getUMOdata()||1
          if(weight){
            let dt = {
              origin: locationInfo.city,
              destination: customerAddress.city,
              weight: weight,
            };
            apiRequest
              .getDelyOption(dt)
              .then((res) => {
                if (res.data.errorMessage) {
                  console.error(
                    "Error msg Delviay address feee",
                    res.data.errorMessage
                  );
                } else {
                  console.log("set ", res.data)
                  setDeliveryList(res.data.data)
                }
              })
              .catch((e) => {
                console.error("fetch Delviay address feee Info error ", e);
              })
              .finally((e) => {
                dispatch({ type: appConstant.HIDE_LOADER });
              });
          }
        }
      }
    };
    fetchInfo();
  }, [choosenAddress]);

  function Cart() {
    return (
      <div className="">
        <div className="cart-user-border cart-user-count-section checkoutCart ">
          <div className="cartHeader">
            <p>Your Cart Items</p>
            <div className="closeIcon" />
          </div>
          <div className="CartSection">
            <ul>
              {cartItems.map((x) => (
                <CartProduct key={x.id} product={x} />
              ))}
              {cartItems.length == 0 ? (
                <li className="text-center justify-content-center">
                  <p className="m-0">
                    Your Cart Is Empty
                    <Link to={"/"} className="btn btn-primary px-3 py-1">
                      Shop Now
                    </Link>
                  </p>
                </li>
              ) : (
                ""
              )}
            </ul>
          </div>
        </div>
      </div>
    );
  }

  // function deliveryValue() {
  //   console.log('deliveryValueAmt', deliveryValueAmt);
  //   return deliveryValueAmt != "" ?  deliveryValueAmt.toString() : "";
  // }

  function onDeliveryFilter(e) {
    if (e.target.value !== "") { 
      setDeliveryValueAmt(parseInt(e.target.value));
    } else {
      setDeliveryValueAmt("");
    }
  }
  function loadOptionDeleivy() {
    console.log(deliveryList)
    return deliveryList?.map((d) => (
      <option key={d.name} value={d.price}>{d.name}</option>
    ));
  }

  function Delviverydata() {
    let isDisabled = choosenAddress == null ? true : false;
    return (
      <>
        <p className="font-weight-bold">Delivery option</p>
        <div className="row Summary-section-sub-font pt-2 pb-2 total-border m-0 DeliveryFees align-items-center mb-3">
          <div className="pl-0">Delivery Fees</div>
          <div className="text-right ml-3 pr-0">
            <select
              onChange={onDeliveryFilter}
              className="form-control form-control-lg" 
              disabled={isDisabled} 
            >
               <option value="">Select a delivery mode</option>
              {loadOptionDeleivy()}
            </select>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="">
      <div className="Product-user-details-body">
        <div className="Product-user-details-body-content">
          <div className="row m-0">
            <div className="col-12 col-lg-8 Product-user-details-body-shipping p-4 bg-white">
              {Cart()} 
              <p className="font-weight-bold">Shipping Details</p>
              <AddressSection
                choosenAddress={choosenAddress}
                setChoosenAddress={setChoosenAddress}
                currentAddress={currentAddress}
                setCurrentAddress={setCurrentAddress}
              />
               {Delviverydata()}
            </div>
            <div className="col-12 col-lg-4 Summary-section summary-mobile mb-0">
              <CartSummary deliveryValueAmt={deliveryValueAmt}  setDeliveryValueAmt={setDeliveryValueAmt}/>
              <Payment
                choosenAddress={choosenAddress}
                currentAddress={currentAddress}
                deliveryValueAmt={deliveryValueAmt}
                
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
