import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { urlParamCreater } from "../../lib/helpers/utils";

export default function FilterOrderBy(i) {
  const history = useHistory();
  const uri = i?.uri;

  useEffect(() => {
    const fetchFilterInfo = () => {
      // console.log(
      //   "--effect-in-order ",
      //   i?.selectedBrand,
      //   i?.selectedPrice,
      //   i?.selectedOrderType,
      //   uri
      // );
      let queryParam = urlParamCreater(
        i?.selectedBrand,
        i?.selectedPrice,
        i?.selectedOrderType,
        i?.onSearch
      );
      history.push({
        pathname: uri,
        search: queryParam,
      });
    };
    fetchFilterInfo();
  }, [i?.selectedOrderType]);

  function onOrderTypeFilter(e) {
    //console.log("-----------", e.target.value);
    if (e.target.value !== "") {
      let c = i?.selectedOrderType.length > 0 ? [] : [];
      c.push(e.target.value);
      i.setSelectOrderType(c);
    } else {
      i.setSelectOrderType([]);
      if (i?.selectedOrderType.length === 0) {
        history.push(
            uri +
            urlParamCreater(
              i?.selectedBrand,
              i?.selectedPrice,
              i?.selectedOrderType,
              i?.onSearch
            )
        );
      }
    }
  }

  function currentValue() {
    //console.log("object", i?.selectedOrderType);
    return i?.selectedOrderType.length > 0 ? i?.selectedOrderType[0] : "";
  }

  function orderByData() {
    return (
      <div className="products-items-content-right">
        <span className="products-items-content-span">Sort By</span>
        <select
          value={currentValue()}
          onChange={onOrderTypeFilter}
          className="form-control form-control-lg"
        >
          <option className="filter-items-content" value="">Select</option>
          <option className="filter-items-content" value="asc">price - low to high</option>
          <option className="filter-items-content" value="desc">price - high to low</option>
        </select>
      </div>
    );
  }

  return orderByData();
}