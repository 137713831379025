export const ADD_HOST_API_DATA = 'ADD_HOST_API_DATA';
export const FAILED_HOST_API_DATA = 'FAILED_HOST_API_DATA';

export const SET_TOKEN = 'SET_TOKEN';
export const REMOVE_TOKEN = 'REMOVE_TOKEN';

export const HOST_API_LOADED = 'API_LOADED';
export const HOST_API_FAILED = 'API_FAILED';
export const HOST_API_CLEAR = 'API_CLEAR';
export const CUSTOMER_API_LOADED = 'CUSTOMER_API_LOADED';

export const CLOSE_POPUP = 'CLOSE_POPUP';
export const SHOW_POPUP_LOGIN = 'SHOW_POPUP_LOGIN';
export const SHOW_POPUP_SIGNIN = 'SHOW_POPUP_SIGNIN';
export const SHOW_POPUP_FORGETPASSWORD = 'SHOW_POPUP_FORGETPASSWORD';
export const SHOW_POPUP_OTP = 'SHOW_POPUP_OTP';
export const SHOW_POPUP_NEWPASSWORD = 'SHOW_POPUP_NEWPASSWORD';
export const SHOW_POPUP_LOGOUT = 'SHOW_POPUP_LOGOUT';

export const OPEN_CART = 'OPEN_CART';
export const CLOSE_CART = 'CLOSE_CART';

export const ENABLE_CHECKOUT = 'ENABLE_CHECKOUT';
export const DISABLE_CHECKOUT = 'DISABLE_CHECKOUT';

export const OPEN_MENU = 'OPEN_MENU';
export const CLOSE_MENU = 'CLOSE_MENU';

export const ADD_MENU_DROPDOWN = 'ADD_MENU_DROPDOWN';
export const DELETE_MENU_DROPDOWN = 'DELETE_MENU_DROPDOWN';

export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';

export const CUSTOMER_LOGIN = 'CUSTOMER_LOGIN';
export const CUSTOMER_LOGOUT = 'CUSTOMER_LOGOUT';

export const SET_OTP_EMAIL = 'SET_OTP_EMAIL';
export const REMOVE_OTP_EMAIL = 'REMOVE_OTP_EMAIL';

export const ADD_LOCATION_INFO = 'ADD_LOCATION_INFO';
export const REMOVE_LOCATION_INFO = 'REMOVE_LOCATION_INFO';

export const ADD_LOGIN_MOBILE_MACID = 'ADD_LOGIN_MOBILE_MACID';
export const REMOVE_LOGIN_MOBILE_MACID = 'REMOVE_LOGIN_MOBILE_MACID';

export const ADD_TAX_INFO = 'ADD_TAX_INFO';
export const REMOVE_TAX_INFO = 'REMOVE_TAX_INFO';

export const ADD_PAY_INFO = 'ADD_PAY_INFO';
export const REMOVE_PAY_INFO = 'REMOVE_PAY_INFO';

export const ADD_CITY = "ADD_CITY";
export const REMOVE_CITY = "REMOVE_CITY";

export const ADD_BRAND = "ADD_BRAND";
export const REMOVE_BRAND = "REMOVE_BRAND";


