import React, { useEffect } from "react";
import Route from "./routes";
import { useMainStateValue } from "./provider/AppProvider";
import apiRequest from "./lib/api/services";
import * as appConstant from "./provider/appConstant.type";
import "./styles/App.scss";
import PageLoader from "./components/Loader/loader";
import { TokenService } from "./lib/api/storage.service";
import { notifyError } from "./lib/helpers/utils";

function App() {
  const [{ apiOnPageLoaded }, dispatch] = useMainStateValue();

  useEffect(() => {
    const fetchHostInfo = () => {
      dispatch({ type: appConstant.SHOW_LOADER });
      let macId = TokenService.getMACIDToken();

      if (macId) {
        apiRequest
          .onCustomerLogin(macId)
          .then((responses) => {
            const c = responses[0].data;
            const init = responses[1].data;

            console.log("customer ", c);
            console.log("init ", init);

            if (responses[0].data.status === 500) {
              dispatch({
                type: appConstant.SET_TOKEN,
                data: {
                  xtoken: c.x_Token,
                },
              });
              apiRequest
                .getHeaderAndMenuInfo(
                  init.productSettings.online_store_id,
                  init.settings.time_zone
                )
                .then((res) => {
                  console.log(res);
                  dispatch({ type: appConstant.ADD_HOST_API_DATA, data: init });
                  dispatch({
                    type: appConstant.ADD_MENU_DROPDOWN,
                    data: res[0].data,
                  });
                  dispatch({
                    type: appConstant.ADD_LOCATION_INFO,
                    data: res[1].data,
                  });
                  //tax
                  let taxdt = res[2].data.reduce(
                    (acc, a) => ((acc[a.id] = a.value), acc),
                    {}
                  );
                  dispatch({
                    type: appConstant.ADD_TAX_INFO,
                    data: taxdt,
                  });
                  dispatch({ type: appConstant.ADD_CITY, data: res[3].data });

                  let result = res[4].data.reduce(function (map, obj) {
                    map[obj.code] = obj;
                    return map;
                  }, {});

                  dispatch({ type: appConstant.ADD_BRAND, data: result });
                  dispatch({ type: appConstant.HOST_API_LOADED });
                })
                .catch((e) => {
                  console.error("Menu API error ", e);
                  notifyError("Menu API error");
                });
            } else {
              dispatch({
                type: appConstant.SET_TOKEN,
                data: {
                  xtoken: c.x_Token,
                },
              });
              apiRequest
                .getHeaderAndMenuInfo(
                  init.productSettings.online_store_id,
                  init.settings.time_zone
                )
                .then((res) => {
                  dispatch({
                    type: appConstant.CUSTOMER_API_LOADED,
                    data: {
                      xtoken: c.x_Token,
                      customer: c.customer,
                      macId: c.macId,
                      productSettings: init.productSettings,
                      settings: init.settings,
                    },
                  });
                  dispatch({
                    type: appConstant.ADD_MENU_DROPDOWN,
                    data: res[0].data,
                  });
                  dispatch({
                    type: appConstant.ADD_LOCATION_INFO,
                    data: res[1].data,
                  });
                  //tax
                  let taxdt = res[2].data.reduce(
                    (acc, a) => ((acc[a.id] = a.value), acc),
                    {}
                  );
                  dispatch({
                    type: appConstant.ADD_TAX_INFO,
                    data: taxdt,
                  });
                  dispatch({ type: appConstant.ADD_CITY, data: res[3].data });
                  let result = res[4].data.reduce(function (map, obj) {
                    map[obj.code] = obj;
                    return map;
                  }, {});

                  dispatch({ type: appConstant.ADD_BRAND, data: result });
                  dispatch({ type: appConstant.HOST_API_LOADED });
                })
                .catch((e) => {
                  console.error("Menu API error ", e);
                  notifyError("Menu API error");
                });
            }
          })
          .catch((e) => {
            console.error("customer login API error ", e);
            notifyError("Customer login API error");
            dispatch({ type: appConstant.FAILED_HOST_API_DATA });
            dispatch({ type: appConstant.HOST_API_FAILED });
            dispatch({ type: appConstant.CUSTOMER_LOGOUT });
          })
          .finally((e) => {
            dispatch({ type: appConstant.HIDE_LOADER });
          });
      } else {
        apiRequest
          .getInitAPI()
          .then((res) => {
            if (res.data.errorMessage) {
              dispatch({ type: appConstant.FAILED_HOST_API_DATA });
              dispatch({ type: appConstant.HOST_API_FAILED });
              notifyError("API failed");
            } else {
              dispatch({ type: appConstant.SET_TOKEN, data: res.data });
              apiRequest
                .getHeaderAndMenuInfo(
                  res.data.productSettings.online_store_id,
                  res.data.settings.time_zone
                )
                .then((x) => {
                  dispatch({
                    type: appConstant.ADD_HOST_API_DATA,
                    data: res.data,
                  });
                  dispatch({
                    type: appConstant.ADD_MENU_DROPDOWN,
                    data: x[0].data,
                  });
                  dispatch({
                    type: appConstant.ADD_LOCATION_INFO,
                    data: x[1].data,
                  });
                  //tax
                  let taxdt = x[2].data.reduce(
                    (acc, a) => ((acc[a.id] = a.value), acc),
                    {}
                  );
                  dispatch({
                    type: appConstant.ADD_TAX_INFO,
                    data: taxdt,
                  });
                  dispatch({ type: appConstant.ADD_CITY, data: x[3].data });
                  let result = x[4].data.reduce(function (map, obj) {
                    map[obj.code] = obj;
                    return map;
                  }, {});

                  dispatch({ type: appConstant.ADD_BRAND, data: result });
                  dispatch({ type: appConstant.HOST_API_LOADED });
                })
                .catch((e) => {
                  console.error("Menu API error ", e);
                  //notifyError("Menu API error");
                });
            }
          })
          .catch((e) => {
            console.error("Token API error ", e);
            notifyError("Token API error failed");
            dispatch({ type: appConstant.HOST_API_FAILED });
          })
          .finally((e) => {
            dispatch({ type: appConstant.HIDE_LOADER });
          });
      }
    };
    fetchHostInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageLoader />
      {apiOnPageLoaded ? <Route /> : ""}
    </>
  );
}

export default App;
