import React,{useState} from "react";
import { useCartValue } from "../../provider/CartContext";
import { useMainStateValue } from "../../provider/AppProvider";
import { genrateCartPriceData,formatCurrency } from "../../lib/helpers/utils";

export default function CartSummary(i) {
  const { cartItems, itemCount, clearCart } = useCartValue();
  const [{ settings, customer, tax }, dispatch] = useMainStateValue();
  let cartPrices = genrateCartPriceData(cartItems, tax);

  

  

  return (
    <div className="col-12 summary-mobile mb-0">
      <div className="bg-white p-4">
        <p className="Summary-section-font">Summary</p>
        <p className="Summary-section-sub-font total-border">
          The total consist of the tax, insurance and the Shipping charge
        </p>
        <div className="row Summary-section-sub-font pt-2 pb-2 m-0 pl-0">
          <div className="col-6 pl-0">Subtotal</div>
          <div className="col-6 text-right pr-0">
            <span> {formatCurrency(cartPrices.subtotal,  settings.country_code)}</span>
          </div>
        </div>
        {/* <div className="row Summary-section-sub-font pt-2 pb-2 m-0 ">
          <div className="col-6 pl-0 ">Total Tax</div>
          <div className="col-6 text-right pr-0">
            <span> {formatCurrency(cartPrices.tax,  settings.country_code)}</span>
          </div>
        </div> */}
        <div className="row Summary-section-sub-font pt-2 pb-2  m-0">
          <div className="col-6 pl-0">Total Discount</div>
          <div className="col-6 text-right pr-0">
            <span>{formatCurrency(cartPrices.discountFees,  settings.country_code)}</span>
          </div>
        </div>
        <div className="row Summary-section-sub-font pt-2 pb-2 total-border m-0">
          <div className="col-6 pl-0">Delivery Fees</div>
          <div className="col-6 text-right pr-0">
            <span>{formatCurrency(parseInt(i.deliveryValueAmt?i.deliveryValueAmt:0),  settings.country_code)}</span>
          </div>
        </div>
        <div className="row Summary-section-sub-font pt-2 pb-2 total-border m-0">
          <div className="col-6 pl-0">Total</div>
          <div className="col-6 text-right pr-0">
            <span>{formatCurrency((cartPrices.total + parseInt(i.deliveryValueAmt?i.deliveryValueAmt:0) ),  settings.country_code) }</span>
          </div>
        </div>
      </div>
    </div>
  );
}

{
  /* <div className="col-4 Summary-section">
              <div className="col-12">
                <div className="bg-white p-4">
                  <p className="Summary-section-font">Summary</p>
                  <p className="Summary-section-sub-font total-border">
                    The total consist of the tax, insurance and the Shipping
                    charge
                  </p>
                  <div className="row Summary-section-sub-font pt-2 pb-2 m-0 pl-0">
                    <div className="col-6 pl-0">Subtotal</div>
                    <div className="col-6 text-right pr-0">
                       <span> 50.00</span>
                    </div>
                  </div>
                  <div className="row Summary-section-sub-font pt-2 pb-2 m-0 ">
                    <div className="col-6 pl-0 ">GST(18%) Fees</div>
                    <div className="col-6 text-right pr-0">
                       <span> 50.00</span>
                    </div>
                  </div>
                  <div className="row Summary-section-sub-font pt-2 pb-2  m-0">
                    <div className="col-6 pl-0">Discount Fees</div>
                    <div className="col-6 text-right pr-0">
                       <span> 50.00</span>
                    </div>
                  </div>
                  <div className="row Summary-section-sub-font pt-2 pb-2 total-border m-0">
                    <div className="col-6 pl-0">Delivery Fees</div>
                    <div className="col-6 text-right pr-0">
                      <span> 50.00</span>
                    </div>
                  </div>
                  <div className="row Summary-section-sub-font pt-2 pb-2 total-border m-0">
                    <div className="col-6 pl-0">Total</div>
                    <div className="col-6 text-right pr-0">
                       <span> 50.00</span>
                    </div>
                  </div>
                </div>
              </div>
            </div> */
}
