import React , { useState } from "react";
import { useMainStateValue } from "../../provider/AppProvider";
import * as appConstant from "../../provider/appConstant.type";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import apiRequest from "../../lib/api/services";

export default function SignInModal() {
  const [{ enablePopUpType }, dispatch] = useMainStateValue();
  const handleClose = () => dispatch({ type: appConstant.CLOSE_POPUP });
  const [terms, setTerms] = useState(false);
  function showlogin() {
    dispatch({ type: appConstant.SHOW_POPUP_LOGIN });
  } 
  function onChangeTerms(e){
    setTerms(e.target.checked);
  }
  function validate(values) {
    const errors = {};

    if (!values.firstname) {
      errors.firstname = "Required";
    }

    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    // var phoneno = /^\+(?:[0-9] ?){6,14}[0-9]$/;
    console.log(values.mobileno, "mblReg");
    if (!values.mobileno) {
      errors.mobileno = "Required";
    }else if(! values.mobileno.match(phoneno)){
      errors.mobileno = "Enter a valid Mobile number"
    }
    
    if (!values.email) {
      errors.email = "Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }

    
    return errors;
  }

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    errors,
  } = useFormik({
    initialValues: {
      firstname:"",
      lastname:"",
      mobileno:"",
      email: "",
    },
    validate,
    onSubmit: (values) => {
      if(!terms){
        return;
      }
      const data = {
        "email":  values.email,
        "first_name": values.firstname,
        "last_name": values.lastname,
        "mobile_number": values.mobileno,
      }
      dispatch({ type: appConstant.SHOW_LOADER });
      apiRequest
        .signinUser(data)
        .then((res) => {
          console.log(res);
          if (res.data.errorMessage) {
            // TODO SHow error

          } else {
            showlogin();
            // TODO Save the token and show user is login in header (ask praveen )
            // const dt = {
            //   customer: res.data.customer,
            //   xtoken: res.data.x_Token,
            //   macId: res.data.macId,
            // };
            // dispatch({ type: appConstant.CUSTOMER_LOGIN, data: dt });
          }
        })
        .catch((e) => {
          console.error("Login API error ", e);
        })
        .finally((e) => {
          console.log("----------> Closed");
          dispatch({ type: appConstant.HIDE_LOADER });
        });
    },
  });

  return (
      <Modal
        show={enablePopUpType === "SIGNIN"}
        onHide={handleClose}
        animation={false}
        backdrop="static"
        keyboard={false}
        dialogClassName="modalContent"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h3 className="text-center">Sign up to continue</h3>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <input placeholder="First Name"
                type="text"
                className="form-control"
                name="firstname"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {touched.firstname && errors.firstname ? (
                <small className="form-text  text-danger">
                  {errors.firstname}
                </small>
              ) : null}
              {/* <small className="form-text  text-danger">
                First Name is Required
              </small> */}
            </div>
            <div className="form-group">
              <input placeholder="Last Name"
                type="text"
                className="form-control"
                name="lastname"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {touched.lastname && errors.lastname ? (
                <small className="form-text  text-danger">
                  {errors.lastname}
                </small>
              ) : null}
            </div>
            <div className="form-group">
              <input placeholder="Mobile No"
                type="text"
                className="form-control"
                name="mobileno"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {touched.mobileno && errors.mobileno ? (
                <small className="form-text  text-danger">
                  {errors.mobileno}
                </small>
              ) : null}
              {/* <small className="form-text  text-danger">
                Mobile No is Required
              </small>
              <small className="form-text  text-danger">
                Enter Valid Mobile No
              </small> */}
            </div>
            <div className="form-group">
              <input placeholder="Email ID"
                type="email"
                className="form-control"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {touched.email && errors.email ? (
                <small className="form-text  text-danger">
                  {errors.email}
                </small>
              ) : null}
              {/* <small className="form-text  text-danger">
                Email is Required
              </small>
              <small className="form-text  text-danger">
                Enter Valid Email ID
              </small> */}
            </div>
            <label className="products-filter-left-nav-container">
              <input type="checkbox" 
                name="terms" 
                value={terms} onChange={onChangeTerms} />
              <a href="/page/Content-Page/1/1" target = "_blank" className="textPrimary cursor-pointer">
                By signing up you agree to our Terms & Conditions
              </a>
                <span className="checkmark" />
              </label>
            
            <button type="submit" className="btn btn-primary w-100">
              Sign up
            </button>
          </form>
          <div className="text-center  mt-3">
            <span onClick={handleClose} className="textPrimary cursor-pointer">
              Cancel
            </span>
          </div>
        </Modal.Body>
      </Modal>
  );
}
