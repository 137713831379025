import React from "react";
import ImageTag from "../ImageLoader/imageTag";
import { useCartValue } from "../../provider/CartContext";
import { useMainStateValue } from "../../provider/AppProvider";
import {
  formatCurrency,
  notifySuccess,
  notifyWarn,
  isItaNumber,
  getProductPriceWithoutDiscount,
  getProductPriceWithDiscount,
  checkStartEndDate,
} from "../../lib/helpers/utils";

export default function CartProduct(p) {
  const [{ settings, tax }, dispatch] = useMainStateValue();
  const {
    cartItems,
    itemCount,
    increase,
    decrease,
    removeProduct,
    clearCart,
  } = useCartValue();

  function minus() {
    let q = p.product.quantity - 1;
    if (q > 0) {
      decrease({ id: p.product.id, quantity: q });
    } else {
      removeProduct(p.product);
    }
  }
  function add() {
    let q = p.product.quantity + 1;
    let product = cartItems[cartItems.findIndex((item) => item.id === p.product.id)];
    let stockQuantity = product.stockQuantity;
    if ((product.allow_backorder === "Y" && q <= product.max_qty) || q <= stockQuantity) {
      increase({ id: p.product.id, quantity: q });
    } else {
      notifyWarn(
        "You've reached the maximum units allowed for the purchase of this item"
      );
    }
  }

  function remove() {
    removeProduct(p.product);
  }

  
    let productPriceWithDiscount = getProductPriceWithDiscount(
      p.product,
      tax
    );
    let priceDetails = formatCurrency(
      productPriceWithDiscount ? productPriceWithDiscount : 0,
      settings.country_code
    );

  function price() {
    return priceDetails;
  }

  return (
    <li>
      <div className="p-0 productImg">
        {/* name={p.product.image1 != null ? p.product.name : ""} */}
        <ImageTag
          imageid={p.product.image1 != null ? p.product.image1 : "NA"}
        />
      </div>
      <div className="cart-user-count-section-desc">
        <p className="m-0">{p.product.name}</p>
      </div>
      <div className="cart-user-count-button">
        <div className="add-sub-button-common">
          <div className="value-button decrement" onClick={minus}></div>
          <div className="cart-count-input">
            <span>{p.product.quantity}</span>
          </div>
          <div className="value-button Increment" onClick={add}></div>
        </div>
      </div>
      <div className="productPrice">
        {price()}
        <div className="removeCartItem" onClick={remove}></div>
      </div>
    </li>
  );
}
