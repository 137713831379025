import React, { useState } from "react";
import { useMainStateValue } from "../../provider/AppProvider";
import * as appConstant from "../../provider/appConstant.type";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import apiRequest from "../../lib/api/services";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

export default function LoginOTP() {
  const history = useHistory();
  const [{ enablePopUpType, enableCheckout }, dispatch] = useMainStateValue();
  const [msg, setMsg] = useState(null);
  const handleClose = () => {
    setMsg("");
    dispatch({ type: appConstant.CLOSE_POPUP });
  };
  const showSignup = () => dispatch({ type: appConstant.SHOW_POPUP_SIGNIN });
  const showForget = () =>
    dispatch({ type: appConstant.SHOW_POPUP_FORGETPASSWORD });

  // function validate(values) {
  //   const errors = {};
  //   var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  //   if (!values.umobileno) {
  //     errors.umobileno = "Required";
  //   } else if (!values.umobileno.match(phoneno)) {
  //     errors.umobileno = "Enter a valid Mobile number";
  //   }
  //   return errors;
  // }

  const { handleSubmit, handleChange, handleBlur, touched, errors } = useFormik(
    {
      initialValues: {
        umobileno: "",
        email: ""
      },
      validate: values => {
        const errors = {};
        var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    // var phoneno = /^\+(?:[0-9] ?){6,14}[0-9]$/;
      // Check for mobile number
        console.log("email:::",values.email);
        if (!values.email && !values.umobileno) {
          errors.umobileno = "Email/Mobile number is required";
        } else if (!values.email &&  !values.umobileno.match(phoneno)) {
          errors.umobileno = "Invalid mobile number";
        }else if (!values.umobileno && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
          errors.email = "Invalid email address";
        }
    
        return errors;
      },
      onSubmit: (values) => {
        let uuid = uuidv4();
        //console.log(JSON.stringify(values), uuid);
        dispatch({ type: appConstant.ADD_LOGIN_MOBILE_MACID,data: {macId: uuid,
          loginMobile: values.umobileno,} });
          dispatch({ type: appConstant.SET_OTP_EMAIL,data: {
            otpEmail: values.email,} });
        const data = {
          phoneNumber: values.umobileno,
          email: values.email,
          device: "online",
          macId: uuid,
          langCode: "us-en",
        };
        dispatch({ type: appConstant.SHOW_LOADER });
        apiRequest
          .sendOTP(data)
          .then((res) => {
            console.log(res, "Login API sntotp error res " );
            if (res.data.errorMessage) {
              // TODO SHow error
              dispatch({ type: appConstant.CUSTOMER_LOGOUT });
            } else {
              // TODO Open verfiy otp popup
              dispatch({ type: appConstant.SHOW_POPUP_OTP });
              //   const dt = {
              //     customer: res.data.customer,
              //     xtoken: res.data.x_Token,
              //     macId: res.data.macId,
              //   };
              //   dispatch({ type: appConstant.CUSTOMER_LOGIN, data: dt });
            }
          })
          .catch((e) => {
            console.error("Login API sntotp error ", e);
            setMsg("Mobile number doesn't match");
          })
          .finally((e) => {
            dispatch({ type: appConstant.HIDE_LOADER });
          });
        //   apiRequest
        //     .loginUser(data)
        //     .then((res) => {
        //       console.log(res);
        //       if (res.data.errorMessage) {
        //         // TODO SHow error
        //         dispatch({ type: appConstant.CUSTOMER_LOGOUT });
        //       } else {
        //         // TODO Save the token and show user is login in header (ask praveen )
        //         const dt = {
        //           customer: res.data.customer,
        //           xtoken: res.data.x_Token,
        //           macId: res.data.macId,
        //         };
        //         dispatch({ type: appConstant.CUSTOMER_LOGIN, data: dt });
        //         if(enableCheckout){
        //           history.push(`/member/checkout`)
        //         }
        //         handleClose();
        //       }
        //     })
        //     .catch((e) => {
        //       console.error("Login API error ", e);
        //       setMsg("Username and Password doesn't match");
        //     })
        //     .finally((e) => {
        //       console.log("----------> Closed");
        //       dispatch({ type: appConstant.HIDE_LOADER });
        //     });
      },
    }
  );

  return (
    <Modal
      show={enablePopUpType === "LOGIN"}
      onHide={handleClose}
      animation={false}
      backdrop="static"
      keyboard={false}
      dialogClassName="modalContent"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <h3 className="text-center">Welcome Back</h3>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">Mobile</label>
            <input
              type="nummber"
              name="umobileno"
              onChange={handleChange}
              onBlur={handleBlur}
              className="form-control"
              aria-describedby="Mobile number"
              placeholder="Mobile Number"
            />
            {touched.umobileno && errors.umobileno ? (
              <small className="form-text  text-danger">
                {errors.umobileno}
              </small>
            ) : null}
          </div>
          <div className="form-group">
            OR
          </div>
          <div className="form-group">
        <label htmlFor="email">Email:</label>
        <input
          type="email"
          name="email"
          onChange={handleChange}
          onBlur={handleBlur}
          // value={values.uemail}
          className="form-control"
          placeholder="Email"
        />
        {touched.email && errors.email && (
          <div className="text-danger">{errors.email}</div>
        )}
      </div>
          {msg ? <small className="form-text  text-danger">{msg}</small> : null}
          <button type="submit" className="btn btn-primary w-100">
            Send OTP
          </button>
        </form>
        <div className="d-flex justify-content-space-between mt-3">
          <span onClick={showSignup} className="textPrimary cursor-pointer">
            Sign Up Here
          </span>
        </div>
      </Modal.Body>
    </Modal>
  );
}
