import React from 'react';
import apiRequest from "../lib/api/services";

export default async function payXendit( data , callback ){
    
    window.open(data.redirectURL);
    let url = data.redirectURL;
    let id = url.substring(url.lastIndexOf("/")+1,url.length)
    let payres;
    let paymentSub = await setInterval( async () => {
        payres = await apiRequest.xenditStatus(id);
        if(payres.data.status!="PENDING"){
            clearInterval(paymentSub);
            callback(payres.data.status);
        }
    }, 3000);
    
}