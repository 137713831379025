import axios from "./http-common";
import requestsURL from "./requestURI";
import { TokenService } from "./storage.service";

const setHeader = () => {
  //axios.defaults.headers.common["Authorization"] = `Bearer ${TokenService.getToken()}`;
  axios.defaults.headers.common["X-Token"] = `${TokenService.getToken()}`;
};

const removeHeader = () => {
  axios.defaults.headers.common = {};
};

const getInitAPI = () => {
  //removeHeader();
  //requestsURL.guestLogin
  //return axios.post(requestsURL.guestLogin,{"password": "guest","username": "guest"});
  return axios.post("/service/login", {
    password: "guest",
    username: "guest",
  });
};

const getMenuDetails = (locationId) => {
  setHeader();
  let locationStr = "";
  if(locationId){
    locationStr  = "&locationId="+locationId;
  }
  return axios.get(requestsURL.catagories + locationStr);
};
const getCatagoriesCount = (locationId) => {
  setHeader();
  let locationStr = "";
  if(locationId){
    locationStr  = "?locationId="+locationId;
  }
  return axios.get(requestsURL.catagoriesCount + locationStr);
};
const getBanner = () => {
  setHeader();
  return axios.get(requestsURL.banner);
};
const getBrand = () => {
  setHeader();
  return axios.get(requestsURL.brand);
};

const postFeatureProduct = () => {
  setHeader();
  return axios.post(requestsURL.searchProduct, { featured: "Y" });
};

const getAllProduct = () => {
  setHeader();
  return axios.post(requestsURL.searchProduct);
};

const getLocationDetails = (id) => {
  setHeader();
  return axios.get(requestsURL.locationInfo + id);
}

const getTax = () => {
  setHeader();
  return axios.get(requestsURL.tax);
}

const getDateTimeByTimeZone = (timeZone) => {
  return axios.get("http://worldtimeapi.org/api/timezone/"+timeZone);
}

const getCity = () => {
  setHeader();
  return axios.get('/service/city');
}

const getHeaderAndMenuInfo = (id,timeZone) => {
  setHeader();
  return Promise.all([
    getMenuDetails(id),
    getLocationDetails(id),
    getTax(),
    getCity(),
    getBrand()
    //getDateTimeByTimeZone(timeZone)
  ]);
}

const getDashboardData = () => {
  setHeader();
  return Promise.all([
    getBanner(),
    //getBrand(),
    postFeatureProduct(),
  ]);
};

//getCatagoriesCount(),

const loaderImage = (uuid) => {
  setHeader();
  return axios.get(requestsURL.imageUrl + uuid);
};

const loginUser = (data) => {
  setHeader();
  // requestsURL.customerLogin
  return axios.post("/service/customer/login", data);
};

const signinUser = (data) => {
  setHeader();
  return axios.post(requestsURL.customerSignup, data);
};

const sendOTP = (data) => {
  setHeader();
  return axios.post(requestsURL.sendOtp, data);
};

const verifyOTP = (data) => {
  setHeader();
  return axios.post(requestsURL.verifyOtp, data);
};

const loginByMacID = (macid) => {
  return axios.get(requestsURL.customerByMacId + macid);
};

const onCustomerLogin = (macid) => {
  removeHeader();
  return Promise.all([loginByMacID(macid), getInitAPI()]);
};

const onLogout = () => {
  setHeader();
  return axios.post(requestsURL.logout);
};

const getproductDetails  = (data, qparam) => {
    setHeader(); 
    return axios.post(requestsURL.productFullDetail, data ,{ params: qparam }); 
}

const getProductSearchbox  = (data, qparam) => {
  setHeader(); 
  return axios.post(requestsURL.productSearchbox, data ,{ params: qparam }); 
}



const getproductViewDetail  = (data, locationId) => {
    setHeader();
    return axios.post(requestsURL.productViewDetail+'?locationId='+locationId, data); 
}


const getBrandbycatagory = (locationId,catagoryCodes) => {
  setHeader();
  return axios.get(requestsURL.brandByCatagory+"?locationId="+locationId+"&catagoryCodes="+catagoryCodes);
}

const getProductbyBrand = (locationId, brandCodes) => {
  setHeader();
  return axios.get(requestsURL.brandByCatagory+"?locationId="+locationId+"&brand_code="+brandCodes);
}
const getPricebycatagory = () => {
  setHeader();
  return axios.get(requestsURL.priceByCatagory);
}

const getProductListPageData = (locationId, catagoryCodes) => {
  setHeader();
  //
  return Promise.all([getBrandbycatagory(locationId,catagoryCodes) , getPricebycatagory()]);
  //return axios.post(requestsURL.productFullDetail+'?locationId='+locationId, data); 
}

const getAddressByCustomerID = (id) => {
  setHeader();
  return axios.get(requestsURL.addressByCustomer + id);
}

const deleteAddressByCustomerID = (id) => {
  setHeader();
  return axios.delete(requestsURL.saveAddressByCustomer +"/"+ id);
}

const saveAddressByCustomerId = (data) => {
  setHeader();
  return axios.post(requestsURL.saveAddressByCustomer, data);
}

const editAddressByCustomerId = (id,data) => {
  setHeader();
  return axios.put(requestsURL.saveAddressByCustomer+"/"+id, data);
}

const locationinvoicedetailsPay = (data) => {
  setHeader();
  return axios.post(requestsURL.locationinvoicedetailsPay , data)
}
const locationinvoicedetails = (data) => {
  setHeader();
  return axios.post(requestsURL.locationinvoicedetails , data)
}

const getCustomerByID = (id) => {
  setHeader();
  return axios.get(requestsURL.customerByID + id)
}

const putCustomerByID = (id , data) => {
  setHeader();
  return axios.put(requestsURL.customerByID + id , data)
}

const getOrderBycustomerId = (data) => {
  setHeader();
  return axios.post(requestsURL.orderByid,data);
}

const getOrderById  = (id) => {
  setHeader();
  return axios.get(requestsURL.orderByorderid + id);
}

const deleteOrderById = (id, data) => {
  setHeader();
  return axios.put(requestsURL.orderByorderid + id +"/cancel" , data);
}


const getPageDT = (id) => {
  setHeader();
  return axios.get(requestsURL.aboutpage + id);
}

const getFullPageDT = () => {
  setHeader();
  return axios.get(requestsURL.cmsPage);
}

const getDelyOption = (data) => {
  setHeader();
  return axios.post(requestsURL.deliverycharges, data);
}


const getNewPaymentDetails = (amount, locationId) => {
  setHeader();
  return axios.get(requestsURL.newPayament+'?locationId='+locationId+'&amount='+amount);
}

const getPaymentGateWay = (data) => {
  setHeader();
  return axios.post(requestsURL.newPayamentGatwayId, data);
}

const getPaymentGateWayKey = (data) => {
  setHeader();
  return axios.post(requestsURL.paymentGatewayKey, data);
}
const xenditStatus = (data) => {
  setHeader();
  return axios.get(requestsURL.xenditStatus + data)
}

const getPaymentRefund = (ord,loc,cust) =>{
  setHeader(); 
  return axios.get(`/service/payment?invoiceId=${ord}&customerId=${cust}&locationId=${loc}`);  
}
export default {
  getInitAPI,
  setHeader,
  removeHeader,
  getMenuDetails,
  getCatagoriesCount,
  getBanner,
  getBrand,
  getDashboardData,
  loaderImage,
  loginUser,
  signinUser,
  sendOTP,
  verifyOTP,
  onCustomerLogin,
  onLogout,
  getproductDetails,
  getProductSearchbox,
  getHeaderAndMenuInfo,
  getProductListPageData,
  getproductViewDetail,
  getAddressByCustomerID,
  saveAddressByCustomerId,
  editAddressByCustomerId,
  locationinvoicedetailsPay,
  locationinvoicedetails,
  getPricebycatagory,
  deleteAddressByCustomerID,
  getCustomerByID,
  putCustomerByID,
  getOrderBycustomerId,
  getOrderById,
  deleteOrderById,
  getPageDT,
  getDelyOption,
  getFullPageDT,
  getAllProduct,
  getNewPaymentDetails,
  getPaymentGateWay,
  getPaymentGateWayKey,
  xenditStatus,
  getPaymentRefund
};
