import React, { useEffect, useState } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { AutoComplete } from "./AutoComplete";
import  { Redirect } from 'react-router-dom'
export default function SearchBar() {
  const history = useHistory();
  const [serachText, setSerachText] = useState("");
  
  function onKeyUp(event) {  
    if (event.key === "Enter") {
      callsearch(event.target.value);
    }else{

    }
  }

  function callsearch(s){
    console.log('serach text', s)
    history.push('/search/?q='+s);
    setSerachText("")
  }

  function handleSearchInputChange(event){
    const clearValue = event.target.value.replace(/[^a-zA-Z0-9\s]/g,'')
    setSerachText(clearValue)
  }

  function onsearch(e){
    e.preventDefault();
    callsearch(serachText)
  }


  return (
    <div className="input-group header-global-search">
      {/* <input
        type="text"
        className="form-control"
        placeholder=""
        value={serachText}
        onChange={handleSearchInputChange}
        onKeyPress={onKeyUp}
        aria-label="Recipient's username"
        aria-describedby="basic-addon2"
      />
      <div className="input-group-append" onClick={onsearch}>
        <span className="input-group-text">
          <img src={"/images/svg/search-white.svg"} />
        </span>
      </div> */}

     <AutoComplete />
    </div>
  );
}
