import React, { useState, useEffect } from "react";
import apiRequest from "../../lib/api/services";
import * as appConstant from "../../provider/appConstant.type"; 
import { useMainStateValue } from "../../provider/AppProvider";
import { Link } from "react-router-dom";

export default function FooterSection() {
  const [{ customer }, dispatch] = useMainStateValue();
  const [cmsPageList, setcmsPageList] = useState([]);
  useEffect(() => { 
    const fetchInfo = () => { 
      dispatch({ type: appConstant.SHOW_LOADER });
      apiRequest 
      .getFullPageDT()
      .then((res) => {  
        setcmsPageList(res.data); 
      }).catch((e) => { 

      }).finally((e) => {
          dispatch({ type: appConstant.HIDE_LOADER });
      });
    }



    fetchInfo();
  }, []);
 
  function renderPageLink () { 
    if(cmsPageList.length > 0) { 
      const listItems = cmsPageList.map((page) =>
          <li key={page.id}> <Link to={'/page/Content-Page/'+page.code+'/'+page.id}> {page.name}</Link></li> // {page.code}
      );
      return (listItems); 
    } else {
      return "";
    } 
  }


  /* <li className="py-2"> About </li>
      <li><a title="About Us" href="/page/About-Us">About Us</a></li>
      <li><a title="Contact Us" href="/page/ContactUS">Contact US</a></li>
      <li><a title="privacy-policy" href="/page/PrivacyPolicy">Privacy Policy</a></li> 
   */ 



  return (
    <footer className="text-center">
      {}
      <div className="footer-address-section">
       <div className="row m-0">
         <div className="col-lg-4 col-md-4 col-12 home-link"> 
          <ul>
            <li className="py-2"> Links </li>
            <li><a title="Home" href="/">Home</a></li>
            <li><a title="Product-Category" href="/product/MK/">Products</a></li>
          </ul>
         </div>
         <div className="col-lg-4 col-md-4 col-12 address-link">
         <ul className={cmsPageList.length == 0 ? 'd-none' : ''}>
            {renderPageLink()} 
          </ul>
          </div>
         <div className="col-lg-4 col-md-4 col-12 social-link">
         <ul>
            <li className="py-2"> Social </li>
            <li> <img src="/images/svg/facebook.svg"/> 
            {/* <a href="https://www.facebook.com/grocerboxindia" target="_blank">Facebook</a> */}
            Facebook
            </li>
            <li><img src="/images/svg/instagram-sketched.svg"/> 
            {/* <a href="https://www.instagram.com/grocerbox.in/" target="_blank" >Instagram</a> */}
            Instagram
            </li> 
            <li><img src="/images/svg/whatsapp.svg"/> 
            {/* <a href="https://api.whatsapp.com/send?phone=919360624801" target="_blank" >Whatsapp</a> */}
            Whatsapp
            </li>
          </ul>
          </div>
       </div>
      </div>
      <p className="font-weight-bold m-0 footer-copyright">Copyright &#169; 2020-2021 Grocerbox</p>
    </footer>
  );
}

