import React, { useState } from "react";
import { useMainStateValue } from "../../provider/AppProvider";
import * as appConstant from "../../provider/appConstant.type";
import { Link } from "react-router-dom";

export default function MenuCard() {
  const [{ showMenu, menuData, customer }, dispatch] = useMainStateValue();
  const [menuDt, setMenuDt] = useState({});
  function openMenu() {
    showMenu
      ? dispatch({ type: appConstant.CLOSE_MENU })
      : dispatch({ type: appConstant.OPEN_MENU });
  }
  function showlogin() {
    dispatch({ type: appConstant.SHOW_POPUP_LOGIN });
  }
  function hideMenu() {
    dispatch({ type: appConstant.CLOSE_MENU });
  }
  const showSignup = () => dispatch({ type: appConstant.SHOW_POPUP_SIGNIN });

  function MainCategoryRender() {
    return menuData?.catagories.map((catagorey) => (
      <li key={catagorey.code} className={menuDt[catagorey.code] ? "active" : ""}>
        <div className="linkTxt">
          <Link onClick={hideMenu} key={catagorey.code} to={"/product/" + catagorey.code}>{catagorey.name}</Link>
          {AddArrow(catagorey.code)}
        </div>
        {subCatagoreyRender(catagorey.code)}
      </li>
    ));
  }

  function AddArrow(catagoreyCode) {
    if (menuData?.subcatagories.hasOwnProperty(catagoreyCode)) {
      return (
        <span
          className="right-arrow-green"
          onClick={() => {
            const val = menuDt[catagoreyCode] ? false : true;
            setMenuDt({ [catagoreyCode]: val });
          }}
        ></span>
      );
    } else {
      return "";
    }
  }

  function subCatagoreyRender(catagoreyCode) {
    if (menuData?.subcatagories.hasOwnProperty(catagoreyCode)) {
      const subcatagorey = menuData?.subcatagories[catagoreyCode].map(
        (subcatagorey) => (
          <li key={subcatagorey.code}>
            <Link onClick={hideMenu} to={"/product/" + catagoreyCode + "/" + subcatagorey.code}>
              {subcatagorey.name}
            </Link>
            {/* <span className="right-arrow-green"></span>  */}
          </li>
        )
      );
      return <ul>{subcatagorey}</ul>;
    } else {
      return "";
    }
  }

  function logout() {
    dispatch({ type: appConstant.SHOW_POPUP_LOGOUT });
  }

  function iflogin() {
    if (customer) {
      return (
        <ul>
          <li>
            <Link onClick={hideMenu} to="/member/account">Your Account</Link>
          </li>
          {/* <li>
            <Link onClick={hideMenu} to="/member/account">Your Orders</Link>
          </li> */}
          <li>
            <span onClick={logout} className="cursor-pointer">Logout</span>
          </li>
        </ul>
      );
    } else {
      return (
        <ul>
          <li>
            <span onClick={showlogin}  className="cursor-pointer">Login</span>
          </li>
          <li>
            <span onClick={showSignup}  className="cursor-pointer">Sign up to continue</span>
          </li>
        </ul>
      );
    }
  }

  return (
    <div
      id="mySidenav"
      className={"sidenav sidenavLeft " + (showMenu ? "open" : "")}
    >
      <div className="navHeader">
        <div className="userIcon"></div>
        <span className="flex-grow-1">
          {customer ? "Hello " + customer.first_name : "Hello Guest"}
        </span>
        <div onClick={openMenu} className="sidenavClose closeIcon"></div>
      </div>
      <div id="FirstLevelMenu" className="sideMenuContainer ">
        <p className="menuTitle text-uppercase">SHOP BY CATEGORY</p>
        <ul>
          {MainCategoryRender()} 
        </ul>
        <p className="menuTitle text-uppercase">Account</p>
        {iflogin()}
      </div>
      {/* <div id="SecoundLevelMenu" className="sideMenuContainer d-none">
        <div className="back">
          <span className="right-arrow-green"></span> Back
        </div>
        <p className="menuTitle text-uppercase">Fruits &amp; Vegetables</p>
        <ul>
          <li>
            <a href="#">Fresh Vegetables</a>
            <span className="right-arrow-green"></span>
          </li>
          <li>
            <a href="#">Fresh Fruits</a>
            <span className="right-arrow-green"></span>
          </li>
          <li>
            <a href="#">Herbs &amp; Seasonings</a>
            <span className="right-arrow-green"></span>
          </li>
          <li>
            <a href="#">Exotic Fruits &amp; Veggies</a>
            <span className="right-arrow-green"></span>
          </li>
          <li>
            <a href="#">Organic Fruits &amp; Vegetables</a>
            <span className="right-arrow-green"></span>
          </li>
          <li>
            <a href="#">Cuts &amp; Sprouts</a>
            <span className="right-arrow-green"></span>
          </li>
          <li>
            <a href="#">Flower Bouquets, Bunches</a>
            <span className="right-arrow-green"></span>
          </li>
        </ul>
      </div>
      <div id="ThridLevelMenu" className="sideMenuContainer d-none">
        <div className="back">
          <span className="right-arrow-green"></span> Back
        </div>
        <p className="menuTitle text-uppercase">Fruits &amp; Vegetables</p>
        <ul>
          <li>
            <a href="#">Potato, Onion &amp; Tomato</a>
          </li>
          <li>
            <a href="#">Root Vegetables</a>
          </li>
          <li>
            <a href="#">Cucumber &amp; Capsicum</a>
          </li>
          <li>
            <a href="#">Cabbage &amp; Cauliflower</a>
          </li>
          <li>
            <a href="#">Beans, Brinjals &amp; Okra</a>
          </li>
          <li>
            <a href="#">Gourd, Pumpkin, Drumstick</a>
          </li>
        </ul>
      </div> */}
    </div>
  );
}
