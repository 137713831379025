import React,{useEffect, useState} from "react";
import { useMainStateValue } from "../../provider/AppProvider";
import * as appConstant from "../../provider/appConstant.type";
import { Link } from "react-router-dom";

export default function MenuDropdown() {
    const [{ menuData },dispatch] = useMainStateValue(); 
    function catagoreyRender(){
        return (menuData?.catagories.map((catagorey) => (
            <li key={catagorey.code}> 
                <Link to={"/product/" + catagorey.code}>{catagorey.name}</Link>
                {AddArrow(catagorey.code)}
                {subCatagoreyRender(catagorey.code)}
            </li>
        )))
    }

    function AddArrow (catagoreyCode) {
        if(menuData?.subcatagories.hasOwnProperty(catagoreyCode)) {
            return (
                <span className="right-arrow-green"></span>
            );
        } else {
            return "";
        }
    }

    function subCatagoreyRender(catagoreyCode) {
         if(menuData?.subcatagories.hasOwnProperty(catagoreyCode)) {
            const subcatagorey =  menuData?.subcatagories[catagoreyCode].map((subcatagorey) => ( 
                <li key={subcatagorey.code}> 
                     <Link to={"/product/" + catagoreyCode + "/" + subcatagorey.code}>{subcatagorey.name}</Link>
                     {/* <span className="right-arrow-green"></span>  */}
                </li>
            ));
            return ( 
                <ul>
                    {subcatagorey}
                </ul>
            ); 
         } else { 
             return ""; 
         }
    }

    return (
        <div className="master-category-container">
        <ul className="mainMenu"> 
            <li> 
               <div className="content" >
                <span className="text-uppercase">shop by category </span> 
                <span className="right-arrow-white"></span>
               </div>


               <div id="MenusubCategory" className="sub-category-container"> 
                    <div id="FirstLevelMenu" className="sideMenuContainer"> 
                        <ul>
                            {catagoreyRender()}
                        </ul>
                    </div> 
                </div>


            </li> 
        </ul>
        {/* <div id="MenusubCategory" className={'sub-category-container '+(isShown  ? 'active' : '') }>  */} 
        
    </div>
    )
}
