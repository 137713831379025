import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { mainInitialState } from "./provider/appReducer";
import mainReducer from "./provider/appReducer";
import { AppProvider } from "./provider/AppProvider";
import CartContextProvider from "./provider/CartContext";

ReactDOM.render(
  <React.StrictMode>
    <AppProvider initialState={mainInitialState} reducer={mainReducer}>
      <CartContextProvider>
        <App />
      </CartContextProvider>
    </AppProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.unregister();
