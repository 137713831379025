import React, { useState } from "react";
import requestURL from "../../lib/api/requestURI";
import { useMainStateValue } from "../../provider/AppProvider";
import { imageSrcType } from "../../lib/helpers/utils";
import ReactImageMagnify from "react-image-magnify";
export default function ImageTag(i) {
  //const [{imageUrl}] = useMainStateValue();
  //const [imageSrc, setImageSrc] = useState(window._env_.API_URL+ requestURL.imageUrl + i.imageid);
  // console.log(">>>>>>>>>> ",i.imageid)
  let imagesrc = !(i.imageid && i.imageid.indexOf("s3.amazonaws.com") > -1)? window._env_.API_URL + requestURL.imageUrl + i.imageid : i.imageid;
  // console.log("imagesrc   ----> ", imagesrc);
  
  const imageProps = {
    smallImage: {
      alt: i.name,
      isFluidWidth: true,
      src: imagesrc,
    },
    largeImage: {
      src: imagesrc,
      width: 400,
      height: 500,
    },
    enlargedImageContainerStyle: { background: "#fff", zIndex: 9 },
    enlargedImageContainerDimensions: {
      width: "200%",
      height: "100%",
    },
    isHintEnabled: true,
    shouldHideHintAfterFirstActivation: false,
  };

  if (i.enableZoom) {
    if (i.imageid === "NA") {
      let imagesrc = "/images/jpg/brokenImage/product-broken-image.png";
      switch (i.imagetype) {
        case imageSrcType.BANNERIMAGE:
          //setImageSrc('/images/jpg/brokenImage/banner-broken-image1.png');
          imagesrc = "/images/jpg/brokenImage/banner-broken-image1.png";

          break;
        case imageSrcType.PRODCARDIMAGE:
          //setImageSrc('/images/jpg/brokenImage/product-broken-image.png');
          imagesrc = "/images/jpg/brokenImage/product-broken-image.png";

          break;
        case imageSrcType.BRANDIMAGE:
          //setImageSrc('/images/jpg/brokenImage/brand-broken-image.png');
          imagesrc = "/images/jpg/brokenImage/brand-broken-image.png";

          break;
      }
      return <img src={imagesrc} alt="No Image Found" />;
    } else {
      return <ReactImageMagnify {...imageProps} />;
    }
  } else {
    if (i.imageid === "NA") {
      return (
        <img
          src={"/images/jpg/brokenImage/product-broken-image.png"}
          alt="No Image Found"
        />
      );
    } else {
      return (
        <img
          onError={(event) => setBrokenImage(i.imagetype)}
          src={imagesrc}
          alt={i.name}
        />
      );
    }
  }

  function setBrokenImage(imagetype) {
    switch (imagetype) {
      case imageSrcType.BANNERIMAGE:
        //setImageSrc('/images/jpg/brokenImage/banner-broken-image1.png');
        imagesrc = "/images/jpg/brokenImage/banner-broken-image1.png";

        break;
      case imageSrcType.PRODCARDIMAGE:
        //setImageSrc('/images/jpg/brokenImage/product-broken-image.png');
        imagesrc = "/images/jpg/brokenImage/product-broken-image.png";

        break;
      case imageSrcType.BRANDIMAGE:
        //setImageSrc('/images/jpg/brokenImage/brand-broken-image.png');
        imagesrc = "/images/jpg/brokenImage/brand-broken-image.png";

        break;
    }
  }
}
