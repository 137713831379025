import { useEffect, useCallback, useRef } from "react";
import apiRequest from "../lib/api/services";

// make API calls and pass the returned data via dispatch
export const useProductFetch = (data,dispatch) => {
  useEffect(() => {
    dispatch({ type: "FETCHING_DATA", fetching: true });
    data.queryParam['pageNumber'] = data.pageNumber;
    apiRequest
      .getproductDetails(data.data, data.queryParam )
      .then((p) => {
        dispatch({ type: "STACK_DATA", products :  p.data.products , totalRecords: p.data.totalRecords });
        dispatch({ type: "FETCHING_DATA", fetching: false });
      })
      .catch((e) => {
        // handle error
        dispatch({ type: "FETCHING_DATA", fetching: false });
        return e;
      });
  }, [dispatch, data.pageNumber]);
};
