import axios from "axios";
import { stringify } from "uuid";
import { useMainStateValue } from "../../provider/AppProvider";
import * as appConstant from "../../provider/appConstant.type";


const instance  = axios.create({
    baseURL: window._env_.API_URL || process.env.REACT_APP_HOST_API || '/',
    headers: {"Content-type": "application/json"},
    validateStatus: function(status){
      return true;
    }
});
instance.interceptors.response.use(function (response) {
  
  if(response.status == 500){
    if(response.data.message === "Invalid Session"){
      if(response.config.url.indexOf("customer/info") > -1){
        const [dispatch] = useMainStateValue();
        dispatch({ type: appConstant.CUSTOMER_LOGOUT });
      } else {
        window.location.reload();
      }
      
    }
    return Promise.reject(response.data);
  }
  return response;
}, function (error) {
  console.log(error)
  return Promise.reject(error);
});
export default instance;