import React from "react";
import { useMainStateValue } from "../../provider/AppProvider";
import * as appConstant from "../../provider/appConstant.type";
import { Modal } from "react-bootstrap";
import apiRequest from "../../lib/api/services";
import { useHistory } from "react-router-dom";

export default function Logout() {
  const [{ enablePopUpType }, dispatch] = useMainStateValue();
  const handleClose = () => dispatch({ type: appConstant.CLOSE_POPUP });
  const history = useHistory();
  const handleLogout = () => {
    apiRequest
      .onLogout()
      .then((res) => {
        console.log("--------------------", res);
        if (res.status === 200) {
          handleClose();
          dispatch({ type: appConstant.CUSTOMER_LOGOUT });
          dispatch({ type: appConstant.SHOW_LOADER });

          apiRequest
            .getInitAPI()
            .then((res) => {
              if (res.data.errorMessage) {
                dispatch({ type: appConstant.FAILED_HOST_API_DATA });
                dispatch({ type: appConstant.HOST_API_FAILED });
              } else {
                dispatch({ type: appConstant.SET_TOKEN, data: res.data });
                apiRequest
                  .getHeaderAndMenuInfo(
                    res.data.productSettings.online_store_id,
                    res.data.settings.time_zone
                  )
                  .then((x) => {
                    dispatch({
                      type: appConstant.ADD_HOST_API_DATA,
                      data: res.data,
                    });
                    dispatch({
                      type: appConstant.ADD_MENU_DROPDOWN,
                      data: x[0].data,
                    });
                    dispatch({
                      type: appConstant.ADD_LOCATION_INFO,
                      data: x[1].data,
                    });
                    //tax
                    let taxdt = x[2].data.reduce(
                      (acc, a) => ((acc[a.id] = a.value), acc),
                      {}
                    );
                    dispatch({
                      type: appConstant.ADD_TAX_INFO,
                      data: taxdt,
                    });
                    dispatch({type : appConstant.ADD_CITY, data:res[3].data })
                    let result = res[4].data.reduce(function (map, obj) {
                      map[obj.code] = obj;
                      return map;
                    }, {});
  
                    dispatch({ type: appConstant.ADD_BRAND, data: result });
                    dispatch({ type: appConstant.HOST_API_LOADED });
                  })
                  .catch((e) => {
                    console.error("Menu API error ", e);
                  })
                  .finally((e) => {
                    history.push(`/`);
                    dispatch({ type: appConstant.HIDE_LOADER });
                  });
              }
            })
            .catch((e) => {
              console.error("Token API error ", e);
              dispatch({ type: appConstant.HOST_API_FAILED });
            });
        }
      })
      .catch((e) => {
        console.error("Logout API error ", e);
      })
      .finally((e) => {
        dispatch({ type: appConstant.HIDE_LOADER });
      });
  };

  return (
    <Modal
      show={enablePopUpType === "LOGOUT"}
      onHide={handleClose}
      animation={false}
      backdrop="static"
      keyboard={false}
      dialogClassName="modalContent"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="ForgetPasswordContainer">
          <h4 className="text-center">Logout</h4>

          <small className="form-text mb-3 ">Do you want to logout?</small>

          <button
            type="button"
            onClick={handleLogout}
            className="btn btn-primary w-100"
          >
            Yes
          </button>

          <div className="text-center  mt-3">
            <span onClick={handleClose} className="textPrimary cursor-pointer">
              Cancel
            </span>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
