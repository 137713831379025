import React, { useState } from "react";
import apiRequest from "../../lib/api/services";
import { useMainStateValue } from "../../provider/AppProvider";
import { useCartValue } from "../../provider/CartContext";
import * as appConstant from "../../provider/appConstant.type";
import { useHistory } from "react-router-dom";
import xendit from '../../helpers/xendit'
import { Modal } from "react-bootstrap";
import {
  genrateCartPriceData,
  notifySuccess,
  notifyWarn,
  notifyError,
  getProductPriceWithoutDiscount,
  getProductPriceWithDiscount,
} from "../../lib/helpers/utils";

export default function Payment(i) {
  const skipCod = window._env_.SKIP_COD;
  const history = useHistory();
  const [
    { settings, customer, locationInfo, tax },
    dispatch,
  ] = useMainStateValue();
  const { cartItems, itemCount, clearCart } = useCartValue();
  const [isCOD, setIsCOD] = useState(false);
  const [enablePopUp, setEnablePopUp] = useState("");
  const [invoice_Id, setInvoiceId] = useState(0);
  const [paymentToken, setPaymentToken] = useState("");
  const [paymentKey, setPaymentKey] = useState("");
  const [deliveryCharges, setDeliveryCharges] = useState(0);
  const [placeOrder, setPlaceOrder] = useState(false);
  const [skipCodShow, setSkipCodShow] = useState(skipCod);
  // const [deliverAdd, setDeliverAdd] = useState("");
 
  console.log(skipCod, "skipCod");

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }
  const __DEV__ = document.domain === "localhost";
  //const [name, setName] = useState("Mehul");

  async function displayRazorpay(totalamt) {
    
    console.log('paymentKey');
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    // const data = await fetch("http://localhost:1337/razorpay", {
    //   method: "POST",
    // }).then((t) => t.json());

    console.log(locationInfo)
    totalamt = totalamt + deliveryCharges;
    getNewPaymentDetails(totalamt, locationInfo.id)
  }
  function afterPaidByRazorpay(
    razorpay_payment_id,
    razorpay_order_id,
    razorpay_signature
  ) {
    dispatch({ type: appConstant.SHOW_LOADER });
    let dt = onCOD("N");
    dt["paymentToken"] = razorpay_signature;
    dt["transactionId"] = razorpay_payment_id; 
    console.log(invoice_Id);
    debugger;
    // dt['newInvoice'] = razorpay_order_id;
    console.log("dt ---> after Razorpay ", dt);
    apiRequest
      .locationinvoicedetailsPay(dt)
      .then((res) => {
        console.log("res", res);
        if (res.data.errorMessage) {
          console.error(
            "Error msg locationinvoicedetailsPay",
            res.data.errorMessage
          );
        } else {
          //displayRazorpay();
          // TODO
          // Clear cart empty it in both storge ---
          dispatch({ type: appConstant.ADD_PAY_INFO, data:res.data });
          clearCart();
          history.push("/member/orderconfirmation");
          localStorage.removeItem('GrocerboxAPP.invoiceId');
        }
      })
      .catch((e) => {
        console.error("locationinvoicedetailsPay API error ", e);
      })
      .finally((e) => {
        dispatch({ type: appConstant.HIDE_LOADER });
      });
  }
  async function gotoPament(amt,obj){
    let user_data = onCOD('N');
    dispatch({ type: appConstant.SHOW_LOADER });
    let res = await apiRequest.getNewPaymentDetails(amt,user_data.detail.location_id);
        const pay_token = "res.data.paymentToken";
        user_data.newInvoice = res.data.invoiceId;
        res = await apiRequest.locationinvoicedetails(user_data);
        // await fetchRequest('locationinvoicedetails', user_data); 
        let data = res.data;
        xendit(data, async (status) => {
            if(status=="PAID" || status=="SETTLED"){
                dispatch({ type: appConstant.HIDE_LOADER });
                // localStorage.removeItem(storage_items.cart);
                data.detail.paidByTransfer = data.detail.totalamount; 
                data.newInvoice = res.data.invoiceId;
                res = await apiRequest.locationinvoicedetailsPay(data);
                // await fetchRequest('pay', data);
                // cb(true);
                // setState({ ...state, cart: [] })
                notifySuccess(
                  "ORDER PLACED!"
                );
                dispatch({ type: appConstant.ADD_PAY_INFO, data:res.data });
                clearCart();
                history.push("/member/orderconfirmation");
                localStorage.removeItem('GrocerboxAPP.invoiceId');
                // history.push("/member/account");
            }
            else if(status=="EXPIRED"){
                dispatch({ type: appConstant.HIDE_LOADER });
                // notification.error({
                //     message: 'EXPIRTED! TIMEOUT'
                // })
                console.log("expired");
            }
        });
  }
  function validtionPlaceOrder() {
    // console.log("i?.choosenAddress", i?.choosenAddress);
    // console.log("i?.currentAddress", i?.currentAddress);
    // console.log('i?.deliveryValueAmt ', i?.deliveryValueAmt  )
    // console.log('i?.deliveryValueAmt ', !i?.deliveryValueAmt == ""  )

    if (i?.choosenAddress && cartItems.length > 0 && i?.deliveryValueAmt !== '') {
      //onPlaceOrderClick();
    console.log('i?.deliveryValueAmt ', i?.deliveryValueAmt  )
      setDeliveryCharges(i?.deliveryValueAmt);
      if (skipCodShow === true) {
        setPlaceOrder(true);
      } else{
        showCODpopup()
      }
    } else {
      if (!i?.choosenAddress) {
        notifyError("Choose a Shipping Address");
      }
      if (i?.deliveryValueAmt === '') {
        
    console.log('i?.desssliveryValueAmt ', i?.deliveryValueAmt  )
        notifyError(" Choose a Delivery option");
      }
    }
  }


  async function getNewPaymentDetails(orderAmmount, location_id) {
    dispatch({ type: appConstant.SHOW_LOADER });
    apiRequest.getNewPaymentDetails(orderAmmount, location_id)
    .then((res) => {  
       setPaymentToken(res.data.paymentToken);
       localStorage.setItem("GrocerboxAPP.invoiceId", res.data.invoiceId); 
       apiRequest.getPaymentGateWay({"enabled": "Y"})
       .then((PayamentGatway) => {
         console.log('PayamentGatway', PayamentGatway);
         let gateway = PayamentGatway.data.filter(gateway => gateway.gateway_name == 'razorpay');
          if(gateway && gateway.length){
            apiRequest.getPaymentGateWayKey({"gateway_id": gateway[0].id})
          .then((response) => {
            var payData = response.data; 
            if(payData && payData.length){ 
              setPaymentKey(payData[0].merchant_id);
              
              
              
              const options = {
              // key: __DEV__ ? "rzp_test_YFtJ95Pcg9J89c" : "rzp_test_YFtJ95Pcg9J89c",
                key : payData[0].merchant_id,
                currency: settings.currency_code,
                amount: orderAmmount,
                order_id: res.data.paymentToken,
                name: "Grocerbox",
                description: "Choose your payment options",
                image: "/service/assets/clientLogo",
                handler: function (response) {
                  // alert(response.razorpay_payment_id);
                  // alert(response.razorpay_order_id);
                  // alert(response.razorpay_signature);
          
                  afterPaidByRazorpay(
                    response.razorpay_payment_id,
                    response.razorpay_order_id,
                    response.razorpay_signature
                  );
                },
                prefill: {
                  name: customer.first_name,
                  email: customer.email,
                  phone_number: customer.mobile_number,
                },
              };
              console.log(options);
              const paymentObject = new window.Razorpay(options);
              paymentObject.open();
            }
          }).catch((e) => {
             console.error(" New Payment Error ", e);
         });
          }
       }).catch((e) => {
          console.error(" New Payment Error ", e);
      });

    }).catch((e) => {
      console.error(" New Payment Error ", e);
    })
    .finally((e) => {
      dispatch({ type: appConstant.HIDE_LOADER });
    });
  }


  function showCODpopup() {
    setEnablePopUp("COD");
  }
  function handleClose(){
    setEnablePopUp("");
  }
  function onNotCODClick() {
    onPlaceOrderClick(false);
  }
  function onCODClick() {
    onPlaceOrderClick(true);
  }
  function onPlaceOrderClick(isCOD) {
    handleClose();
    setIsCOD(isCOD)
    console.log("isCOD ----------> ", isCOD);
    if (isCOD) {
      // /service/locationinvoicedetails/pay
      dispatch({ type: appConstant.SHOW_LOADER });
      apiRequest
        .locationinvoicedetailsPay(onCOD("Y"))
        .then((res) => {
          console.log("res -----> locationinvoicedetailsPay ", res.data);
          if (res.data.errorMessage) {
            console.error(
              "Error msg locationinvoicedetailsPay",
              res.data.errorMessage
            );
          } else {
            dispatch({ type: appConstant.ADD_PAY_INFO, data:res.data });
            clearCart();
            history.push("/member/orderconfirmation");
          }
        })
        .catch((e) => {
          console.error("locationinvoicedetailsPay API error ", e);
        })
        .finally((e) => {
          dispatch({ type: appConstant.HIDE_LOADER });
        });
    } else {
      let amtdt = genrateCartPriceData(cartItems, tax);
      let totalamt = amtdt.total; 
        // displayRazorpay(totalamt);
      gotoPament(totalamt, onCOD("N"));
    }
  }
  function addresGen() {
    let address = i?.currentAddress.filter(
      (v) => v.id.toString() === i?.choosenAddress.toString()
    )[0];
    console.log(address, "currentAddress")

    return (
      address.customer_name + "," +
      address.address1 +
      ", " +
      address.city +
      ", " +
      address.zipcode +
      "," + address.phone_number
      // JSON.stringify(address)
    );
  }

  

  function cartItemGen() {
    let item = [];
    cartItems.map((x) =>
      item.push({
        discount: x.discount,
        product_barcode: x.barcode,
        product_capacity: x.capacity,
        product_id: x.id,
        product_key: x.pkey,
        product_name: x.name,
        product_price: getProductPriceWithDiscount(x, tax),
        quantity: x.quantity,
      })
    );
    return item;
  }

  function onCOD(isCODDT) {
    let amtdt = genrateCartPriceData(cartItems, tax);
    let orderID = localStorage.getItem("GrocerboxAPP.invoiceId");
    var dt = {
      detail: {
        cod_enabled: isCODDT,
        customer_mobile: customer.mobile_number,
        deliveryAddress: addresGen(),
        delivery_charges: deliveryCharges,
        discount_amount: amtdt.discountFees,
        earned_loyality: 0,
        location_id: locationInfo.id,
        needHomeDelivery: "Y",
        online_sale: "Y",
        paidByTransfer: isCODDT === "Y" ? 0 : amtdt.total,
        status: "PENDING",
        subtotal: amtdt.subtotal,
        tax: amtdt.tax,
        totalamount: amtdt.total + deliveryCharges,
      },
      items: cartItemGen(),
      newInvoice: isCODDT === "Y"?undefined:orderID
    };
    return dt;
  }
  return cartItems.length > 0 ? (
    <>
      <div className="col-12 product-shipping-users summary-mobile m-0">
        <div className="paymentContainer">
          {/* <label className="products-filter-left-nav-container  mb-0 pt-2">
            Cash on Delivery
            <input
              type="checkbox"
              name="iscod"
              value={isCOD}
              className="pt-2"
            />
            <span className="checkmark mt-2" />
          </label> */}
          {skipCodShow ? (placeOrder ?<h6>Click Online Payment to complete you order</h6> : "") : ""}
          <button
            onClick={skipCodShow ? (placeOrder ? onNotCODClick : validtionPlaceOrder) : validtionPlaceOrder}
            className="btn btn-primary px-3 py-1 mt-3 w-100"
          >
            {skipCodShow ? (placeOrder ? "Online Payment" : "Place Order") : "Place Order"}
            
          </button>
        </div>
      </div>
      <Modal
        show={enablePopUp === "COD"}
        onHide={handleClose}
        animation={false}
        backdrop="static"
        keyboard={false}
        dialogClassName="modalContent"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="ForgetPasswordContainer">
            <h4 className="text-center">Payment option</h4>
             {/* <label className="products-filter-left-nav-container  mb-0 pt-2">
              Cash on Delivery
              <input
                type="checkbox"
                name="iscod"
                value={isCOD}
                onClick={(e) => setIsCOD(!isCOD)}
                className="pt-2"
              />
              <span className="checkmark mt-2" />
            </label> */}
            <br/>
            <button
              type="button"
              onClick={onNotCODClick}
              className="btn btn-primary w-100"
            >
              Online Payment
            </button>
            
            <div className="btn">
              
            </div>
            <button
              type="button"
              onClick={onCODClick}
              className="btn btn-primary w-100"
            >
              Cash on Delivery
            </button>
          </div>
        </Modal.Body>
      </Modal> 
    </>
  ) : (
    ""
  );
}
