import React, { useState, useEffect } from "react";
import apiRequest from "../lib/api/services";
import * as appConstant from "../provider/appConstant.type";
import parse from "html-react-parser";
import { useMainStateValue } from "../provider/AppProvider";

export default function PrivacyPolicy() {
  const [{ customer }, dispatch] = useMainStateValue();
  const [title, setTitle] = useState("");
  const [data, setData] = useState("");

  useEffect(() => {
    const fetchInfo = () => {
      dispatch({ type: appConstant.SHOW_LOADER });
      apiRequest
        .getPageDT(3)
        .then((res) => {
          if (res.data.errorMessage) {
            console.error("Error msg getPageDT", res.data.errorMessage);
            //serOrderData([]);
          } else {
            setTitle(res.data.name);
            setData(res.data.html_content);
          }
        })
        .catch((e) => {
          console.error("fetch getPageDT Info error ", e);
        })
        .finally((e) => {
          dispatch({ type: appConstant.HIDE_LOADER });
        });
    };
    fetchInfo();
  }, []);

  function showdata() {
    return parse(data);
  }

  return (
    <div className="privacy-Page">
      {showdata()}
      {/* <div className="commonPadding">
                <div> <span> Privacy Policy</span>
                    <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Faucibus purus in massa tempor nec feugiat nisl pretium. Viverra tellus in hac habitasse. Fusce ut placerat orci nulla. Risus viverra adipiscing at in tellus integer feugiat scelerisque. Vitae auctor eu augue ut. A condimentum vitae sapien pellentesque habitant morbi tristique senectus. At lectus urna duis convallis convallis tellus id interdum. Justo eget magna fermentum iaculis eu non diam phasellus. Diam sit amet nisl suscipit adipiscing bibendum est ultricies. Mi in nulla posuere sollicitudin aliquam ultrices sagittis orci. Ac orci phasellus egestas tellus rutrum tellus.
                    </p>
                </div>
                <div> 
                    <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Faucibus purus in massa tempor nec feugiat nisl pretium. Viverra tellus in hac habitasse. Fusce ut placerat orci nulla. Risus viverra adipiscing at in tellus integer feugiat scelerisque. Vitae auctor eu augue ut. A condimentum vitae sapien pellentesque habitant morbi tristique senectus. At lectus urna duis convallis convallis tellus id interdum. Justo eget magna fermentum iaculis eu non diam phasellus. Diam sit amet nisl suscipit adipiscing bibendum est ultricies. Mi in nulla posuere sollicitudin aliquam ultrices sagittis orci. Ac orci phasellus egestas tellus rutrum tellus.
                    </p>
                    <ul>
                        <li>Lorem ipsum dolor sit amet</li>
                        <li>Lorem ipsum dolor sit amet</li>
                        <li>Lorem ipsum dolor sit amet</li>
                    </ul>
                </div>
                <div>
                    <h3>
                        About My Site
                    </h3>
                    <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Faucibus purus in massa tempor nec feugiat nisl pretium. Viverra tellus in hac habitasse. Fusce ut placerat orci nulla. Risus viverra adipiscing at in tellus integer feugiat scelerisque. Vitae auctor eu augue ut. A condimentum vitae sapien pellentesque habitant morbi tristique senectus. At lectus urna duis convallis convallis tellus id interdum. Justo eget magna fermentum iaculis eu non diam phasellus. Diam sit amet nisl suscipit adipiscing bibendum est ultricies. Mi in nulla posuere sollicitudin aliquam ultrices sagittis orci. Ac orci phasellus egestas tellus rutrum tellus.
                    </p>
                </div>
                
            </div> */}
    </div>
  );
}
