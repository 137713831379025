import React, { useEffect, useState } from "react";
import apiRequest from "../../lib/api/services";
import { useMainStateValue } from "../../provider/AppProvider";
import SlideMenu from "../Menu/SlideMenu";
import Banner from "../dashboard-components/Banner";
import Brand from "../dashboard-components/Brand";
import FeatureProducts from "../dashboard-components/FeatureProducts";

function Dashboard() {
  const [{ imageUrl , menuData ,tax, branddt}] = useMainStateValue();
  const [menuDt, setMenuDt] = useState([]);
  const [banner, setBanner] = useState([]);
  //const [brand, setBrand] = useState([]);
  const [fproducts, setFproducts] = useState({});

  useEffect(() => {
    const fetchDashboardInfo = () => {
      apiRequest
        .getDashboardData()
        .then((responses) => {
          const getBanner = responses[0].data;
          //const getBrand = responses[1].data;
          const getfproducts = responses[1].data;

          //console.log("getBrand", getBrand);
          //console.log("getfproducts", getfproducts);
          //console.log('menuData.catagories', menuData.catagories)
          setMenuDt(menuData.catagories);
          setBanner(getBanner);
          //setBrand(getBrand);
          setFproducts(getfproducts);
        })
        .catch((e) => {
          console.error("fetch Dashboard Info error ", e);
        });
    };
    fetchDashboardInfo();
  }, []);

  return (
    <div className="">
      <SlideMenu catagories={menuDt} />
      <Banner banner={banner} imageUrl={imageUrl}/>
      <FeatureProducts products={fproducts} imageUrl={imageUrl}/>
      <Brand brand={branddt} imageUrl={imageUrl}/>
    </div>
  );
}

export default Dashboard;
