import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { removeValueInArray, urlParamCreater } from "../../lib/helpers/utils";

export default function FilterBrand(i) {
  const history = useHistory();
  const uri = i?.uri;

  useEffect(() => {
    const fetchFilterInfo = () => {
      // console.log(
      //   "--effect-in-brand ",
      //   i?.selectedBrand,
      //   i?.selectedPrice,
      //   i?.selectedOrderType,
      //   uri
      // );
      let queryParam = urlParamCreater(
        i?.selectedBrand,
        i?.selectedPrice,
        i?.selectedOrderType,
        i?.onSearch
      );
      history.push({
        pathname: uri,
        search: queryParam,
      });
    };
    fetchFilterInfo();
  }, [i?.selectedBrand]);

  function onbrandFilter(e) {
    //console.log("-----------", e.target.value, e.target.checked);
    if (e.target.checked) {
      let c = i?.selectedBrand.length > 0 ? i?.selectedBrand : [];
      i.setSelectBrand(c.concat(e.target.value));
    } else {
      let c = i?.selectedBrand.length > 0 ? i?.selectedBrand : [];
      let r = removeValueInArray(c, e.target.value);
      i.setSelectBrand(r);
      history.push(
          uri +
          urlParamCreater(
            i?.selectedBrand,
            i?.selectedPrice,
            i?.selectedOrderType,
            i?.onSearch
          )
      );
    }
  }
  function isCheckedBrand(c) {
    //console.log("object", i?.selectedBrand);
    let b = i?.selectedBrand.filter((b) => {
      if (b === c) {
        return b;
      }
    });
    return b.length > 0 ? true : false;
  }

  function brandData() {
    if (i?.brand.length > 0) {
      return i?.brand.map((key) => (
        <label key={key.code} className="products-filter-left-nav-container">
          {key.name}
          <input
            type="checkbox"
            value={key.code}
            defaultChecked={isCheckedBrand(key.code)}
            onChange={onbrandFilter}
          />
          <span className="checkmark" />
        </label>
      ));
    } else {
      return <p>N/A</p>;
    }
  }

  return brandData();
}
