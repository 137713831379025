import React from "react";
import { Link } from "react-router-dom";
import { useMainStateValue } from "../../provider/AppProvider";
import ImageTag from "../ImageLoader/imageTag";
import { useCartValue } from "../../provider/CartContext";
import * as appConstant from "../../provider/appConstant.type";
import CartProduct from "./CartProduct";
import { genrateCartPrice } from "../../lib/helpers/utils";

export default function Cart() {
  const [{ showCart, settings, customer,tax }, dispatch] = useMainStateValue();
  const { cartItems, itemCount, clearCart } = useCartValue();

  //console.log("cartItems ", cartItems);
  //console.log("cartItems Size", cartItems.length);
  //console.log("itemCount", itemCount);

  function cartClick() {
    showCart
      ? dispatch({ type: appConstant.CLOSE_CART })
      : dispatch({ type: appConstant.OPEN_CART });
  }

  function loginCartClick(){
    dispatch({ type: appConstant.SHOW_POPUP_LOGIN });
    dispatch({ type: appConstant.ENABLE_CHECKOUT }); 
    cartClick();
  }

  function cartSection() {
    if (cartItems.length > 0) {
      return (
        <div className="CartSection">
          <ul>
            {cartItems.map((x) => (
              <CartProduct key={x.id} product={x} />
            ))}
          </ul>
        </div>
      );
    } else {
      return (
        <div className="CartSection CartEmpty"> 
          {EmptyCartImage()}
          <p>Your Box is empty</p>
          <button type="button" className="btn btn-primary" onClick={cartClick}>Shop Now</button> 
        </div>
      );
    }
  }


  function EmptyCartImage() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 205">
        <g fill="none" fillRule="evenodd">
          <path
            fill="#F8F8F8"
            d="M107.06 179.086c67.55 58.744 163.97 10.556 138.867-73.888-25.103-84.443-55.053-56.907-122.964-56.907C55.053 48.29 0 73.769 0 105.198c0 31.43 39.509 15.145 107.06 73.888z"
          ></path>
          <g fillRule="nonzero">
            <path
              fill="#37474F"
              d="M190.583 127.918a12.742 12.742 0 00-5.724-10.027c-1.672-.976-3.176-1.029-4.237-.328l-3.918 2.198 8.211 14.11 3.884-2.194-.006-.009c1.088-.58 1.777-1.873 1.79-3.75z"
            ></path>
            <path
              fill="#455A64"
              d="M180.789 120.19a12.744 12.744 0 015.723 10.028c-.023 3.678-2.624 5.153-5.808 3.293a12.734 12.734 0 01-5.724-10.027c.024-3.68 2.624-5.153 5.809-3.294z"
            ></path>
            <path
              fill="#263238"
              d="M178.797 125.713c.009-1.439.89-2.094 1.969-1.465a4.67 4.67 0 011.934 3.744c-.008 1.438-.89 2.094-1.968 1.464a4.671 4.671 0 01-1.935-3.744z"
            ></path>
            <path
              fill="#000"
              d="M186.513 130.218a10.64 10.64 0 00-.787-3.857l-9.945-5.754a4.922 4.922 0 00-.8 2.88c0 .045.004.091.004.137l11.522 6.668c.002-.027.005-.05.005-.074z"
              opacity="0.15"
            ></path>
            <path
              fill="#455A64"
              d="M149.41 159.276c-2.1.16-4.206-.217-6.12-1.094L89.081 127.06c-3.277-1.642-6.952-5.914-7.181-9.954l-1.669-38.492 3.019-.171 1.668 38.49c.156 2.748 3.04 6.19 5.516 7.43l54.211 31.126a10.436 10.436 0 009.101-.524l36.15-20.76c.864-.566 1.36-1.243 1.328-1.81-.033-.566-.575-1.147-1.49-1.606L131.701 97.21c-3.268-1.64-6.935-5.913-7.164-9.958l-.81-21.347 3.019-.172.81 21.347c.156 2.754 3.031 6.196 5.497 7.432l58.035 33.58c1.939.973 3.058 2.438 3.156 4.127.098 1.69-.882 3.321-2.687 4.504l-36.151 20.76a12.46 12.46 0 01-5.996 1.793z"
            ></path>
            <g>
              <path
                fill="#37474F"
                d="M139.661 158.412a12.738 12.738 0 00-5.724-10.028c-1.671-.976-3.175-1.023-4.236-.326l-3.918 2.196 8.211 14.112 3.883-2.195-.005-.01c1.089-.58 1.777-1.872 1.79-3.75z"
              ></path>
              <path
                fill="#455A64"
                d="M129.871 150.684a12.746 12.746 0 015.723 10.028c-.023 3.678-2.624 5.153-5.808 3.294a12.743 12.743 0 01-5.725-10.028c.025-3.678 2.625-5.152 5.81-3.294z"
              ></path>
              <path
                fill="#263238"
                d="M127.876 156.206c.01-1.439.89-2.093 1.97-1.464a4.676 4.676 0 011.935 3.743c-.01 1.438-.891 2.094-1.97 1.464a4.67 4.67 0 01-1.935-3.743z"
              ></path>
            </g>
            <g>
              <path
                fill="#37474F"
                d="M99.627 135.402a12.748 12.748 0 00-5.705-10.039c-1.67-.978-3.175-1.028-4.237-.333l-3.921 2.189 8.185 14.126 3.887-2.188-.005-.009c1.09-.577 1.778-1.87 1.796-3.746z"
              ></path>
              <path
                fill="#455A64"
                d="M89.851 127.658a12.741 12.741 0 015.704 10.038c-.03 3.679-2.633 5.148-5.814 3.283a12.738 12.738 0 01-5.705-10.039c.03-3.678 2.633-5.148 5.815-3.282z"
              ></path>
              <path
                fill="#263238"
                d="M87.846 133.174c.012-1.437.894-2.09 1.971-1.457a4.674 4.674 0 011.929 3.747c-.012 1.439-.895 2.092-1.972 1.457a4.676 4.676 0 01-1.928-3.747z"
              ></path>
            </g>
            <path
              fill="#455A64"
              d="M74.327 32.217a10.015 10.015 0 013.832-10.652L113.93 1.31c3.254-2.138 6.81-.652 7.956 3.07l2.48 8.066-2.453 1.433-2.658-8.147a2.7 2.7 0 00-4.286-1.503L80.137 23.96a6.583 6.583 0 00-2.506 6.878l2.101 7.736-2.927 1.71-2.478-8.067z"
            ></path>
            <g>
              <path
                fill="#ECECEC"
                d="M130.005 71.121L81.779 98.986l70.482 40.729 48.227-27.877z"
              ></path>
              <path
                fill="#000"
                d="M130.005 71.121L81.779 98.986l70.482 40.729 48.227-27.877z"
                opacity="0.5"
              ></path>
              <path
                fill="#B5B5B5"
                d="M76.991 40.243l47.73-27.91 5.284 58.788-48.226 27.865z"
              ></path>
              <path
                fill="#FFF"
                d="M76.991 40.243l47.73-27.91 5.284 58.788-48.226 27.865z"
                opacity="0.35"
              ></path>
              <path
                fill="#27DEBF"
                d="M124.722 12.333l5.284 58.788 70.481 40.72 11.128-49.303-86.892-50.205zm24.64 56.518a4.037 4.037 0 01-5.054-2.62l-11.65-30.255a3.808 3.808 0 01.308-4.052 3.798 3.798 0 016.733 1.337l11.65 30.257a4.042 4.042 0 01-1.987 5.334zm22.007 10.364a4.038 4.038 0 01-5.054-2.62l-11.65-30.256a3.807 3.807 0 01.307-4.053 3.799 3.799 0 016.734 1.339l11.65 30.255a4.043 4.043 0 01-1.987 5.335zm22.008 10.364a4.038 4.038 0 01-5.054-2.62l-11.65-30.256a3.806 3.806 0 01.305-4.055 3.798 3.798 0 016.736 1.34l11.65 30.256a4.043 4.043 0 01-1.987 5.335z"
              ></path>
              <path
                fill="#B6B6B6"
                d="M124.722 12.333l5.284 58.788 70.481 40.72 11.128-49.303-86.892-50.205zm24.64 56.518a4.037 4.037 0 01-5.054-2.62l-11.65-30.255a3.808 3.808 0 01.308-4.052 3.798 3.798 0 016.733 1.337l11.65 30.257a4.042 4.042 0 01-1.987 5.334zm22.007 10.364a4.038 4.038 0 01-5.054-2.62l-11.65-30.256a3.807 3.807 0 01.307-4.053 3.799 3.799 0 016.734 1.339l11.65 30.255a4.043 4.043 0 01-1.987 5.335zm22.008 10.364a4.038 4.038 0 01-5.054-2.62l-11.65-30.256a3.806 3.806 0 01.305-4.055 3.798 3.798 0 016.736 1.34l11.65 30.256a4.043 4.043 0 01-1.987 5.335z"
              ></path>
              <g>
                <path
                  fill="#EAEAEA"
                  d="M76.991 40.243l4.79 58.746 70.482 40.731 11.128-49.304-86.4-50.173zm27.822 57.827a4.037 4.037 0 01-5.054-2.618l-11.65-30.257a3.805 3.805 0 01.307-4.052 3.8 3.8 0 016.734 1.337l11.65 30.256a4.043 4.043 0 01-1.987 5.334zm22.007 10.364a4.035 4.035 0 01-5.054-2.619l-11.65-30.255a3.805 3.805 0 01.307-4.052 3.798 3.798 0 016.733 1.337l11.65 30.256a4.042 4.042 0 01-1.986 5.334zm22.007 10.364a4.037 4.037 0 01-5.054-2.62l-11.65-30.255a3.806 3.806 0 014.097-5.514 3.803 3.803 0 012.944 2.799l11.65 30.256a4.044 4.044 0 01-1.987 5.334z"
                ></path>
                <path
                  fill="#27DEBF"
                  d="M163.391 90.414l48.226-27.864-11.13 49.301-48.226 27.864z"
                ></path>
                <path
                  fill="#D2D2D2"
                  d="M163.391 90.414l48.226-27.864-11.13 49.301-48.226 27.864z"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }


  function checkout() {
    if (customer) {
      return (
        <Link
          to="/member/checkout"
          type="submit"
          className="btn btn-primary w-100"
          onClick={cartClick}
        >
          Checkout
        </Link>
      );
    } else {
        return (<button  type="submit"
        className="btn btn-primary w-100"
        onClick={loginCartClick} >Checkout</button>);
    }
  }

  function cartFooter() {
    let cartPrices = genrateCartPrice(cartItems, settings.country_code,tax);
    if (cartItems.length > 0) {
      return (
        <div className="CartFooter">
          <ul>
            <li>
              <p className="label">Sub-total: </p>
              <p className="price">{cartPrices.subtotal}</p>
            </li>
            {/* <li>
              <p className="label">Total Tax: </p>
              <p className="price">{cartPrices.tax}</p>
            </li> */}
            <li>
              <p className="label">Total Discount: </p>
              <p className="price">{cartPrices.discountFees}</p>
            </li>
            {/* <li>
              <p className="label">Delivery Fees: </p>
              <p className="price">{cartPrices.deliveryFees?cartPrices.deliveryFees:0}</p>
            </li> */}
            <li className="CarttotalContainer">
              <p className="label">Total: </p>
              <p className="price">{cartPrices.total}</p>
            </li>
          </ul>
          {checkout()}
        </div>
      );
    } else {
      return "";
    }
  }

  return (
    <div
      className={
        "Product-cart-right-popup " + (showCart ? "active" : "")
      }
      id="Side-nav"
    >
      <div className="cart-user-border cart-user-count-section ">
        <div className="cartHeader">
          <p>Your Cart Items</p>
          <div className="closeIcon" onClick={cartClick}></div>
        </div>
        {cartSection()}
        {cartFooter()}
      </div>
    </div>
  );
}

{
  /* <div className="CartSection">
          <ul>
            <li>
              <div className="p-0 productImg">
                <img src={"/images/png/onion-big.PNG"} alt="logo" />
              </div>
              <div className="cart-user-count-section-desc">
                <p className="m-0">BIO BLOOMS Gardening Small Onion - 1kg </p>
              </div>
              <div className="cart-user-count-button">
                <div className="add-sub-button-common">
                  <div className="value-button decrement"></div>
                  <div className="cart-count-input">
                    <input type="text" />
                  </div>
                  <div className="value-button Increment"></div>
                </div>
              </div>
              <div className="productPrice">
                &#8377; 19.00 <div className="removeCartItem"></div>
              </div>
            </li>
          </ul>
        </div> */
}

// <div className="CartFooter">
//   <ul>
//     <li>
//       <p className="label">Subtotal: </p>
//       <p className="price">&#8377; 38.00</p>
//     </li>
//     <li>
//       <p className="label">GST(18%) Fees: </p>
//       <p className="price">&#8377; 6.84</p>
//     </li>
//     <li>
//       <p className="label">Discount Fees: </p>
//       <p className="price">&#8377; 0.00</p>
//     </li>
//     <li>
//       <p className="label">Delivery Fees: </p>
//       <p className="price">&#8377; 8.00</p>
//     </li>
//     <li className="CarttotalContainer">
//       <p className="label">Total: </p>
//       <p className="price">&#8377; 52.84 </p>
//     </li>
//   </ul>
//   <Link
//     to="/member/checkout"
//     type="submit"
//     className="btn btn-primary w-100"
//     onClick={cartClick}
//   >
//     Checkout
//   </Link>
// </div>
