import React, { useState, useEffect, useReducer, useRef } from "react";
import ProductCard from "../Product/ProductCard";
import { Link } from "react-router-dom";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { removeValueInArray, urlParamCreater } from "../../lib/helpers/utils";
import { useMainStateValue } from "../../provider/AppProvider";
import apiRequest from "../../lib/api/services";
import * as appConstant from "../../provider/appConstant.type";
import ProductGrid from "../Product/ProductGrid";
import FilterBrand from "../Product/FilterBrand";
import FilterPrice from "../Product/FilterPrice";
import FilterOrderBy from "../Product/FilterOrderBy";

export default function ProductList() {
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const [
    { imageUrl, productSettings, menuData },
    dispatch,
  ] = useMainStateValue();
  const queryBrand = query.get("brand_code");
  const queryPrice = query.get("price_code");
  const queryOrderType = query.get("ordertype");
  const [brand, setBrand] = useState([]);
  const [price, setPrice] = useState([]);
  const [orderType, setOrderType] = useState([]);
  const [showmobileFiter, setShowMobileFiter] = useState(false);

  let { catagories, subcatagories } = useParams();
  const catagoryCodes = subcatagories ? subcatagories : catagories;
  console.log("catagories", catagories);
  console.log("subcatagories", subcatagories, subcatagories ? true : false);
  console.log("queryBrand ", queryBrand, "queryPrice ", queryPrice);
  const title = subcatagories && subcatagories[catagories]
    ? menuData?.subcatagories[catagories].map((subcat) => {
        if (subcat.code === subcatagories) {
          return subcat.name;
        }
      })
    : menuData?.catagories.map((catagorey) => {
        if (catagories === catagorey.code) {
          return catagorey.name;
        }
      });
  const [selectedBrand, setSelectBrand] = useState(
    queryBrand ? queryBrand.split(",") : []
  );
  const [selectedPrice, setSelectPrice] = useState(
    queryPrice ? queryPrice.split(",") : []
  );
  const [selectedOrderType, setSelectOrderType] = useState(
    queryOrderType ? queryOrderType.split(",") : []
  );
  const uri = catagories + (subcatagories ? "/" + subcatagories + "/" : "/");
  const url = "/product/" + uri;
  //console.log("productsData", productsData);

  // get count of product in catagories or subcatagories
  //NOt this http://13.228.191.234:8080/service/locationstocks/searchproduct?locationId=4&pageSize=1&pageNumber=1
  //http://13.228.191.234:8080/service/locationstocks/searchproduct/groupbyname?locationId=1&pageSize=10&pageNumber=1
  //{
  //   "catagory_code": "string"
  // }

  //location id -- product setting -- online_store_id

  //Order --
  // ?orderby=price1&ordertype=asc/desc

  // Sub cat
  // //{
  //   "catagory_code": "string"
  //   "subcatagory_code": "string", send comma sep by sub cat
  // }

  // Get list brand by catagory/subcatagory
  // to be given ---
  // '/service/brandbycatagory/{catagoryCode}?locationId=1&catagoryCodes=MK,FROPD'
  // {
  //   "id": 0,
  //   "code": null, --- to show
  //   "name": null, --- to show
  //   "imageid": null,
  //   "created_by": null,
  //   "updated_by": null,
  //   "created_date": null,
  //   "updated_date": null,
  //   "enabled": null
  // },

  //List of prices api by catagory/subcatagory
  //to be given ----

  useEffect(() => {
    const fetchCatInfo = () => {
      dispatch({ type: appConstant.SHOW_LOADER });

      apiRequest
        .getProductListPageData(productSettings?.online_store_id, catagoryCodes)
        .then((responses) => {
          const branddt = responses[0].data;
          const pricedt = responses[1].data;
          setBrand(branddt);
          setPrice(pricedt);
        })
        .catch((e) => {
          console.error("getProductListPageData API error ", e);
        })
        .finally((e) => {
          dispatch({ type: appConstant.HIDE_LOADER });
        });
    };
    fetchCatInfo();
  }, [catagoryCodes]);

  function categories() {
    return menuData?.catagories.map((catagorey) => {
      if (catagories === catagorey.code) {
        return (
          <li key={catagorey.code}>
            <div className="linkTxt">
              <Link key={catagorey.code} to={"/product/" + catagorey.code}>
                {catagorey.name}
              </Link>
            </div>
            {subCatagoreyRender(catagorey.code)}
          </li>
        );
      } else {
        return "";
      }
    });
  }

  function subCatagoreyRender(catagoreyCode) {
    console.log('KPR', subcatagories);
    if (menuData?.subcatagories.hasOwnProperty(catagoreyCode)) {
      const subcatagorey = menuData?.subcatagories[catagoreyCode].map(
        (subcatagorey) => (
          <li key={subcatagorey.code} className={subcatagories == subcatagorey.code ? 'linkTxt' : ''}>
            <Link to={"/product/" + catagoreyCode + "/" + subcatagorey.code}>
              {subcatagorey.name}
            </Link>
            {/* <span className="right-arrow-green"></span>  */}
          </li>
        )
      );
      return <ul>{subcatagorey}</ul>;
    } else {
      return "";
    }
  }

  function clearBrandFilter(e) {
    console.log("e", e.target.dataset.remove);
    let c = selectedBrand.length > 0 ? selectedBrand : [];
    let r = removeValueInArray(c, e.target.dataset.remove);
    setSelectBrand(r);
    history.push(
      url + urlParamCreater(selectedBrand, selectedPrice, selectedOrderType , false)
    );
  }
  function clearPriceFilter(e) {
    console.log("e", e.target.dataset.remove);
    let c = selectedPrice.length > 0 ? selectedPrice : [];
    let r = removeValueInArray(c, e.target.dataset.remove);
    setSelectPrice(r);
    history.push(
      url + urlParamCreater(selectedBrand, selectedPrice, selectedOrderType , false)
    );
  }

  function getPriceNameById(id) {
    let a = price.filter((v) => v.id.toString() === id.toString());
    return a.length > 0 ? a[0].name : "";
  }
  function filterDisplayBrand() {
    return selectedBrand.map((v) => (
      <span className="pr-2">
        {v}&nbsp;
        <img
          src={"/images/svg/close-white.svg"}
          alt="close"
          data-remove={v}
          onClick={clearBrandFilter}
        />
      </span>
    ));
  }
  function filterDisplayPrice() {
    return selectedPrice.map((v) => (
      <span className="pr-2">
        {getPriceNameById(v)}&nbsp;
        <img
          src={"/images/svg/close-white.svg"}
          alt="close"
          data-remove={v}
          onClick={clearPriceFilter}
        />
      </span>
    ));
  }
  function filterClearAll() {
    return selectedPrice.length > 0 || selectedBrand.length > 0 ? (
      <span
        className="product-clear-all cursor-pointer"
        onClick={clearAllFilter}
      >
        Clear all
      </span>
    ) : (
      ""
    );
  }

  function clearAllFilter() {
    setSelectBrand([]);
    setSelectPrice([]);
    setSelectOrderType([]);
  }

  function BrandFilter (brand) {
    if(brand.length > 0) {
      return (
        <div className="products-filter-left-nav-Second-section pt-3">
          <h6 className="pb-1">Brand</h6> 
          <FilterBrand
            uri={url}
            onSearch={false}
            catagories={catagories}
            subcatagories={subcatagories}
            brand={brand}
            selectedBrand={selectedBrand}
            setSelectBrand={setSelectBrand}
            price={price}
            selectedPrice={selectedPrice}
            setSelectPrice={setSelectPrice}
            orderType={orderType}
            selectedOrderType={selectedOrderType}
            setSelectOrderType={setSelectOrderType}
          />
        </div>
      );
    } else {
      return '';
    }
  }
   

  return (
    <div className="product-filter-section commonPadding">
      <div className="product-filter-main-page">
        <div className="products-items-content">
          <div className="filter-text" onClick={() => {setShowMobileFiter(true)}}><span className="filter-icon"></span></div>
          <div className="product-search-clear">
            {filterDisplayBrand()}
            {filterDisplayPrice()}
            {filterClearAll()}
          </div>
          <FilterOrderBy
            uri={url}
            onSearch={false}
            catagories={catagories}
            subcatagories={subcatagories}
            brand={brand}
            selectedBrand={selectedBrand}
            setSelectBrand={setSelectBrand}
            price={price}
            selectedPrice={selectedPrice}
            setSelectPrice={setSelectPrice}
            orderType={orderType}
            selectedOrderType={selectedOrderType}
            setSelectOrderType={setSelectOrderType}
          />
        </div>
      </div>
      <div className="products-with-cart">
        <div className="col-12 col-md-2 col-lg-2 primary-product-container">
          <div className={showmobileFiter ? "products-filter-left-nav products-mobile-filter active" : "products-filter-left-nav products-mobile-filter"}>
            <div className="products-filter-left-nav-first-section">
              <h6 className="pb-1">Categories</h6>
              <ul className="categoriesFilter">{categories()}</ul>
            </div>
            {BrandFilter(brand)} 
            
          </div>
        </div>
        <ProductGrid
          uri={url}
          onSearch={false}
          imageUrl={imageUrl}
          title={title}
          catagories={catagories}
          subcatagories={subcatagories}
          productSettings={productSettings}
          selectedBrand={selectedBrand}
          setSelectBrand={setSelectBrand}
          selectedPrice={selectedPrice}
          setSelectPrice={setSelectPrice}
          selectedOrderType={selectedOrderType}
          setSelectOrderType={setSelectOrderType}
        />
      </div>
    </div>
  );
}

{
  /* <label className="products-filter-left-nav-container">
                All Categories
                <input type="checkbox" defaultChecked="checked" />
                <span className="checkmark" />
              </label>
              <label className="products-filter-left-nav-container">
                Fresh Fruits
                <input type="checkbox" />
                <span className="checkmark" />
              </label>
              <label className="products-filter-left-nav-container">
                Fresh Vegtables
                <input type="checkbox" />
                <span className="checkmark" />
              </label>
              <label className="products-filter-left-nav-container">
                Fresh Herbs
                <input type="checkbox" />
                <span className="checkmark" />
              </label>
              <label className="products-filter-left-nav-container">
                Produce Baskets
                <input type="checkbox" />
                <span className="checkmark" />
              </label> */
}

{
  /* <label className="products-filter-left-nav-container">
                Winter Fruits
                <input type="checkbox" defaultChecked="checked" />
                <span className="checkmark" />
              </label>
              <label className="products-filter-left-nav-container">
                bb Combo
                <input type="checkbox" />
                <span className="checkmark" />
              </label>
              <label className="products-filter-left-nav-container">
                bb Combo
                <input type="checkbox" />
                <span className="checkmark" />
              </label>
              <label className="products-filter-left-nav-container">
                Fresho
                <input type="checkbox" />
                <span className="checkmark" />
              </label>
              <label className="products-filter-left-nav-container">
                Tadda
                <input type="checkbox" />
                <span className="checkmark" />
              </label> */
}
