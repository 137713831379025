import React from "react";
import { Redirect, Route } from "react-router-dom";
import HeadersSection from "../components/Header/HeaderSection";
import FooterSection from "../components/Footer/FooterSection";
import Cart from "../components/Cart/Cart";
import MenuDropdown from "../components/Menu/MenuDropdown";
import MenuCard from "../components/Menu/MenuCard";

const Layout = ({ children, ...rest }) => {
  return <div>{children}</div>;
};

const TemplateLayout = ({ component: Component, ...rest }) => {
  //console.log('component', Component);
  let path = window.location.pathname;
  //console.log("rest", window.location.href, path);

  let loggedIn = true;
  return (
    <Route
      {...rest}
      render={(props) =>
        loggedIn ? (
          <>
            <HeadersSection />
            <div className="page-container">
              {path.includes("/member") || path.includes("/page") ? "" : <MenuDropdown />}
              <Layout>
                <Component {...props} key={window.location.href} />
              </Layout>
              <Cart />
              <MenuCard />
            </div>
            <FooterSection />
            <div className="modalBackDrop"></div>
          </>
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

export default TemplateLayout;
