import React, { useState, useEffect } from "react";
import { useMainStateValue } from "../../provider/AppProvider";
import apiRequest from "../../lib/api/services";
import * as appConstant from "../../provider/appConstant.type";
import { useFormik } from "formik";

export default function Profile() {
  const [{ customer }, dispatch] = useMainStateValue();
  const [customerData, setCustomerData] = useState(null);
  const [initvalue, setInitvalue] = useState({
    firstname: customer.first_name,
    lastname: customer.last_name,
    mobileno: customer.mobile_number,
    email: customer.email,
    gender: customer.sex ? customer.sex : "",
  });
  const [enableReinitialize, setEnableReinitialize] = useState(false);
  useEffect(() => {
    const fetchInfo = () => {
      dispatch({ type: appConstant.SHOW_LOADER });

      apiRequest
        .getCustomerByID(customer.id)
        .then((res) => {
          console.log("res", res);
          if (res.data.errorMessage) {
            console.error("Error msg getCustomerByID", res.data.errorMessage);
            setCustomerData({});
          } else {
            console.log("res data", res.data);
            setCustomerData(res.data);
            // setInitvalue({
            //   firstname: res.data.first_name,
            //   lastname: res.data.last_name,
            //   mobileno: res.data.mobile_number,
            //   email: res.data.email,
            //   gender: res.data.sex ? res.data.sex : "",
            // });
            formik.values.firstname = res.data.first_name;
            formik.values.lastname = res.data.last_name;
            formik.values.mobileno = res.data.mobile_number;
            formik.values.email = res.data.email;

            formik.values.gender = res.data.sex ? res.data.sex : ""
            setEnableReinitialize(true);
            console.log('enableReinitialize', enableReinitialize)
            console.log('customerData', customerData)
          }
        })
        .catch((e) => {
          console.error("getCustomerByID API error ", e);
          setCustomerData({});
        })
        .finally((e) => {
          dispatch({ type: appConstant.HIDE_LOADER });
        });
    };
    fetchInfo();
  }, []);

  // {
  //     "id": 1557,
  //     "first_name": "Bhuvan",
  //     "last_name": "",
  //     "mobile_number": "9486867374",
  //     "email": "bhuvan.ganesan@gmail.com",
  //     "sex": null,
  //     "type": "Walk-In",
  //   }

  function validate(values) {
    const errors = {};

    if (!values.firstname) {
      errors.firstname = "Required";
    }

    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    // var phoneno = /^\+(?:[0-9] ?){6,14}[0-9]$/;
    if (!values.mobileno) {
      errors.mobileno = "Required";
    } else if (!values.mobileno.match(phoneno)) {
      errors.mobileno = "Enter a valid Mobile number";
    }

    if (!values.email) {
      errors.email = "Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Invalid email address";
    }

    if (!values.gender) {
      errors.gender = "Required";
    }

    return errors;
  }
  const formik = useFormik({
    enableReinitialize: enableReinitialize,
    initialValues: initvalue,
    validate,
    onSubmit: (values) => {
      console.log(JSON.stringify(values));
      const data = {
        id:customer.id,
        email: values.email,
        first_name: values.firstname,
        last_name: values.lastname,
        //mobile_number: values.mobileno,
        sex: values.gender,
      };
      dispatch({ type: appConstant.SHOW_LOADER });
      apiRequest
        .putCustomerByID(customer.id , data)
        .then((res) => {
          console.log(res);
          if (res.data.errorMessage) {
            // TODO SHow error
          } else {
          }
        })
        .catch((e) => {
          console.error("Login API error ", e);
        })
        .finally((e) => {
          dispatch({ type: appConstant.HIDE_LOADER });
        });
    },
  });

  function errorTicker(s) {
    let t = formik.touched[s],
      e = formik.errors[s];
    return t && e ? <small className="form-text  text-danger">{e}</small> : "";
  }

  return (
    <div className="row user-product-details">
      <form onSubmit={formik.handleSubmit} className="w-100 row">
        <div className="col-12 col-sm-6 col-md-4 product-shipping-users">
          <label htmlFor="fname">First name</label>
          <input
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            type="text"
            name="firstname"
            placeholder="First Name"
            value={formik.values.firstname}
            maxLength="50"
          />
          {errorTicker("firstname")}
        </div>
        <div className="col-12 col-sm-6 col-md-4 product-shipping-users">
          <label htmlFor="fname">Last name</label>
          <input
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            type="text"
            name="lastname"
            value={formik.values.lastname}
            placeholder="Last Name"
            maxLength="50"
          />
          {errorTicker("lastname")}
        </div>

        <div className="col-12 col-sm-6 col-md-4 product-shipping-users">
          <label htmlFor="mob-number">Mobile-Number</label>
          <input
            disabled={true}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.mobileno}
            type="text"
            name="mobileno"
            placeholder="Mobile-Number"
            maxLength="10"
          />
          {errorTicker("mobileno")}
        </div>

        <div className="col-12 col-sm-6 col-md-4 product-shipping-users">
          <label htmlFor="Gender">Gender</label>
          <select
            name="gender"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.gender}
            className="form-control"
          >
            <option value="">Choose a gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Transgender">Transgender</option>
          </select>
          {errorTicker("gender")}
        </div>
        <div className="col-12 col-sm-6 col-md-4 product-shipping-users">
          <label htmlFor="E-mail">E-mail</label>
          <input
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            name="email"
            placeholder="Email"
          />
          {errorTicker("email")}
        </div>
        <div className="ButtonContainer mr-3 pt-3 w-100 d-block text-right">
          <button type="submit" className="btn btn-primary px-5 py-2">
            Save
          </button>
        </div>
      </form>
    </div>
  );
}
