import React from "react";
import { Link } from "react-router-dom";
import { useMainStateValue } from "../../provider/AppProvider";
import { genrateCartPriceData,formatCurrency } from "../../lib/helpers/utils";

import { useHistory } from "react-router-dom";

export default function OrderConfirmation() {
  const [{ payData, customer ,settings}, dispatch] = useMainStateValue();
  const history = useHistory();

  function itemsDt() {
    return payData.items.map((d) => (
      <div className="row Summary-section-sub-font pt-2 pb-2 m-0 pl-0">
        <div className="col-6 pl-0">
          <span>{d.product_name}</span>
        </div>
        <div className="col-6 text-right pr-0">
          <span>{formatCurrency(d.product_price,  settings.country_code)}</span>
        </div>
      </div>
    ));
  }

  if (payData) {
    return (
      <div className="">
        <div className="User-Confirmation-page py-3">
          <div className="User-Confirmation-body">
            <div className="Thanks_Section">
              <h4 className="text-center">Your Orders Is On The Way</h4>
              <div className="ButtonContainer pt-3">
                <Link to="/member/account">
                  <button
                    type="submit"
                    className="btn btn-primary track-order-Btn"
                  >
                    Track Order
                  </button>
                </Link>
                <Link to="/">
                  <button
                    type="submit"
                    className="btn btn-outline-primary track-order-Btn"
                  >
                    Continue Shopping
                  </button>
                </Link>
              </div>
              <p className="text-center mb-0 py-3">
                We're processing your order now, here are the details
              </p>
            </div>
            <div className="row orderMainDetail">
              <div className="col-6 col-md-6">
                <p className="label">Order ID :</p>
                <p className="label_value">{payData.detail.id}</p>
              </div>
              <div className="col-6 col-md-6">
                <p className="label">Order Date :</p>
                <p className="label_value">{payData.detail.created_date}</p>
              </div>
            </div>
            <div className="Order-details py-3">
              <div className="row">
                <div className="col-12 col-lg-6 ">
                  <div className="Order-details-right">
                    <h6 className="pb-2">Delivery Address</h6>
                    <p>{payData.detail.deliveryAddress}</p>
                  </div>
                </div>
                <div className="col-12 col-lg-6 text-left ">
                  <div className="Order-details-left">
                    <h6 className="pb-2">Your Order details</h6>
                    <div>
                      {itemsDt()}
                      <div className="row Summary-section-sub-font pt-2 pb-3 total-border m-0">
                        <div className="col-6 pl-0">
                          <span>Total</span>
                        </div>
                        <div className="col-6 text-right pr-0">
                          <span>{formatCurrency(payData.detail.totalamount,  settings.country_code)}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    history.push("/");
    return "";
  }
}
